@import "variables";
@import "mixins";
$animationDuration: 0.22s;
//$animationDuration: 3s;

.JackpotCard {
  width: 292px;
  height: 385px;
  background-size: cover;
  background-position: center center ;
  position: relative;

  .video {
    width: 100%;
    transform: scale(1.5);
    margin-top: 85px;
    transform-origin: center center;
    background: transparent;
    position: absolute;
    opacity: 1;
  }

  .JackpotCard__bg_img {
    height: 100%;

    &.mobile {
      display: none;
    }
  }

  &__bg_img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &__playBtn {
    color: #fff;
    height: 40px;
    background: $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 100px;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    padding: 8px 24px;
    gap: 8px;
    margin-top: 80px;

    &:hover {
      background: #08a50d;;
    }

    div {
      height: 24px;
    }
  }

  &.skyBg {

    .JackpotCard__bg_img {
      max-height: 100%;

      img {
        transform: translateX(-20px);
        width: calc(100% + 20px);
        height: 100%;
      }
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 8px;

    .topValuesContainer {
      width: 100%;
      margin-top: 22px;
      min-height: 72px;
    }

    .topValues {
      //width: calc(100% - 32px);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      //margin-top: 40px;
      color: #fff;

      .val {
        flex: 1;
        display: flex;
        justify-content: center;
        gap: 4px;
        max-height: 20px;

        .ron {
          line-height: 100%;
          font-size: 20px;
          font-weight: 800;
        }
      }

      .badge {
        @include jpBadge;

      }


    }

  }
}

.valueContainer {
  @include spinningValue;
}

.sliderContainer {
  height: 485px;
  position: relative;
  overflow: hidden;

  .title {
    display: flex;
    gap: 8px;

    align-items: center;

    h2 {
      margin-bottom: 0 !important;
    }

    button {
      padding: 0;
      background: none;
      border: none;
      color: $primary-color;
    }
  }

  .slider {
    position: absolute;
    top: 35px;
    left: 0;
    &__slide {
      width: fit-content;
      margin-right: 13px !important;
      margin-bottom: 50px;
      margin-top: 15px;
      padding: 0 !important;

      &:first-child {
        margin-left: 40px;
      }
    }
  }
}

.modal {
  //max-width: calc(100vw - 8px);
  width: 636px !important;
  max-height: calc(100vh - 100px);

  .inner {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: 100%;

    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      strong {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: #FFF;
      }

      button {
        color: $dark_text3;
        background: none;
        border: none;
      }
    }

    .body {
      text-align: start;
      display: flex;
      flex-direction: column;
      gap: 24px;
      flex: 1;
      overflow: auto;
      height: auto;

      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: #D2CDDB;
      }

      .grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
        gap: 8px;

        .grid_item {
          position: relative;

          .burn {
            position: absolute;
            width: 90%;
            height: 30%;
            bottom: 15%;
            left: 50%;
            transform: translateX(-50%);



            .burn_inner {
              height: 100%;
            }
            
            @media (max-width: 1400px) {
              .glow {
                filter: blur(3px) !important;
              }
            }
          }

          &:not(.is_image) {
            padding: 0 16px;
          }
        }
      }

      .yellow {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        color: $primary-color;
      }
    }

    .footer {
      button {
        margin: 0 auto;
      }
    }
  }
  
  @media (max-width: 512px) {
    .inner {
      .header {
        strong {
          font-size: 18px;
        }
      }

      .body {
        p {
          font-size: 14px;
        }

        .yellow {
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 991px) {


  .JackpotCard {
    transform: scale(0.50);
    transform-origin: top left;

    .video {
      display: none;
    }

    &__playBtn {
      //transform: scale(1.35);
      transform-origin: bottom right;
      width: 70px;
      height: 70px;
      padding: 0;
      position: absolute;
      bottom: 25px;
      right: 30px;

      span {
        display: none;

      }

      svg {
        width: 40px;
        height: 40px;
      }
    }

    .JackpotCard__bg_img {
      display: none;

      &.mobile {
        display: block !important;
      }
    }

    &__content {

      .rounded_leveling {
        transform: translateX(-2px);
      }

      .topValuesContainer {
        border-radius: 20px;
        height: 120px;
      }

      .topValues {
        //margin-top: 10px;
        flex-direction: column;
        gap: 20px;
        border-radius: 32px;

        .val {
          transform: scale(1.4);
        }

        .badge {
          min-width: 100px;
          transform: scale(1.2);
          text-align: center;
        }
      }
    }
  }

  .sliderContainer {
    height: 270px;

    .slider {
      &__slide {
        width: 150px !important;
        height: 200px;
        margin-right: 8px !important;
        margin-bottom: 20px;
        margin-top: 8px;

        &.first {
          margin-left: 20px;
        }
      }
    }
  }

}


