$primary-color: #EEB665;
$primary-dark: #957243;
$secondary-color: #006E04;
$background-color: #0E0C10;
$secondary-background-color: #292929;
$dark1: #27222D;
$dark2: #35303E;
$dark3: #464050;
$dark_cards: #17141B;
$dark_cards_hover: #1F1B24;
$dark_text: #84789C;
$dark_text2: #8B819C;
$dark_text3: #8F84A4;
$error: #ed3030;
$danger: #9D0000;
$danger_hover:  #b20707;
$warning: #ff611b;
$info: #008bc7;
$maxWidth: 1920px;
$width1: 1180px;
$width2: 900px;
$font: 'Montserrat', sans-serif;
