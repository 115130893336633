@import 'variables';
@import "mixins";

$sideWidth: 153px;

.close_profile {
  padding: 24px;
  color: #fff;
  border-radius: 16px;

  .headeing {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }

  .info {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    color: $spins-dark_text;
  }

  .dropdown_container {
    margin: 34px 0;
  }

  .action_container {
    //border: 1px solid #fff;
    display: flex;
    gap: 16px;

    button {
      flex: 1;
    }
  }

  &.left {
    background: $spins_gray_bg3;
    //border: 1px solid $spins_border_gray_color;
    flex: 2;
  }

  &.right {
    background: $spins_gray_bg3;
    flex: 1;

    .check_boxes {
      width: 100%;
      display: flex;
      gap: 24px;

      .box {
        margin: 0 !important;
        flex: 1;
        .checkmark_label {
          border-radius: 8px;
          //@include darkBox;
          padding: 16px 16px 16px 56px;
          display: flex;
          position: relative;
          //margin-bottom: 12px;
          cursor: pointer;
          font-size: 22px;
          width: 100%;
          gap: 8px;
          align-items: center;

          .checkmark3 {
            svg {
              display: none;
            }
          }

          &.checked {
            @include boxYellow;
            color: #0e0c10;

            .checkmark3 {
              svg {
                display: block;
                path {
                  fill: $spins-primary-color;
                }
              }
            }

            .content {

              svg {
                display: block;

                path {
                  color: currentColor;
                }
              }

              .checkBoxText {
                //color: $spins_gray_bg2;
              }
            }
          }

          input {
            position: absolute;
            cursor: pointer;
            height: 0;
            width: 0;
            opacity: 0;
          }

          .checkmark3 {
            height: 25px;
            width: 25px;
            border-radius: 50px;
            background-color: transparent;
            border: 2px solid $spins-dark_text;
            position: absolute;
            left: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .checkBoxBlock3 {
            color: $spins-dark_text;
            font-size: 11px;
            border-radius: 100px;
            border: 1px solid transparent;
            padding: 16px;
            width: 100%;
            padding-left: 50px;

          }

          .content {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            gap: 8px;

            svg {
              path {
                //fill: #D9D9D9;
              }
            }

            .checkBoxText {
              font-size: 14px;
              //color: #d2cddb;
              font-weight: 500;
              line-height: 16px;
            }
          }
        }

        .checkmark_label input:checked ~ .checkmark3{
          background-color: $spins_green;
          border: none;

          .checkBoxBlock {
            color: white;
          }

          .checkBoxBlock3 {
            color: white;
          }
        }

        .checkmark_label input:checked {
          .pauseWrapper {
            border: none;
            background: grey;
          }
        }
      }
    }
  }
}

.btnBlock {
  display: flex;
  justify-content: space-around;
  gap: 16px;
}

.claimable_offer {
  padding: 0 !important;
  overflow: hidden;

  .offer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 !important;

    .title_platform {
      position: relative;

      img {
        width: 100%;
        height: auto;
      }

      .countdown {
        top: 0;
        left: 0;
        border-radius: 0 0 16px 0;
        position: absolute;
        @include smallCountdown;

      }

      p {
        margin: 0;
      }

      .title {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        padding: 16px;
      }
    }

    .alternatives {
      width: 100%;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .alternative_item {
        display: flex;
        align-items: center;
        gap: 12px;
        color: var(--theme-white-black-text);
        font-size: 14px;

        img {
          width: 24px;
        }

      }
    }


  }

  .offer_bottom {
    padding: 16px;
    display: flex;
    gap: 8px;

    .btn_details {
      background: none;
      border: none;
      font-weight: 700;
      text-decoration: underline;
      color: var(--theme-primary-yellow);
    }

    button {
      flex: 1;
      min-height: unset;
      height: 40px;
      text-transform: none !important;
    }
  }
}

.pageAvailableOffers {
    //border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;

  .title_platform {
    position: relative;

    img {
      width: 100%;
      height: auto;
    }

    .title {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: end;
      padding: 16px;

      p {
        margin: 0;
        color: #fff;
      }
    }
  }

  .pageHeading {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;

    &__switch{
      width: 315px;
      height: 40px;
      background: $background-color;
      border: 1px solid $dark2;
      border-radius: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .sides {
        width: $sideWidth;
        text-align: center;
        font-size: 16px;
        font-family: Poppins;
        font-weight: 600;
        line-height: 16px;
        text-transform: uppercase;
        color: $dark_text;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        &.active {
          color: #fff;
        }
      }

      .selector {
        border-radius: 100px;
        width: $sideWidth;
        height: 100%;
        border: 1px solid $primary-color;
        position: absolute;
        left: 0;
        -webkit-transition: 0.3s all;
        transition: 0.3s all;

        &.floated {
          -webkit-transition: 0.3s all;
          transition: 0.3s all;
          left: calc(100% - $sideWidth);
        }
      }
    }


    .buttons {
      display: flex;
      gap: 8px;

    }

    @media (max-width: 520px) {
      .buttons {
        flex-wrap: wrap;

        button {
          font-size: 11px;
          padding: 16px;
          min-height: 40px;
          height: 40px;
        }
      }
    }
  }

  .no_offers {
    color: var(--theme-column-color);
  }

  .loading {
    display: flex;
    align-self: center;
    justify-content: center;
  }

  .terms {
    flex-wrap: wrap;
    gap: 8px;
    color: var(--theme-white-black-text);
    margin: 60px 0;

    span {
      margin-left: 8px;
    }

    svg {
      transform: translateY(-4px);
    }

    button {
      padding: 0;
      border: 0;
      background: none;
      color: var(--theme-primary-yellow);
      margin-left: 8px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.accountMenu {
  //margin-top: 60px;
  //border: 1px solid #604180;
  border-radius: 24px;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 50px;
  width: 325px;


  &__profileName {
    padding: 30px;
    background: #1F1B24;
    border-radius: 50px;
    width: 100px;
    height: 100px;
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__profile {
    font-weight: 600;
    text-align: center;
    padding: 0 10px 25px;
    color: #FFFFFF;

    & img {
      border: 1px solid purple;
      border-radius: 50px;
      padding: 20px 10px;
      background: purple;
    }
  }

  &__name {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #fff;
    margin: 16px 0;
    text-transform: capitalize;
  }

  &__offers {
    padding: 10px 0 9px 48px;


  }

  &__offersText {
    &:hover {
      color: #EEB665;
      transform: translateX(0.2%);
    }
  }
  &__block {
    border-top: 1px solid #1F1B24;
    padding: 9px 0;
  }

  &__myProfile {
    color: white;
    position: relative;
    cursor: pointer;
    margin: auto;

    &:hover {
      //background: #1B1025;
    }
  }

  &__title {
    padding: 12px 0 10px 16px;
    text-transform: capitalize;
    width: 325px;
    margin: auto;

    //&:hover {
    //  color: #EEB665;
    //}
  }

  &__icon {
    color: transparent;
    position: absolute;
    left: 11px;
    margin-right: 15px;
    margin-top: 16px;
  }

  &__info {
    list-style: none;
    padding-left: 16px;
    padding-top: 8px;
    font-size: 14px;
    font-weight: 500;


    & li {
      padding: 3px 0;

      & a{
        display: inline-flex;
        width: 100%;
        padding: 5px 0;
      }
      &:hover {
        color: #EEB665;
      }
    }
  }

  &__text {
    padding-left: 10px;
    margin: auto 0;
  }
}

.passwordRules {
  color: white;
  font-size: 14px;
  width: 100%;
  height: auto;
  //background: #17141B;
  border-radius: 16px;
}

.passwordRules__list {
  list-style: none;
  padding-left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  margin: 16px 0;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    -webkit-align-items: center;
    letter-spacing: 0.1px;
    line-height: 24px;
    flex: 1;
    color: $spins-dark_text;

    &.checked {
      .passwordRules__listIcon {
        //background: $spins-orange-color;
      }

      .description {
        //color: $spins-orange-color;
      }
    }
  }

  &__item:first-child  {
    .passwordRules__listIcon {
      border-radius: 8px 0px 0px 8px;
    }
  }

  &__item:last-child  {
    .passwordRules__listIcon {
      border-radius: 0px 8px 8px 0px;
    }
  }
}

.passwordRules__listIcon {
  margin-right: 8px;
  //color:#9D0000;
  width: 100%;
  height: 6px;
  background: $spins-dark_text;
  display: inline-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;

}

.active{
  color: #EEB665;
}

.arrow {
  &:after {
    content: "";
    position: absolute;
    margin-top: -25px;
    right: 20px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #d2cddb;
  }
}

.wrapper {
  padding: 8px 0 5px 44px;
}

.activeTitle {
  background: #1F1B24;
  border-radius: 8px;
  width: 325px;
  color: white;

  //& .link {
  //  color: #EEB665;
  //
  //  color: #EEB665;
  //}

  &.arrow{
    &:after{
      transform: rotate(180deg);
      border-top: 5px solid #84789C;

    }
  }
}

.link {
  display: inherit;
  padding: 7px 0 6px 0px;
 }

//PROFILE
.profile {
  position: relative;
  z-index: 95;


  &__title {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    //text-transform: capitalize!important;
    //border-bottom: 1px solid #35303e;
    //padding-bottom: 5px;
    //color: white;
    //margin-bottom: 0px;
    color: #fff;
    position: relative;

  }

  &__titleCount {
    position: absolute;
    left: 168px;
    letter-spacing: .24px;
    text-transform: capitalize;
    font-size: 14px;
    color: #eeb665;
    font-weight: 500;
    padding-left: 10px;
  }

  &__wrapper {
    margin-top: 32px;
    padding-bottom: 24px;

    .header_title {
      font-size: 20px;
      font-weight: 600;
    }

    .col_box {
      padding: 24px;
      //background: $spins_gray_bg3;
      color: #fff;
      border-radius: 16px;

      display: flex;
      flex-direction: column;
      gap: 16px;

      &.nickname_col {
        height: 126px;
        justify-content: center;

        .nickname_set {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
  }

  &__block {

    &.nickname_block {
      display: flex;
      justify-content: space-between;
      //border: 1px solid #fff;
      align-items: center;
      gap: 16px;

      .profile__details {
        flex-grow: 2 !important;
        border: 1px solid $spins_border_gray_color !important;
        height: 48px ;
        border-radius: 100px;
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        input {
          font-size: 18px ;
          font-weight: 400;
          padding: 0 !important;
        }

      }

      button {
        flex-shrink: 0 !important;

      }
    }
  }

  &__details {
    padding: 5px 2px;
  }

  &__name {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    //color: $spins_text_gray_color;
  }

  &__input {
    white-space: nowrap;
    text-overflow: ellipsis;
    background: transparent;
    border: none;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
  }

  &__nickName {
    &:focus {
      outline: none;
    }
  }
}

.savebtn {
  display: inherit;
  //margin: 30px auto 0 ;
}

.agreement {
  padding-left: 30px;
  margin-top: 22px;
  color: #84789c;
  font-size: 11px;
}

.changePassword {
  max-width: 616px;
}

.inputWrapper {
  position: relative;

  &__old_pass {
    padding: 0 24px;

    .no_shadow {
      box-shadow: none;
    }

  }

  &__new_pass {
    background: $spins_gray_bg3;
    padding: 24px;
    border-radius: 16px;
  }

}

.passwordWrapper {
  position: absolute;
  max-width: 360px;
  height: 164px;
  background: #17141B;
  border-radius: 16px;
  padding-top: 10px;
  top: 20px;
  right: -371px;
}

.profile__titleCount {
  left: 192px;
}

.submenu_item {
  button {
    //@include dropdownStyle();
    //@include transition();
    width: 100%;
    border: none;
    font-size: 14px;
    font-weight: 500;

    &:hover {
      background: var(--gray-color1-hover);
    }
  }

  .label {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;

    svg {
      transform: rotate(270deg);
      @include regularTransition();
    }

    &.opened {
      svg {
        transform: rotate(90deg);
      }
    }
  }

  .submenu {
    display: flex;
    flex-direction: column;
    justify-content: end;
    overflow: hidden;
    @include regularTransition();

    a {
      padding: 10px 16px;
      @include regularTransition();
      font-size: 14px;
      text-decoration: none;
      border-radius: 30px;
      margin-bottom: 8px;
    }

    &.opened {
      margin-top: 8px;
      padding-bottom: 16px;
      //border: 1px solid #f00;
    }
  }
}

@media (max-width: 1670px)  {
  .passwordRules {
    position: relative;
    width: 100%;
    left: auto;
    margin-left: 0;
  }
  .passwordWrapper {
    position: relative;
    top: -2px;
    right: -3px;
    padding-top: 15px;
    max-width: 100%;
  }
}

@media(max-width: 1200px) {
  .profile__titleCount {
    left: 158px;
  }
}

@media(max-width: 1199px) {
  .accountMenu {
   display: none;
  }

  .profile__title {
    margin-bottom: 0;
    font-size: 28px;
  }

  .activeTitle {
    width: 100%;
    border-radius: 0;
  }

  .accountMenu__block {
    border-top: none;
  }

  .accountMenu__info {
    padding-left: 20px;
  }

  .profile__titleCount {
    left: 158px;
  }

  .accountMenu__title {
    padding-left: 0;
  }

  .profile__block {
    &:first-child {
      margin: 0;
    }

  }

  .profile {
    display: flex;
    align-items: center;
    //margin-bottom: 20px;
    max-width: 370px;
  }

  .accountMenu__info {
    & li {
      & a {
        padding: 11px 0 ;
      }
    }
   margin-bottom: 0;
  }


  .btnBlock {

    display: flex;
    justify-content: space-between;

    & button {
      flex: 1;
      padding: 0;
    }
  }
}

@media(max-width: 991px){
  .changePassword {
    margin: 15px 0;
    max-width: 100%;
  }

  .profile {
    &__columns {
      &.right {
        margin-top: 24px;
      }
    }
  }
}

@media(max-width: 767px) {
  .profile__title {
    font-size: 20px;
  }
  .profile {
    //margin-bottom: 0px!important;
  }
  .myDocsTitle {
    //margin-bottom: 15px!important;
  }
  .profile__title {
    font-size: 16px;
  }
  .titleGameBlock {
    margin-bottom: 30px!important;
  }
  .profile__title {
    font-size: 16px;
  }

  .profile__forGuest {
    margin-bottom: 0 !important;
  }
  .profile__titleCount {
    left: 95px;

  }
  .profile {
    max-width: 250px;
  }
}

@media(max-width: 600px) {
  .passwordRules {
    font-size: 11px !important;
  }
}

@media(max-width: 575px) {
  .profile__title {
    font-size: 28px;
  }

  .profile__title {
    font-size: 16px;
    line-height: 1;
  }

  .profile__titleCount {
    font-size: 13px;
  }

  .pageAvailableOffers {

    .pageHeading {
      flex-direction: column;
      align-items: start;

      &__switch{
        width: 100%;
        height: 40px;
        background: $background-color;
        border: 1px solid $dark2;
        border-radius: 100px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        position: relative;

        .sides {
          width: $sideWidth;
          text-align: center;
          font-size: 16px;
          font-family: Poppins;
          font-weight: 600;
          line-height: 16px;
          text-transform: uppercase;
          color: $dark_text;
          cursor: pointer;

          &.active {
            color: #fff;
          }
        }

        .selector {
          border-radius: 100px;
          width: 50%;
          height: 100%;
          border: 1px solid $primary-color;
          position: absolute;
          left: 0;
          -webkit-transition: 0.3s all;
          transition: 0.3s all;

          &.floated {
            -webkit-transition: 0.3s all;
            transition: 0.3s all;
            left: 50%;
          }
        }
      }
    }
  }

}

@media(max-width: 500px) {
  .profile {
    &__input {
      font-size: 18px;
    }
  }
}

@media(max-width: 425px) {
  .profile__columns {
    padding: 0;
  }
}

@media (max-width: 1600px) {

  .close_profile {
    &.right {
      background: $spins_gray_bg3;
      flex: 1;

      .check_boxes {
        flex-direction: row;
        width: 100%;
      }

      .check_boxes > * {
        flex: 1;
      }
    }
  }

}

@media (max-width: 1300px) {

  .close_profile {
    &.right {
      .check_boxes {
        flex-direction: column;
      }
    }
  }

}

@media (max-width: 1100px) {

  .close_profile {
    &.right {
      .check_boxes {
        flex-direction: row;
        width: 100%;
      }
    }
  }

}

@media (max-width: 600px) {

  .close_profile {

    &.left  {
      .action_container {
        flex-direction: column;
        button {
          font-size: 12px;
          white-space: nowrap;
          min-height: 48px;
        }
      }
    }

    &.right {
      .check_boxes {
        flex-direction: column;
      }
    }
  }

}


















