$spins-primary-color: #fbb90e;
$spins-yellow-color: #FBB90E;
$spins-orange-color: #F2994A;
$spins-primary-dark: #957243;
$spins-secondary-color: #910f5c;
$spins-background-color: #939393;
$spins-secondary-background-color: #292929;
$spins-dark1: #27222D;
$spins-dark2: #35303E;
$spins-dark_cards: #203432;
$spins-dark_cards_hover: #1F1B24;
$spins-dark_text: #A4AAB6;
$spins-dark_text2: #8B819C;
$spins-dark_text3: #8F84A4;
$spins-error: #ed3030;
$spins-danger: #9D0000;
$spins-danger2: #E02020;
$spins-danger_hover:  #b20707;
$spins-warning: #ff611b;
$spins-maxWidth: 1920px;
$spins-width1: 1180px;
$spins-width2: 900px;
$info: #008bc7;
$spins-text-color-primary: white;

//SPINS DARK
$spins_border_gray_color: #2d3543;
$spins_border_gray_color_hover: #535869;
$spins_text_gray_color: #6C6F74;
$spins_text_gray_color1: #404858;
$spins_icons_gray_color1: #404758;
$spins_profile_tilte_color: #6e788a;


$spins_gray_bg1: #181E2A;
$spins_gray_bg2: #12171e;
$spins_gray_bg3: #191D26;
$spins_gray_bg4: #181e2a;
$spins_gray_bg5: #232B3B;


//SPINS LIGHT
$spins_light_bg1: #E2E9F1;
$spins_yellow_1: #eca400;
$spins_yellow_2: #E7A500;
$spins_yellow_3: #B05A0A;
$spins_main_text: #2B303B;
$spins_font1: 'Poppins', sans-serif;
$pagination_height: 72px;
$header_height: 60px;
$header_height2: 52px;
$spins_green: #006E04;
$spins_btn_bgColor_primary: #b6c4d4;
$spins_btn_bgColor_seconadry: #D4DFEC;
$spins_border_light_2: #97a0ab;
$spins_hovered_bg1: #e7edf3;

//ACCOUNT STATUS COLORS START
$backgroundPending:rgba(176, 90, 10, 0.10);
$backgroundWin: rgba(0, 110, 37, 0.20);
$backgroundLost: rgba(190, 22, 22, 0.20);
$colorPending: #B05A0A;
$colorPendingBorder: #c0834b;
$colorWin: #006E25;
$colorLost: #BE1616;
//ACCOUNT STATUS COLORS END

//PADDINGS MARGINS START

$mainPaddingMargin1: 50px;
$mainPaddingMargin2: 30px;
$mainPaddingMargin3: 20px;
$mainPaddingMargin4: 15px;
$mainPaddingMargin5: 10px;

//PADDINGS MARGINS END



$primary-color: #EEB665;
$primary-dark: #957243;
$secondary-color: #006E04;
$background-color: #0E0C10;
$secondary-background-color: #292929;
$dark1: #27222D;
$dark2: #35303E;
$dark_cards: #17141B;
$dark_cards_hover: #1F1B24;
$dark_text: #84789C;
$dark_text2: #8B819C;
$dark_text3: #8F84A4;
$error: #ed3030;
$danger: #9D0000;
$danger_hover:  #b20707;
$warning: #ff611b;
$maxWidth: 1920px;
$width1: 1180px;
$width2: 900px;
$font: 'Poppins', sans-serif;

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}