@import "variables";
@import "mixins";


.main {
  //background: #505050;
  position: relative;
  margin-bottom: 16px;
  --content-width: 1300px;
  max-height: 520px;
  
  @media (max-width: 600px) {
    max-height: 370px;
  }

  .background {
    transition: 0.2s all;
    -webkit-transition: 0.2s all;
    background: linear-gradient(180deg, rgba(24, 24, 24, 0.1) 0%, rgba(182, 182, 182, 0.1) 110.29%);
    position: absolute;
    left: 50%;
    bottom: 0;
    top: 0;
    width: 100vw;
    transform: translateX(-50%);


    .decorations {
      transition: 0.2s all;
      -webkit-transition: 0.2s all;
      width: var(--content-width);
      height: 100%;
      max-width: 100%;
      margin: 0 auto;
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    
    @media (max-width: 1400px) {
      .decorations {
        background-size: cover;
      }
    }
  }

  .content {
    position: relative;
    width: var(--content-width);
    max-width: 100vw;
    margin: 0 auto;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;


     .header {
       height: 120px;
       display: flex;
       //justify-content: space-between;
       align-items: center;

       .games {
         font-size: 24px;
         font-style: normal;
         font-weight: 700;
         line-height: normal;
       }

       .end, .games {
         flex: 1;
       }

       .divider {
         display: none;
         width: 2px;
         background: $dark_text3;
         height: 24px;
         border-radius: 10px;
       }

       .end {
         display: flex;
         justify-content: end;
         transform: translateX(-2px);
       }

       .img_container {
         width: 312px;
         max-width: 312px;
         height: 120px;
         display: flex;
         justify-content: center;

         img {
           width: 312px;
           max-width: 312px;
           //max-width: calc(100vw - 40px);
           object-fit: contain;
         }

       }

       @media (max-width: 1400px) {
         flex-wrap: wrap;
         justify-content: center;
         height: fit-content;
         align-items: center;
         gap: 16px;

         .img_container {
           order: -1;
           flex: 1;
           min-width: 100%;
           height: fit-content;

           img {
             width: 200px;
             max-width: 200px;
           }
         }

         .divider {
           display: block;
         }

         .end, .games {
           flex: unset;
           font-size: 16px;

           img {
             height: 40px;
             width: auto;
           }
         }
       }
     }

    .body {

      .slider > div {
        justify-content: center;

        @media (max-width: 1400px) {
          justify-content: unset;
        }
      }

      .slider {
        max-width: 100%;


        .slide {
          //padding: 0;
          //margin: 0;
          width: fit-content;

          .jp_level {
            width: 292px;
            height: 340px;
            position: relative;

            &.skeleton {
              //margin-right: 32px;

              //@media (max-width: 1400px) {
              //  margin-right: 20px;
              //}
            }

            .image {
              position:absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;


              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center center;
              }

            }

            .jv_info {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              padding-top: 10px;
              position: relative;

              .top {
                position: relative;
                min-height: 115px;

                strong {
                  position: absolute;
                  top: 70%;
                  left: 50%;
                  transform: translate(-55%, -50%);
                  font-size: 24px;
                  text-transform: capitalize;
                }
              }

              .center_container {
                width: 100%;
              }

              .center {
                padding: 0 16px;
                border-radius: 40px;
                backdrop-filter: blur(15px);
                min-height: 72px;
                display: flex;
                align-items: center;
                gap: 8px;

                .val {
                  flex: 1;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 8px;
                }

                .valueContainer {
                  font-size: 22px !important;
                  //font-weight: 700 !important;
                  font-family: Roboto !important;
                }

                .ron {
                  font-family: Roboto;
                  font-size: 22px;
                  font-style: normal;
                  font-weight: 800;
                  line-height: 22px;

                }

                .badge {
                  @include jpBadge;

                }

              }

              .numbers {
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 100%;
                padding: 0 36px;
                margin-top: 16px;

                .nm {
                  display: flex;
                  flex-direction: column;
                  gap: 8px;

                  span {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 14px;
                  }

                  strong {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    color: $primary-color;
                  }

                  &.latest_date {
                    strong {
                      color: #fff;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: 16px;
                    }
                  }
                }

              }
            }
          }

          @media (max-width: 1400px) {
            &:first-child {
              margin-left: 24px;
            }

            &:last-child {
              margin-right: 24px;
            }
          }

          @media (max-width: 600px) {
            .jp_level {
              width: 148px;
              height: 190px;

              .jv_info {
                padding-top: 6px;

                .top {
                  min-height: unset;
                  max-height: 58px;

                  strong {
                    font-size: 12px;
                  }

                  .leveling {
                    transform: scale(0.48);
                    transform-origin: top center;
                  }
                }

                .center {
                  min-height: 32px;
                  padding: 0 4px;
                  gap: 4px;
                  justify-content: end;
                  //margin: 0 -100px;
                  margin-left: -5px;
                  margin-right: -5px;

                  .valueContainer {
                    font-size: 11px !important;
                    max-height: 11px !important;
                    //transform: scale(0.6);
                    //font-weight: 700 !important;
                    font-family: Roboto !important;
                  }

                  .ron {
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 800;
                  }

                  .badge {
                    font-size: 9px;
                    padding: 5px;
                    line-height: 10px;
                  }
                }

                .numbers {
                  margin-top: 10px;
                  padding: 0 16px;
                  gap: 8px;

                  .nm {
                    gap: 2px;

                    span {
                      font-size: 10px;
                    }

                    strong {
                      font-size: 12px;
                    }

                    &.latest_date {
                      strong {
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.regalJackpot {
    .background {
      border-bottom: 2px solid $dark_cards_hover;
      background: linear-gradient(180deg, rgba(14, 12, 16, 0.10) 0%, rgba(114, 1, 173, 0.10) 110.29%);
    }
  }


  &.playJackpot {
    .background {
      border-bottom: 2px solid $dark_cards_hover;
      background: linear-gradient(180deg, rgba(14, 12, 16, 0.10) 0%, rgba(32, 50, 250, 0.10) 110.29%);
    }
  }

  &.jackpotCards {
    .background {
      border-bottom: 2px solid $dark_cards_hover;
      background: linear-gradient(180deg, rgba(14, 12, 16, 0.10) 0%, rgba(3, 157, 223, 0.10) 110.29%);
    }
  }
    &.hotLuck {
    .background {
      border-bottom: 2px solid $dark_cards_hover;
      background: linear-gradient(180deg, rgba(14, 12, 16, 0.10) 0%, rgba(154, 110, 58, 0.10) 110.29%);
    }
  }

  &.highCash {
    .background {
      border-bottom: 2px solid $dark_cards_hover;
      background: linear-gradient(180deg, rgba(14, 12, 16, 0.10) 0%, rgba(24, 29, 87, 0.10) 110.29%);
    }
  }

  &.egypt {
    .background {
      border-bottom: 2px solid $dark_cards_hover;
      background: linear-gradient(180deg, rgba(14, 12, 16, 0.10) 0%, rgba(194, 140, 48, 0.10) 110.29%);
    }
  }

  &.clover {
    .background {
      border-bottom: 2px solid $dark_cards_hover;
      background: linear-gradient(180deg, rgba(14, 12, 16, 0.10) 0%, rgba(2, 134, 82, 0.10) 110.29%);
    }
  }

  &.bellLink {
    .background {
      border-bottom: 2px solid $dark_cards_hover;
      background: linear-gradient(180deg, rgba(14, 12, 16, 0.10) 0%, rgba(173, 21, 1, 0.10) 110.29%);
    }
  }

}