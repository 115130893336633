@import 'variables';
@import "mixins";

.modal {
  width: 500px !important;
  min-height: 860px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(180deg, rgba(148, 90, 193, 0) 25.21%, rgba(89, 53, 136, 0.53) 45.47%, #945AC1 67.28%), #27064A !important;
  padding: 0 !important;
  border: 4px solid #ff0000;

  .bottomSection {
    margin-bottom: 20px;
    z-index: 2;

    .depositLock {
        color: #fff;

      .buttonContainer {
        background: #27222D;
        margin: 0 auto ;
        width: 360px;
        padding: 10px 10px;
        border-radius: 100px;
        display: flex;
        flex-direction: row;
        gap: 20px;

        button {
          flex: 1;
        }
      }

      .lockMessageContainer {
        margin-bottom: 35px !important;

        &.deposits {
          margin-bottom: 0 !important;
        }

        .lockMessage {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
        }
      }


      .depositSteps {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        border: 1px solid #D2CDDB;
        background: #6d3f8e;
        width: 360px;
        height: 56px;
        border-radius: 100px;
        margin: 8px auto 12px;

        .completed {
          color: $primary-color;
        }

        .step {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .stepCount {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
          }

          .depositText {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            text-transform: uppercase;
          }
        }
      }

      .allDayLock {

        margin: 0 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #0E0C10;
        padding: 24px;
        gap: 8px;
        border-radius: 12px;

        span {
          text-align: start !important;
          font-weight: 500 !important;
        }
      }
    }
  }


  //Overlay modals/alerts

  .overlayContainer {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3000000000;


    .main {
      position: relative;
      margin: 100px auto 0;
      width: 360px;

      .mainImg {
        width: 100%;
      }

      .platform {
        position: absolute;
        bottom: .5%;
        left: 50%;
        transform: translateX(-50%);
        width: 98%;
      }

      .gift {
        position: absolute;
        bottom: 60px;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
      }

      .prize {
        position: absolute;
        bottom: 20px;
        left: 0;
        height: fit-content;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          height: 108px;

        }
      }
    }
  }
}


@media (max-height: 920px) {
  .modal {
    min-height: 700px;
    width: 400px !important;

    .bottomSection {
      margin-bottom: 20px;
      z-index: 2;


      .depositLock {
        color: #fff;

        .lockMessage {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          font-size: 14px;
        }

        .buttonContainer {

          button {
            min-height: 40px !important;

          }
        }

        .depositSteps {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
          border: 1px solid #D2CDDB;
          background: #6d3f8e;
          width: 360px;
          height: 50px;
          border-radius: 100px;
          margin: 8px auto ;

          .completed {
            color: $primary-color;
          }

          .step {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .stepCount {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
            }

            .depositText {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 400;
              font-size: 10px;
              text-transform: uppercase;
            }
          }
        }
      }
    }


    .overlayContainer {

      .main {
        position: relative;
        margin-top: 50px;

      }
    }
  }
}

@media (max-width: 500px) {
  .modal {
    width: 320px !important;

    .bottomSection {
      margin-bottom: 20px;
      z-index: 2;

      .depositLock {
        color: #fff;

        .lockMessage {
          font-size: 14px;

        }

        .buttonContainer {
          width: 300px;
          button {
            min-height: 40px !important;
            font-size: 10px;
          }
        }

        .allDayLock {
          margin: 0 15px;
          display: flex;
          flex-direction: column ;
          align-items: center;
          justify-content: center;
          background: #0E0C10;
          padding: 14px;
          gap: 8px;
          align-items: flex-start;
          border-radius: 12px;

          button {
            margin: 0 auto;
          }

          span {
            text-align: center !important;
            font-weight: 500 !important;
          }
        }

        .depositSteps {
          width: 300px;
          height: 50px;
          border-radius: 100px;
          margin: 8px auto 27px;

          .completed {
            color: $primary-color;
          }

          .step {
            .stepCount {
              font-size: 14px;
            }

            .depositText {

            }
          }
        }
      }
    }

    .overlayContainer {
      .main {
        width: 300px;
      }
    }
  }
}

@media (max-width: 420px) {

  .modal {
    .bottomSection {
      .depositLock {

        .buttonContainer {
          width: 300px;
          gap: 10px;
          button {
            min-height: 40px !important;
            font-size: 10px;
          }
        }
      }
    }

    .overlayContainer {
      .main {
        margin-top: 80px;
        .prize {

          img {
            height: 80px;

          }
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .modal {
    width: 300px !important;

    .bottomSection {
      margin-bottom: 20px;
      z-index: 2;

      .depositLock {
        color: #fff;

        .lockMessage {
          font-size: 14px;

        }

        .buttonContainer {
          width: 280px;
          button {
            min-height: 40px !important;
          }
        }

        .depositSteps {
          width: 280px !important;
          height: 50px;
          border-radius: 100px;
          margin: 8px auto 27px;
          justify-content: space-between;
          padding: 0 20px;

          .completed {
            color: $primary-color;
          }

          .step {
            .stepCount {
              font-size: 12px;
            }

            .depositText {

            }
          }
        }
      }
    }

    .overlayContainer {
      .main {
        width: 270px;
      }
    }
  }
}

@media (max-height: 720px) {
  .modal {
    min-height: 650px;
    width: 400px !important;

    .bottomSection {
      margin-bottom: 20px;
      z-index: 2;

      .depositLock {
        color: #fff;

        .lockMessage {
          font-size: 14px;
        }

        .buttonContainer {
          width: 280px;

          button {
            min-height: 40px !important;
            font-size: 9px;
          }
        }

        .depositSteps {
          margin: 8px auto ;

          .completed {
            color: $primary-color;
          }

          .step {
            .stepCount {
              font-size: 14px;
            }
          }
        }
      }
    }

    .overlayContainer {
      .main {
        position: relative;
        margin-top: 50px;
        width: 300px;

        .prize {
          img {
            height: 80px;
          }

        }
      }
    }
  }
}

@media (max-height: 670px) {
  .modal {
    min-height: 600px;

    .bottomSection {
      margin-bottom: 20px;
      z-index: 2;

      .depositLock {
        color: #fff;

        .lockMessage {
          font-size: 14px;
        }

        .depositSteps {
          width: 300px;
          height: 50px;
          border-radius: 100px;
          margin: 8px auto;

          .completed {
            color: $primary-color;
          }

          .step {

            .stepCount {

              font-size: 14px;
            }

            .depositText {

            }
          }
        }
      }
    }

    .overlayContainer {
      .main {
        position: relative;
        margin-top: 15px;
        width: 280px;

        .prize {
          img {
            height: 80px;
          }

        }
      }
    }
  }
}

@media (max-height: 630px) {

  .modal {
    min-height: 570px;

    .bottomSection {
      margin-bottom: 20px;
      z-index: 2;

      .depositLock {
        color: #fff;

        .lockMessage {
          font-size: 14px;
        }

        .depositSteps {
          width: 300px;
          height: 45px;
          border-radius: 100px;
          margin: 8px auto;

          .completed {
            color: $primary-color;
          }

          .step {

            .stepCount {
              font-size: 12px;
            }

            .depositText {

            }
          }
        }
      }
    }

    .overlayContainer {
      .main {
        position: relative;
        margin-top: 30px;

        .prize {
          img {
            height: 80px;
          }
        }
      }
    }
  }
}



//
//@media (max-height: 800px) {
//  .modal {
//    min-height: 500px;
//  }
//
//}
//
