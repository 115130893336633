.topGamesSlider {

&__box {
  display: flex;
  position: relative;
  z-index: 100;
  height: 39px;
  margin-bottom: -32px;
}


  &__titleBlock {
    display: flex;
    justify-content: space-between;
  }

  &__pages {

      width: 46px;
      height: 26px;
      background: #EEB665;
      border: 8px solid #1B1025;
      border-radius: 20px;
  }

  &__card {}

  &__block {}

  &__wrapper {
    position: relative;
    padding-left: 140px;



      &:hover {
        .topGamesSlider__index {
          transform: translateX(10px) translateY(25px);
          -webkit-transform: translateX(10px) translateY(25px);
          opacity: 0.5;
        }
        .topGamesSlider__card {
          transform: scale(1.0,1);
          -webkit-transform: scale(1.0,1);
        }
      }
  }

  &__index {
    margin-left: -40px;
    transform: translateY(25px);
    -webkit-transform: translateY(25px);
    font-size: 310px;
    line-height: 1;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    color: black !important;
    text-stroke: 4px #444242;
    -webkit-text-stroke: 4px #444242;
    transition: transform 0.5s;
    -webkit-transition: transform 0.5s;
    letter-spacing: -55px;

    //UNDO start


    //UNDO end
  }

  &__block {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    margin-right: 40px;


  }

  &__swiper_item {
    width: auto !important;
    height: auto !important;
    left: 70px;
  }

  &__card {
    //transform: translateX(-70px);
    //-webkit-transform: translateX(-70px);

    transform: translateX(-13px);
    -webkit-transform: translateX(-13px);
  }
}

.swiper_top_games {

}

.hover {
  .topGamesSlider__index {
    transform: translateX(20px) translateY(25px);
    -webkit-transform: translateX(20px) translateY(25px);
  }
}

@media (max-width: 1200px) {

  .hover {
    //.topGamesSlider__index {
    //  transform: translateX(0px);
    //  -webkit-transform: translateX(0px);
    //}
  }

}

@media (max-width: 991px) {
  .topGamesSlider__block {
    //margin-right: 10px;
    margin-right: 21px;
  margin-top: -55px;
  margin-bottom: -55px;
  }

  .topGamesSlider__swiper_item:last-child{

    .topGamesSlider__card {
      //transform: translateX(-37px);
    }
  }

  .topGamesSlider__index {
    //font-size: 130px;
    font-size: 100px;
    text-stroke: 4px #444242;
    -webkit-text-stroke: 2px #444242;
    letter-spacing: -22px;
    z-index: 9;
    margin-bottom: -60px;
    margin-left: -72px;
    margin-right: -50px;
    pointer-events: none;

    transform: translateY(10px) scale(0.3);

    &.last {
      margin-left: -125px;
      margin-right: -90px;
    }

    //max-width: 75px;
    //width: 100%;
  }

  .topGamesSlider__card {
    //transform: translateX(-70px);
    //-webkit-transform: translateX(-43px);

    //transform: translateX(-14);
    //-webkit-transform: translateX(-14px);


    transform: translateX(-3);
    -webkit-transform: translateX(-3px);


    max-width: 100px;
  }

  .topGamesSlider__card:last-child {

  }

  .hover {
    .topGamesSlider__index {
      transform:  scale(0.3);
      -webkit-transform:  scale(0.3);
    }
  }

}