@import "variables";

$sideWidth: 153px;

.main {
  width: 315px;
  height: 40px;
  background: $background-color;
  border: 1px solid $dark2;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .sides {
    width: $sideWidth;
    text-align: center;
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
    color: $dark_text;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &.active {
      color: #fff;
    }
  }

  .selector {
    border-radius: 100px;
    width: $sideWidth;
    height: 100%;
    border: 1px solid $primary-color;
    position: absolute;
    left: 0;
    -webkit-transition: 0.3s all;
    transition: 0.3s all;

    &.floated {
      -webkit-transition: 0.3s all;
      transition: 0.3s all;
      left: calc(100% - $sideWidth);
    }
  }
}

@media (max-width: 575px) {

  .main {
    width: 100%;
    height: 40px;
    background: $background-color;
    border: 1px solid $dark2;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    position: relative;

    .sides {
      width: $sideWidth;
      text-align: center;
      font-size: 16px;
      font-family: Montserrat;
      font-weight: 700;
      line-height: 16px;
      text-transform: uppercase;
      color: $dark_text;
      cursor: pointer;

      &.active {
        color: #fff;
      }
    }

    .selector {
      border-radius: 100px;
      width: 50%;
      height: 100%;
      border: 1px solid $primary-color;
      position: absolute;
      left: 0;
      -webkit-transition: 0.3s all;
      transition: 0.3s all;

      &.floated {
        -webkit-transition: 0.3s all;
        transition: 0.3s all;
        left: 50%;
      }
    }
  }
}