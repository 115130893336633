.wrapper {
  //padding: 12px 0;
  position: relative;


  &.active {
    .dropDown {
      display: block;
    }

    .vector {
      & svg{
        transform: rotate(180deg);
        //color:  #EEB665;
      }
    }

    .auth {
      background: #EEB665;
    }
  }
}

.vector {
  position: absolute;
  top: 35%;
  right: -14px;

  & svg {
    color: #84789C;
  }
}

.auth {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: #27222D;
  //padding: 8px 10px;
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  color: white;
  border: none;
  padding: 0;

  img {
    width: 100%;
    height: 100%;
  }

  //&:after {
  //  content: "";
  //  position: absolute;
  //  margin-top: -29px;
  //  right: -18px;
  //  top: 50px;
  //  width: 0;
  //  height: 0;
  //  border-left: 7px solid transparent;
  //  border-right: 7px solid transparent;
  //  border-top: 7px solid #d2cddb;
  //}

  &__name {
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    font-weight: 500;
    background: transparent;
    border: none;
    color: white;

  }
}

.dropDown {
  top: 70px;
  right: -15px;
  padding: 15px;
  background: #1B1025;
  border-radius: 12px;
  width: 240px;
  position: absolute;
  display: none;
  cursor: pointer;
  z-index: 500;

  &__name {
    color: #EEB665;
    padding: 10px 0 5px 8px;
    font-size: 14px;
    margin-bottom: 5px;
  }

  &__links {
    list-style: none;
    padding: 0 0 0 0;
    font-size: 15px;
    font-weight: 500;

    & li {
      //padding: 9px 8px;
      border-radius: 8px;
      & a {
        padding: 9px 8px;
        display: inline-block;
        width: 100%;
      }


      &:hover {
        background: #0E0C10;
      }
    }
  }
  &__logOut {
    display: flex;
    align-items: baseline;
    background: transparent;
    border: none;
    color: white;
    padding: 0;
  }
.userLogOut {
  //color: red;
  padding-left: 8px;
  font-size: 15px;
}

  &:after {
    content: "";
    position: absolute;
    top: -22px;
    right: 13px;
    height: 0;
    border-left: 28px solid transparent;
    border-right: 22px solid transparent;
    border-bottom: 26px solid transparent;
    z-index: 1;
  }
  &:before {
    content: "";
    position: absolute;
    top: -14px;
    right: 25px;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 18px solid transparent;
  }
}























