.sidebarMenu {
  margin-bottom: 200px;
  border: 1px solid #604180;
  border-radius: 24px;
  max-width: 350px;
  position: sticky;
  top: 123px;

  &__list {
    list-style: none;
    padding: 0;

    & li {
      font-size: 16px;
      font-weight: 500;
      //padding: 12px 0 12px 30px;
      & a {
        display: block;
        padding: 12px 0 12px 30px;


      }

      & .active {
        background: #604180;
      }

      &:first-child {
        margin-top: 1rem;
        //margin-top: 16px;
      }

      & a {
        color: white;
      }

      &:hover {
        background:  #604180;

      }
    }
  }
}
@media (max-width: 767px) {
  .sidebarMenu {
    max-width: 100%;
    margin-bottom: 60px;
  }
}