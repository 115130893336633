.input__field {
  padding:22px 20px 12px 20px !important;
  width: 360px;
  height: 56px;
  background: #fff;
  border: 1px solid transparent;
  border-radius: 30px;
  position: relative;

  &_block {
    text-align: start;
    position: relative;
    margin-bottom: 30px;

    & input {
      width: 100%;
    }

    input {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: textfield;
      font-weight: 500;
      font-size: 14px !important;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #FFFFFF;
      outline: none;
      margin: 0 !important;
      background: #1F1B24 !important;
      padding-top: 30px;
      border: 1px solid #1F1B24;

      &:-webkit-autofill {
        box-shadow: 0 0 0 1000px #1b1b1b inset !important;
        -webkit-box-shadow: 0 0 0 1000px #1b1b1b inset !important;
        -webkit-text-fill-color: white !important;
        outline: none !important;

        &:not(:focus) {
          border-color: #1F1B24 !important;
        }
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
        -webkit-appearance: none;
      }
      //&.contactForm-btnClose {
      //  display: none;
      //}

      &:focus {
        border: 1px solid #EEB665;


        &:placeholder-shown + .label {
          color: #EEB665;

        }
        //&.contactForm-btnClose {
        //  display: none;
        //}

        &:not(:placeholder-shown) + .label {
          color: #EEB665;
        }

      }

      &:focus ~ .contactForm__btnClose {
        opacity: 1;
      }

      &:placeholder-shown + .label {
        transform: translateY(20px);
        text-transform: uppercase;


      }
      &:placeholder-shown + .label ~ .contactForm-btnClose {
        display: none;
      }

      &::-webkit-input-placeholder {
        opacity: 0;
      }
      &::-moz-placeholder {
        opacity: 0;
      }
      &:-ms-input-placeholder {
        opacity: 0;
      }
      &:-moz-placeholder {
        opacity: 0;
      }

      &:focus::-webkit-input-placeholder  {
        //opacity: 1;
      }

      &:not(:placeholder-shown) + .label,
      &:focus + .label {
        transform: translateY(8px) scale(1);
        cursor: pointer;
        //color: #EEB665;


      }
    }

    .searchIcon {
      position: absolute;
      top: 0;
      right: 20px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &::placeholder {
    font-weight: 400;
    font-size: 12px;
    line-height: 8px;
    color: #84789C;
    opacity: 1;

  }

  &_passwordBtn {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 18px;
    background: transparent;
    border: none;
    cursor: pointer;
    color: #84789C;

    &.icon__color {
      color: #fff;
    }
  }
}


.label {
  color: #84789C;
  position: absolute;
  left: 20px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  pointer-events: none;
  transition: all .2s ease;
  -webkit-transition: all .2s ease;
  //top: -4px;
}

.contactForm__btnClose {
  background: transparent;
  border: none;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: #84789C;
  opacity: 0;
  & svg {
    width: 12px;
    height: 12px;
  }

}