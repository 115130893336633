.root {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  background-color: #1E1E1E;
  height: 56px;
  justify-content: center;
  padding: 0 21px;

  & > button {
    min-height: 40px;
    margin: 0 5px;
    //flex: 1;
  }


}

@media (min-width: 1200px) {
  .root {
    display: none;
  }
}