@import 'variables';
@import "mixins";

.tabs {
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: 32px;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #27222D;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1px;
    z-index: 0;
  }

  &__item {
    outline: none;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid transparent;
    color: #8F84A4;
    height: 38px;
    line-height: 1;
    padding: 0 24px;
    position: relative;
    z-index: 1;
    font-size: 12px;
    font-weight: 600;

    &__active {
      color: $primary-color;
      border-color: $primary-color;
    }
  }
}

.paragraph {
  color: #8F84A4;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: left;

  a {
    color: $primary-color;
    text-decoration: underline;
  }
}

.modal {
  width: fit-content !important;

  .modalContent {
    width: 417px;

    &__item {
      min-width: 80px;
      max-width: 80px;
      //border: 1px solid #fff;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 6px;
      //height: 200px;

       &:hover {
         color: $primary-color;
       }
    }

    &__buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__smName {
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
      margin-top: 5px;
    }

    &__smImage {
      //width: 100%;
      width: 100px;
      height: 100px;
      //border: 1px solid #fff;
    }

    &__shareSection {
      width: 100%;
      padding: 8px 8px 8px 16px;
      border: 1px solid $dark_cards_hover;
      margin-top: 30px;
      background-color: $dark_cards;
      border-radius: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 44px;

      .link {
        max-width: 220px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 171.429% */
        letter-spacing: 0.1px;
        text-overflow: ellipsis;
        //border: 1px solid #fff;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}

@media(max-width: 575px) {
  .modal {
    .modalContent {
      width: 330px;

      &__item {
        min-width: 70px;
        max-width: 70px;

        &:hover {
          color: $primary-color;
        }
      }

      &__smName {
        font-size: 12px;
      }

      &__smImage {
        //width: 100%;
        width: 70px;
        height: 70px;
        //border: 1px solid #fff;
      }
    }
  }
}

@media(max-width: 440px) {
  .modal {
    .modalContent {

      &__item {
        min-width: 60px;
        max-width: 60px;

        &:hover {
          color: $primary-color;
        }
      }

      &__smName {
        font-size: 11px;
      }

      &__smImage {
        //width: 100%;
        width: 60px;
        height: 60px;
        //border: 1px solid #fff;
      }

      &__shareSection {
        gap: 14px;

        .link {
          width: 50%;
        }

        .button {
          font-size: 12px !important;
        }
      }
    }
  }
}

@media(max-width: 440px) {
  .modal {
    .modalContent {
      width: 100%;
      gap: 10px;

      &__item {
        min-width: 50px;
        max-width: 45px;

        &:hover {
          color: $primary-color;
        }
      }

      &__smName {
        font-size: 9px;
      }

      &__smImage {
        //width: 100%;
        width: 45px;
        height: 45px;
        //border: 1px solid #fff;
      }

      &__shareSection {
        gap: 8px;

        .link {
          width: 50%;
        }

        .button {
          font-size: 10px !important;
          padding: 10px 16px !important;
          min-height: unset;
        }
      }
    }
  }
}