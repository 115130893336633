@import "variables";

.themes {
  //height: 39px;
  //margin-bottom: 50px;

  position: relative;

  &__wrapper {

  }
  .theme__Slide {
    margin-right: 20px;
    //added start
    .cardLink {
      display: block;
      width: 500px;
    }
    .cardLinkSkeleton {
      display: block;
      width: 500px;
      height: 289px;
    }

    //added end
  }
  .swiper-slide {
    padding-bottom: 20px;
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    //line-height: 29px;
    text-transform: capitalize;
    padding-left: 50px;
    color: #FFFFFF;
  }

  &__limit {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #EEB665;
    padding-left: 7px;
    position: absolute;
  }

  &__image {
    //width: 500px !important;
    transition: transform 0.5s;
    z-index: 898989999;
    max-width: 100%;
    height: auto;

    &:hover {
      transform: scale(1.05);
      -webkit-transform: scale(1.05);
      opacity: 0.5;
    }
  }

  .swiper-button-prev, .swiper-button-next {
    position: absolute;
    top: 50%;
    width:20px !important;
    height:30px!important;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .themes__mySwiper, .swiper-wrapper {
    //padding-top: 50px;
    padding-bottom: 10px;

  }
  &__box {
    position: relative;
    z-index: 99;
    display: flex;
    height: 39px;
    width: 500px;
    margin-bottom: -33px;
  }

}
@media(max-width: 991px) {
  .themes__image {
    max-width: 151px;
    width: 100%;
  }

  .themes__title {
    padding-left: 30px;
  }

  .themes {
    .theme__Slide {
      margin-right: 8px!important;
      width: 151px;
      //added start
      .cardLink {
        display: block;
        width: 151px;
      }
      .cardLinkSkeleton {
        display: block;
        width: 151px;
        height: 87.22px;
      }
      //added end
    }
  }
}