.sliderContainer {
  overflow-x: scroll;
  .slider  {
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin-top: 30px;

  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.skeletonGameCard {
  background: #383838;
  border-radius: 5px;
  margin-right: 20px;
  width: 240px;
  height: 358px;
  transform: scale(0.95);
  display: block;
}

@media (max-width: 991px) {
  .skeletonGameCard {
    background: #383838;
    border-radius: 5px;
    width: 133px;
    height: 212px;
    display: block;
  }
}