@import 'variables';
@import "mixins";

.themes__image {
  max-width: 100%;
  height: auto;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;

  &__col {
    padding-left: 8px;
    padding-right: 8px;
    aspect-ratio: 440.5/255.44;
    flex: 0 0 25%;
    width: 25%;
  }
}

.item {
  position: relative;
  margin-bottom: 8px;
  display: block;

  img {
    filter: none;
    -webkit-filter: none;
    transform: scale(1);
    -webkit-transform: scale(1);
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
  }

  &__button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    white-space: nowrap;
    opacity: 0;

    &:hover {
      background-color: $primary-color;
      color: #000 !important;
    }
  }

  &:hover {
    img {
      transform: scale(1.05);
      -webkit-transform: scale(1.05);
      filter: brightness(0.5);
      -webkit-filter: brightness(0.5);
    }

    .item__button {
      opacity: 1;
    }
  }
}

@media (max-width: 1199px) {
  .item__button {
    display: none;
  }
}

@media (max-width: 991px) {
  .grid__col {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
  }
}

@media (max-width: 575px) {
  .grid__col {
    flex: 0 0 50%;
    width: 50%;
  }
}