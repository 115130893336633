@import 'variables';
@import "mixins";

.root {
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  border: 1px solid transparent;
  min-height: 56px;
  padding: 0 16px;
  border-radius: 50px;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
  cursor: pointer;
  user-select: none;
  position: relative;
  font-size: 14px;
  //z-index:499;

  .mobileFilters {
    display: none;
  }

  .select {
    opacity: 0;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    transform: translateY(10px);
    -webkit-transform: translateY(10px);

    &.opened {
      opacity: 1;
      pointer-events: initial;
      transform: translateY(0);
      -webkit-transform: translateY(0);

    }

    .checkItem {
      display: flex;
      flex-direction: row;
      align-items: start;

      .checkItem__label {
        transform: translateY(8px);
      }
    }
  }

  .providersContainer {
    position: absolute;
    min-width: 373px;
    max-height: 686px;
    overflow-y: scroll;
    z-index: 1;
    top: 50px;
    left: -50%;
    background-color: #1B1025;
    border-radius: 12px;
    padding: 20px;
    transform: translateY(10px);
    opacity: 1;
    transition:  0.3s opacity;
    -webkit-transition:  0.3s opacity;
    -moz-transition:  0.3s opacity;


    &.features {
      min-width: 600px;

      .grid {
        width: 100% !important;
      }

    }

    .grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 10px;
      grid-row-gap: 10px;

      &.checkBoxesGrid {
        grid-template-columns: repeat(2, 1fr);
        width: 436px;

        .checkItem {
          &__label {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
          }
        }
      }

      .item {
        background-color: #0E0C10;
        border: 1px solid #0E0C10;
        max-width: 110px;
        width: 100%;
        height: 48px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        -webkit-align-items: center;
        cursor: pointer;
        transition: all .3s ease;
        -webkit-transition: all .3s ease;
        border-radius: 8px;
        padding: 5px;

        img {
          display: block;
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
          transition: all .3s ease;
          -webkit-transition: all .3s ease;
        }

        &:hover {
          border-color: $dark_text;
        }

        &.active {
          border-color: $primary-color;
        }


      }
    }
  }

  &.hideBorder {
    border-color: transparent !important;
    //border-bottom: 1px solid #1F1B24 !important;
    //border-radius: 0 !important;
    //width: 100%;
    padding: 0;
    min-height: 45px;
    //margin-bottom: 20px;
  }


  &__selected {
    text-transform: uppercase;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    margin-right: 15px;

    &.counter {
      width: 24px;
      height: 24px;
      background: #9D0000;
      border-radius: 60px;
      display: flex;
      font-size: 12px;
      align-items: center;
      justify-content: center;
    }

  }


  &__icon {
    margin-right: 12px;
    color: $primary-color;
  }

  &__label {
    margin-right: 8px;
    color: $primary-color;
    text-transform: uppercase;
  }

  &__caret {
    opacity: .5;
    transform: rotate(0);
    -webkit-transform: rotate(0);
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
  }

  &__options {
    //border: 1px solid #fff;
    position: absolute;
    min-width: 206px;
    z-index: 1;
    right: 15px;
    top: 54px;
    background-color: #1B1025;
    border-radius: 12px;
    padding: 8px;
    transform: translateY(10px);
    -webkit-transform: translateY(10px);
    opacity: 0;
    pointer-events: none;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;

    &__item {
      padding: 8px;
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.1px;
      background-color: transparent;
      cursor: pointer;
      border-radius: 8px;

      &.selected, &:hover {
        background-color: #0E0C10;
      }
    }
  }

  &.active {
    .root__caret {
      opacity: 1;
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
    }

    .root__options {
      opacity: 1;
      transform: translateY(0);
      -webkit-transform: translateY(0);
      pointer-events: initial;

    }
  }

  &:hover, &.active {
    border-color: $dark_text;
  }
}

@media(max-width: 1620px) {
  .root.active{
    .root__options {
      min-width: 317px;
      left: 0;
    }
  }

  .root {
    display: none;
    display: flex;

    .desktopFilters {
      display: none;
    }

    .mobileFilters {
      &.opened {
        display: block !important;
        position: unset;
        transform: unset;
        width: 100%;
        border-radius: 0;
        top: 0;
        left: 0;

      }
    }
  }

  .root.mobileFilter {
    display: flex;
    border-radius: 0;
    padding-left: 0;
    margin-top: 20px;

  }

  .root:hover, .root.active{
    border-color: transparent!important;
  }

}