@import 'variables';
@import "mixins";


.headerContainerWrapper {
  position: fixed !important;
  left: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  padding-top: 2px;
  top: -2px;
}

.headerContainer {
  padding: 0 50px 0 50px;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  //min-height: 100px;

  &__buttons {
    .questButton {
      background: none;
      border: none;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    -webkit-align-items: center;
    width: 100%;
    min-height: 100px;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .default-page-wrap {
    background: #161827;
  }

  &__nav {
    display: flex;
    align-items: center;
  }

  &__logo {
    position: absolute;
    top: 0;
    right: 50%;
    transform: translateX(50%);
  }
  &__mobileLogo {
    display: none;
  }

  &__navList {
    display: flex;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
  }
  &__list {

    &.sportLink {
      //border: 1px solid #fff;
      a {
        color: $primary-color !important;
      }
    }

    &.quest_item {
      background: rgba(238, 182, 101, 0.1);
      height: 48px !important;
      -webkit-transition: 0.3s all;
      transition: 0.3s all;
      margin-bottom: 16px;
      margin-top: 16px;

      .right {
        color: $primary-color;
      }

      a {
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-right: 10px;
        transform: translateY(-1px);
      }

      &:hover {
        background: rgba(238, 182, 101, 0.1);
        -webkit-transition: 0.3s all;
        transition: 0.3s all;
        box-shadow: 0px 20px 80px 0px rgba(238, 182, 101, 0.10);
      }
    }
  }
}

//sidebar

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 12px;
  height: 100%;
  width: 385px;
  background: #19131D;
  position: fixed;
  top: 0;
  z-index: 55555;
  transform: translateX(-110%);
  -webkit-transform: translateX(-110%);
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
  height: 100vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  &__seeProfile {
    display: none;
  }


  &__close {
    display: block;
    background: transparent;
    border: none;
    color: #84789C;
    margin-top: 21px;
    margin-left: 28px;
    margin-right: 29px;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;


    &:hover {
      color: #EEB665;
    }
  }

  &__logo {
    display: block;
    text-align: center;
  }

  &__userBlock {
    display: flex;
    margin: 10px 0;
  }

  &__balanceBlock {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  &__balance {
    //margin-right: 55px;
  }
  &__balanceItem {
    font-size: 16px;
    font-weight: 700;
    color: #eeb665;
  }
  &__currence {
    color: #eeb665;
    font-size: 14px;
    font-weight: 400;
  }

  &__userData {
    display: flex;
    position: relative;
    margin-left: 16px;
    flex-direction: column;
    justify-content: space-around;
    font-size: 16px;
    color: #d2cddb;
    line-height: 1;
    flex: 1;
    align-items: flex-start;


  }
  &__userBtn {
    display: none;
  }

  &__user {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #1e0f28;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
    }

  }

  &__userName {
    font-weight: 700;
    font-size: 24px;
    line-height: 1;
    color: #fff;
  }




  &__btn {
    padding: 15px 0 16px 0 ;
    text-align: center;
    //border-bottom: 1px solid rgba(132,120,156,.6);
    margin: 0 28px;

  }

  &__btnAside {
    display: none;
  }

  &__links {
    //border-bottom: 1px solid rgba(132,120,156,.6);
    list-style: none;
    padding: 0 28px;
    padding-left: 25px;
    border-bottom: 1px solid #27222D;
    margin-bottom: 0;

    //&__linkSearch {
    //  //&button {
    //    color: white;
    //  //}
    //
    //}
    & button {
      background: transparent;
      border: none;
      outline: none;
      color: white;
      width: 100%;
      text-align: left;
      display: inline-flex;
      padding: 10px 0;
      min-height: 56px;
      align-items: center;
      -webkit-align-items: center;


      &:hover {
        color: inherit;
      }
    }

    & li {
      padding: 5px 0px;
      text-transform: capitalize;
      border-radius: 8px;
      padding-left: 10px;

      &:hover {
        background: #0E0C10;
      }

      a {
        display: inline-flex;
        width: 100%;
        padding: 10px 0;
        min-height: 52px;
        align-items: center;
        -webkit-align-items: center;
      }

      img, svg {
        margin-right: 15px;
      }
    }
  }
  &__lastLink {
    border-bottom: none;
  }

  &__socialBlock {
    text-align: center;
    padding-top: 30px;

    flex: 1;
    display: flex;
    align-items: flex-end;
    -webkit-align-items: flex-end;
    justify-content: center;
    padding-bottom: 20px;
  }

  &__facebok {
    background: transparent;
    color: red;
  }

  &__socialLink {
    background: transparent;
    margin-right: 10px;
    padding: 7px;
    border: 1px solid #84789C;
    border-radius: 50px;

    &:hover svg{
      color: #EEB665;
    }

    &:hover {
      border: 1px solid  #EEB665;
      color: #EEB665;
    }
  }

  &.active {
    //border: 1px solid #fff;
    transition: transform .3s ease;
    -webkit-transition: all .3s ease;
    transform: translateX(0);
    -webkit-transform: translateX(0);

    .overlay{
      display: block
    }

    .sidebar__btn {
      //border-bottom: 1px solid rgba(132,120,156,.6);
    }

  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
}

.overlay.active {
  opacity: 1;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
  pointer-events: initial;
}
//.headerContainer__logo {
//  display: none;
//}
.headerContainer__logoOut {
  position: absolute;
  top: 0;
  right: 50%;
  transform: translateX(50%);
}

.overlayAccount {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
}

.overlayAccount.active {
  opacity: 1;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
  pointer-events: initial;
}

.accountSideBar {
  background: #19131D;
  position: fixed;
  width: 100%;
  //max-width: 385px;
  max-width: 385px;
  height: 100%;
  z-index: 999999;
  transform: translateX(-110%);
  -webkit-transform: translateX(-110%);
  overflow-y: scroll;
  //transition: all .3s ease;
  //-webkit-transition: all .3s ease;

  &::-webkit-scrollbar {
    display: none;
  }

  &__close {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #D2CDDB;
    background: transparent;
    border: none;
    padding: 16px;
    margin-top: 8px;
  }

  &__icon {
    margin-right: 25px;
  }

  &.active {
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    transform: translateX(0);
    -webkit-transform: translateX(0);

  }
  .overlayAccount{
    display: block;
  }
}



@media (max-width: 1300px) {

  .headerContainer__navList {
    display: none;
  }
  .logInGifLogo {
    display: none;
  }
  .headerContainer__inner {
    position: relative;
  }

  .headerContainer__logo {
    display: none;
  }

  .gifLogoMenu {
    width: 125px;
    height: 65px;
  }

  .headerContainer__mobileLogo {
    display: block;
    position: absolute;
    top: 50%;
    /* right: 50%; */
    left: 50px;
    transform: translatey(-50%)
  }
  .headerContainer {
    padding: 0 17px 0 17px;
  }

  .sidebar {
    padding-top: 0;
    height: 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &__close {
      margin-right: 17px;
      margin-top: 12px;
    }

    &__socialBlock {
      justify-content: flex-start;
      padding-left: 20px;
    }
    &__userBlock {
      position: relative;
      background: #0E0C10;
      padding:8px  16px;
      margin-top: 8px;
    }
    &__btn {
      margin: 0;
      background: #0E0C10;
      padding: 0;
    }
    &__btnWrap {

      display: none;
    }

    &__seeProfile {
      display: block;
      align-items: center;
      font-size: 12px;
      margin: auto;
      margin-right: 32px;
      color: #EEB665;
    }

    &__btnAside {
      display: flex;
      justify-content: space-between;
      padding: 15px 0 16px 0 ;
      text-align: center;
      margin: 0 16px;
      padding-top: 0;

      & button {
        flex: 1;
      }
    }
    &__user {
      width: 72px;
      height: 72px;
      background: #27222D;
    }
    &__userData {
      justify-content: center;
      margin-left: 8px;
      //flex: 0;
    }
    &__userFullName {
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: white;
      margin-bottom: 10px;

    }
    &__userName {
      font-weight: 700;
      font-size: 16px;
    }
    &__balanceBlock {
      justify-content: flex-start;
      align-items: center;
    }
    &__balance {
      margin-right: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      color: #8F84A4;
    }
    &__balanceItem {
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;
    }
    &__currence {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      color: #8F84A4;
    }

    &__logo {
      margin-bottom: 20px;

      & img{
        width: 125px;
        height: 65px;
      }

    }
    &__btnLog {
      background: inherit;
    }

    &__userBtn {
      display: inline-block;
      background: transparent;
      border: none;
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

@media(max-width: 1300px) {
  .overlayAccount {
    display: block;
  }
  .sidebar__socialLink:hover {
    border: 1px solid #84789C;
    color: currentColor;
    & svg{
      color: #84789C;
    }
  }

  .headerContainer {
    &__buttons {
      .questButton {
        visibility: hidden;
      }
    }
  }
}

@media(max-width:767px) {
  .headerContainer__inner {
    min-height: 64px;
  }

  .headerContainer {
    min-height: 70px;
  }

  .headerContainer__logoOut {
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    right: auto;

    img {
      width: 78px;
      height: 40px;
    }
  }
}

@media (max-width: 550px) {
  .sidebar {
    width: 100%;
  }
  .headerContainer {
    min-height: 0px;
  }
  .headerContainer {
    padding: 0 10px 0 10px;
  }
}

@media (max-width: 450px) {

}
