@import 'variables';
@import "mixins";


.demo_header {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 80px;
  font-size: 36px;
  color: #fff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  margin-bottom: 20px;
  position: relative;


  img {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

}

.gamePageHeading {
  width: 1290px;
  height: fit-content;
  border-top: 1px solid #35303E;
  margin: 0 auto;

  &.for_demo {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    transform: translateY(30px);
    h1 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 32px;

      color: #D2CDDB;
    }

    span {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #8F84A4;
    }
  }

  .general_inf {
    color: $dark_text3;
    //gap: 50px;
    //display: flex;
    margin-top: 50px;

    .img {
      position: relative;
      float: left;
      max-width: 200px;
      margin-right: 14px;
      margin-left: 8px;
    }

    .headings {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 8px 0;
      align-items: start !important;
      justify-content: start;
      text-align: start;

      h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 38px;
        line-height: 100%;
        margin-bottom: 0;
      }


      .vendor_link {
        width: fit-content;
        padding: 8px 12px;
        border-radius: 8px;
        //height: 60px;
        background: $dark2;
        border: 1px solid $dark2;
        transition: 0.2s all;
        -webkit-transition: 0.2s all;
        font-weight: 700;
        font-size: 18px;
        line-height: 110%;
        color: #fff;


        &.clickable {

          &:hover {
            border: 1px solid $primary-color;
          }
        }

        img {
          width: 100%;
          height: auto;
        }

        @media (max-width: 745px) {
          font-size: 14px;
          padding: 4px 8px;
        }
      }
    }
    
    @media (max-width: 745px) {
      flex-direction: column;
      padding: 0 16px;
      align-items: center;
      text-align: center;
      gap: 18px;

      .img {
        width: 100px;
        margin-right: 8px;
        margin-left: 8px;
      }

      .headings {
        gap: 8px;
        align-items: center;

        h1 {
          font-size: 18px;
        }
      }
    }
  }

  .descr {
    font-weight: 500;
    font-size: 16px;
    font-family: Montserrat;
    color: $dark_text3;
    line-height: 20px;
    padding: 0 16px;
    text-align: start;
    margin-top: 14px;
  }

  @media (max-width: 745px) {
    .descr{
      padding: 0 8px;

      p {

        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  .table_container {
    padding: 0 16px 100px;

    table {
      border-collapse: collapse;
      width: 100%;
      margin-top: 30px;
      
    }

    th, td {
      padding: 14px 10px;
      text-align: start;
      font-weight: 500;
      font-size: 18px;
      font-family: Montserrat;
      color: $dark_text3;
      line-height: 20px;

      &.val {
        color: #fff;
      }
      
      @media (max-width: 700px) {
        font-size: 14px;
      }

      @media (max-width: 400px) {
        font-size: 12px;
      }
    }


    tr:nth-child(even) {
      background-color: rgb(23, 20, 27)
    }


    p {
      margin: 0 0 12px 0;
    }

    li p {
      margin: 0;
    }

  }
}

.casinoCard {
  width: 228px;
}

.provider_block {
  display: flex;
  justify-content: center;
  padding-left: 8px;
}

.filterPreloader {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 30px;
  height: 56px;
  padding: 0 20px;
  align-items: center;
  opacity: 0;
}

.card {
  position: relative;
  transform: scale(1);
  -webkit-transform: scale(1);
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  text-align: center;


  &__home_slider_item {
    width: 245px;
    max-width: 100%;
  }

  &__min_right {
    margin-right: 6px;
  }

  &__regular_right {
    margin-right: 14px;
  }

  &:hover img{
    filter: brightness(0.5);
      transform: scale(1.1);
      -webkit-transform: scale(1.1);

  }


  & img {
    display: block;
    width: 100%;
    transition: transform 0.5s;
    -webkit-transition: transform 0.5s;
    max-width: 100%;
  }



  &__rtp {
    background-color: $secondary-background-color;
    display: inline-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    line-height: 1;
    padding: 6px 10px;
    border-radius: 40px;
    font-size: 17px;
    font-weight: 500;
    position: absolute;
    max-width: 107px;
    width: 100%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);

    > img {
      width: 12px;
      height: auto;
      display: block;
      margin-right: 8px;
      filter: none !important;
      -webkit-filter: none !important;
    }

    &__hot {
      border: 1px solid red;
    }

    &__cold {
      border: 1px solid blue;

      > img {
        width: 16px;
      }
    }
  }

  &__about {
    top: -5px;
    position: absolute;
    background: rgba(0,0,0, 0.5);
    left: -5px;
    right: -5px;
    bottom:-5px;
    display: none;
    padding: 15px;
    //transition: transform 0.5s;
    //-webkit-transition: transform 0.5s;
  }

  &:hover{
    display: block;
    //transition: 0.5s;
    //-webkit-transition: 0.5s;
    transform: scale(1.0,1);
  }

  &__playIcon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 auto;
    text-align: center;
    background: $secondary-color;
  }

  &__btn {
    background: transparent;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #EEB665;
    margin-top: 10px;
    color: white;
  }

  &__title {
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
    text-overflow: ellipsis;
    color: #fff;
    pointer-events: none;
    user-select: none;
    margin: 0 0 16px;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
  }
  &__subtitle {
    color: $primary-color;
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
    margin-bottom: 18px;
  }

  &__play {
    background-color: $secondary-color;
    color: #fff;
    width: 70px;
    height: 70px;
    outline: none;
    border: 0;
    border-radius: 50%;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    margin: 0 auto 16px;


    &:hover {
      background-color: $primary-color;
      color: #000;
    }
  }

  &__play_demo {
    outline: none;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    border-radius: 50px;
    line-height: 1;
    letter-spacing: 1px;
    padding: 7px 10px;
    background-color: transparent;
    border: 1px solid $primary-color;
    color: #fff;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;

    &:hover {
      background-color: $primary-color;
      color: #000;
    }
  }

  &__inner {
    position: absolute;
    //overflow: hidden;
    //top: 50%;
    left: 50%;
    bottom: 25px;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    max-width: 85%;
    width: 100%;
    opacity: 0;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;


    //&.hideDetails {
    //  display: none;
    //}
  }

  &__providerImage {
    max-height: 48px !important;
    max-width: 100% !important;
    width: auto !important;
    display: block;
    margin: 0 auto 16px;
    filter: none !important;
    -webkit-filter: none !important;
    transform: none !important;
    -webkit-transform: none !important;
  }

  &__favorite {
    position: absolute;
    z-index: 20;
    top: 10px;
    right: 10px;
    background-color: transparent;
    color: #fff;
    border: 0;
    outline: none;
    opacity: 0;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;

    &__loading {
      cursor: wait;
    }
  }

  &__minimize {
    width: 190px;
    max-width: 100%;

    & .card__subtitle,
    & .card__title,
    & .card__providerImage {
      margin-bottom: 8px;
    }
  }

  &:hover {
    .card__inner {
      opacity: 1;
    }

    .card__favorite {
      opacity: 1;
    }
  }
}

.hideDetails {
    display: none;
  //opacity: 0;
  }
.hideDetailsInner {
  //margin-bottom: 45px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  width: 100%;


  //justify-content: center;

  //UNDO start
  justify-content: start;
  //UNDO end

  &__scroll {
    height: inherit;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    width: 14.2857142857%;
    flex: 0 0 14.2857142857%;

    //margin-bottom: 10px;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 30px;

  h1 {
    margin: 0;
  }

  .filtersBtn {
    border: none;
    background: none;
    color: $primary-color;
    display: none;
  }

  .overlayBlock {
    display: none;
  }

  .overlayFilters {
    display: none;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    position: relative;

    .providersContainer {
      position: absolute;
      z-index: 1;
      width: 373px;
      height: 686px;
      top: 67px;
      transform: translateX(-25%);
      background: #1B1025;
      box-shadow: 0px 20px 80px rgba(238, 182, 101, 0.1);
      border-radius: 12px;
      padding: 25px;
      overflow-y: scroll;
      transition: 0.3s all;
      -webkit-transition: 0.3s all;
      opacity: 0;


      .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 10px;


        .item {
          background-color: #0E0C10;
          border: 1px solid #0E0C10;
          max-width: 110px;
          width: 100%;

          height: 48px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          -webkit-align-items: center;
          cursor: pointer;
          transition: all .3s ease;
          -webkit-transition: all .3s ease;

          border-radius: 8px;
          padding: 5px;

          img {
            display: block;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            transition: all .3s ease;
            -webkit-transition: all .3s ease;
          }

          &:hover {
            border-color: $dark_text;
          }

          &.active {
            border-color: $primary-color;
          }


        }
      }
    }

    .resetButton {
      max-height: 40px !important;
      background: $danger !important;

      &:hover {
        background: $danger_hover !important;
      }
    }
  }


}

.page_title {
  font-size: 30px;
  color: #fff;
  letter-spacing: .24px;
  font-weight: 700;
  margin-bottom: 30px;
  margin-top: 0;
  text-align: center;
  display: flex;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  justify-content: center;
  //line-height: 1;


  &__count {
    letter-spacing: .24px;
    text-transform: capitalize;
    font-size: 14px;
    color: #eeb665;
    font-weight: 500;
    padding-left: 10px;
  }
}


.window {
  display: inline-flex;
  //width: 100%;
  //max-width: 1306px;
  margin: 0 auto;
  background-color: $background-color;
  position: relative;

  &__game {
    flex: 1;
    position: relative;

    &__loader {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      display: flex;
      position: absolute;
      background-color: $background-color;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
    }

    iframe {
      height: 755px;
      width: 100%;
      display: block;
      position: relative;
      z-index: 10;
    }
  }

  &__bar {
    flex: 0 0 56px;
    width: 56px;
    max-width: 56px;
    overflow: hidden;
    position: absolute;
    right: -56px;
    top: 0;
    bottom: 0;

    &__inner {
      background-color: #19131D;
      border-radius: 8px;
      min-height: 50px;
      display: flex;
      width: 100%;
      flex-direction: column;
      -webkit-flex-direction: column;
      justify-content: center;
      align-items: center;
      -webkit-align-items: center;
      padding: 16px 0;
    }
  }

  &__textualBtn {
    color: $primary-dark;
    background-color: transparent;
    border: 0;
    padding: 5px 10px;
    font-size: 14px;
    outline: none;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
  }

  &__btn {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    padding: 0;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    color: #fff;
    margin: 0 auto;

    svg {
      max-width: 24px;
      max-height: 24px;
    }

    &__text {
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      color: #8F84A4;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
      padding: 0 5px;
      margin-bottom: 20px;
      display: none;
    }

    &:hover {
      background-color: #27222D;
    }
  }

  &__fullScreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    max-width: 100% !important;
    margin: 0 !important;

    .window__game {
      height: calc(100vh - (124px + 20px));

      iframe {
        height: 100%;
        width: 100%;
      }
    }

    .window__bar {
      position: unset;
    }

    .window__bar__inner {
      background-color: transparent;
    }
  }

  &__playIcon {
    border: 1px solid $primary-dark;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: inline-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;

    svg {
      max-width: 19px !important;
    }
  }

  &__depositIcon {
    border: 1px solid $primary-dark;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: inline-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);

    svg {
      max-width: 48% !important;
    }
  }

  &__textualBtn {
    .window__depositIcon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
    //transition: all .3s ease;
    //-webkit-transition: all .3s ease;

}
.overlay.active {
  opacity: 1;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
  pointer-events: initial;


}


.popup {
  display: flex;
  flex-direction: column;
  background: #1F1B24;
  padding:0 0 8px 0;
  border-radius: 8px 8px 0px 0px;
  //transition: all .3s ease;
  //-webkit-transition: all .3s ease;
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
  position: fixed;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  margin: 0 auto;
  max-width: 445px;
  max-height: 450px;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }


  &.active {
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    transform: translateY(0);
    -webkit-transform: translateY(0);

    .overlay{
      display: block;
    }

   body {
      overflow: hidden;
    }
  }

  &__fullWrapper {
    //padding: 0px 0px 40px 0px;
    max-height: 635px;
    //max-height: 800px;
    height: 100%;
    overflow-y: scroll;
    //background-color: #ff36a1;

    &::-webkit-scrollbar {
      display: none;
    }

  }

  &__fullCategories {
    padding: 16px 16px 5px 16px;
    border-top: 1px solid #27222D;
    border-bottom: 1px solid #27222D;
  }

  &__btnFavorite {
    padding: 0 16px 8px 16px;
    border-top: 1px solid #27222D;
    //border-bottom: 1px solid grey;
  }
  &__recommendedGame__BlockTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #BBBBBB;
    padding-left: 24px;
    margin-top: 8px;
  }

  &__closeicon {
    margin-left: auto;
    padding: 10px 16px;

    position: sticky;
    top: 0;
    z-index: 9999;
    background: #1F1B24;
    width: 100%;
    text-align: right;
    //padding-top: 10px;

    & svg {
      color: #8F84A4;
    }
  }
  &__closeLine {
    width: 50px;
    height: 2px;
    background: #8F84A4;
    position: absolute;
    top: 50%;
    transform: translateX(50%);
    right: 50%;
  }
  &__wrapper {
    display: flex;
    padding: 0 16px;
    margin-top: 10px;
  }
  &__gameName {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    flex-direction: column;
    gap: 10px;

    .card__subtitle {
      font-size: 14px;
    }
  }

  &__btnSeeMore {
    display: block;
    //margin: auto;
    width: 100%;
    margin-bottom: 25px;
  }

  &__fullBlock {
    display: flex;
    padding: 0 16px;
    margin-top: 15px;
    margin-bottom: 15px;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;

    .card__subtitle {
      font-size: 14px;
    }
  }

  &__fullBlockWrapper {
    justify-content: center;
  }
  &__gameNameBlock {
    flex: 1;
    margin-left: 16px;
  }
  &__providerImage {
    max-width: 70px;
    margin: auto;
    margin-bottom: 10px;
  }
  &__fullGameName {
    //flex:1;
    text-align: end;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__fullicon {
    flex: 0;
  }


  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  &__imageWrapper {
    max-width: 195px;
    //width: 100%;
  }
  &__demoBtn {
    display: flex;
    align-items: center;
    font-size: 14px;
    background: transparent;
    margin-top: 24px;
    margin-bottom: 60px;
    border: none;
    color:  #EEB665;
    margin: auto;
  }
  &__iconFullBlock {
    margin-right: 10px;
  }
  &__wrapperPlatinum {
    display: flex;
    justify-content: space-between;
    background: #27222D;
    padding: 11px 9px;
    border-radius: 8px;
    margin-bottom: 5px;
  }

  &__currency {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #EEB665;
  }
  &__prize {
     margin-right: 5px;
  }

  &__demoBtnArrow {
    width: 10px;
    height: 16px;
    margin-left: 5px;
  }
  &__seeMoreBtn {
    position: relative;
    background: transparent;
    border: none;
    color:  #EEB665;

  }

  &__recommendedGame__block {
    display: flex;
  }

  &__recommendedGame {
    max-width: 100px;
    //height: 100%;


  }

  &__seeMore {
    position: absolute;
    width: auto!important;
    top: 50%;
    transform: translateY(-50%);
    right: -15px;
  }
  &__seeMoreWrapper {
    border-top: 1px solid #27222D;
    padding: 20px;
    text-align: center;
  }

}


@media(max-width: 1620px) {

  .header {
    .filters {
      display: none;
    }

    .overlayFilters {
      padding: 16px;
      width: 360px;
      height: 100%;
      z-index: 10000;
      position: fixed;
      display: block;
      top: 0;
      right: 0;
      transform: translateX(100%);
      background: #1B1025;
      box-shadow: 0px 20px 80px rgba(238, 182, 101, 0.1);
      transition: 0.3s all;
      -webkit-transition: 0.3s all;

      &.overlayFiltersActive {
        transition: 0.3s all;
        -webkit-transition: 0.3s all;
        transform: translateX(0);
      }

      .outlinedFilter {
        margin-top: 0 !important;
      }

      .scrollFilters {

        .divider {
          background: #27222D;
          width: 100%;
          height: 1px;
        }

        .providersFilterGrid {
          width: 100%;
          overflow-y: scroll;
          z-index: 1;
          top: 50px;
          left: -50%;
          background-color: #1B1025;
          transform: translateY(10px);
          -webkit-transform: translateY(10px);
          opacity: 1;
          transition: all .3s ease;
          -webkit-transition: all .3s ease;
          margin-bottom: 30px;

          &::-webkit-scrollbar {
            display: none;
          }

          .grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 10px;
            grid-row-gap: 10px;

            &.checkBoxesGrid {
              grid-template-columns: repeat(2, 1fr);
              width: 100%;
              grid-column-gap: 3px;
              //border: 1px solid #fff;
              padding-bottom: 5px;
              overflow: hidden;

              .checkItem {
                display: flex;
                flex-direction: row;
                align-items: start;
                width: fit-content;
                //border: 1px solid #fff;
                &.long {
                  grid-column: span 2;
                }

                .checkItem__label {
                  transform: translateY(8px);
                  font-family: 'Montserrat';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 24px;
                }
              }
            }

            .item {
              background-color: #0E0C10;
              border: 1px solid #0E0C10;
              max-width: 110px;
              width: 100%;
              height: 48px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              -webkit-align-items: center;
              cursor: pointer;
              transition: all .3s ease;
              -webkit-transition: all .3s ease;
              border-radius: 8px;
              padding: 5px;

              img {
                display: block;
                max-width: 100%;
                max-height: 100%;
                width: auto;
                height: auto;
                transition: all .3s ease;
                -webkit-transition: all .3s ease;
              }

              &:hover {
                border-color: $dark_text;
              }

              &.active {
                border-color: $primary-color;
              }


            }
          }
        }
      }

      &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        color: #fff;

        h4 {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 24px;

        }

        .closeButton {
          border: none;
          background: none;
          width: 14px;
          height: 14px;

          svg {
            width: 14px;
            height: 14px;
            color: #fff !important;
          }
        }
      }

      .resetButton {
        width: 100%;
        background: $danger !important;
        margin-top: 5px;
        font-size: 14px !important;
      }

      .outlinedSelector_root__dh_nz  {
        margin-top: 0 !important;
      }


    }
  }

  .filtersBtn {
    border: none;
    background: none;
    color: $primary-color;
    display: none;

    &.pageCategs {
      border: none;
      background: none;
      color: $primary-color;
      display: block;
      z-index: 100;
    }
  }
}


@media(max-width: 1290px) {
  .gamePageHeading {
    width: 100vw;
    .container {
      h1 {
        font-size: 32px;
        line-height: 32px;
      }

      span {
        font-size: 16px;
        line-height: 16px;

      }
    }

  }
}



@media (max-width: 1620px) {
  .filterPreloader {
    margin-bottom: 10px;
  }

  .providersFilterGrid {
    width: 100%;
    background: #1B1025;
    border-radius: 12px;
    overflow-y: scroll;

    .grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 10px;
      grid-row-gap: 10px;


      &.checkBoxesGrid {
        grid-row-gap: 0 !important;

        .checkItem {
          //border: 1px solid #fff;
          display: flex;
          flex-direction: row;
          align-items: start;
          width: fit-content;
          //border: 1px solid #fff;
          &.long {
            grid-column: span 2;
          }

          .checkItem__label {
            transform: translateY(8px);
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
          }
        }
      }

      .item {
        background-color: #0E0C10;
        border: 1px solid #0E0C10;
        max-width: 110px;
        width: 100%;

        height: 48px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        -webkit-align-items: center;
        cursor: pointer;
        transition: all .3s ease;
        -webkit-transition: all .3s ease;

        border-radius: 8px;
        padding: 5px;

        img {
          display: block;
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
          transition: all .3s ease;
          -webkit-transition: all .3s ease;
        }

        &:hover {
          border-color: $dark_text;
        }

        &.active {
          border-color: $primary-color;
        }


      }
    }

  }

  .card {
    .card__favorite {
      display: none;

    }
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    position: unset;


    .filtersBtn {
      border: none;
      background: none;
      color: $primary-color;
      display: block;
      z-index: 100;
    }

    .overlayBlock {
      display: flex;
      flex-direction: column;
      align-items: end;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.4);
      border: 1px solid #fff;
      z-index: 999;
      opacity: 0;
      transition: 0.3s all;
      -webkit-transition: 0.3s all;
      pointer-events: none;

      &.overlayBlockActive {
        transition: 0.3s all;
        -webkit-transition: 0.3s all;
        opacity: 1;
        pointer-events: initial;
      }
    }



    .filters {
      display: none;
    }
  }

}

@media (min-width: 1200px) {
  .window {
    max-width: calc(100% - 30px);
    width: 100%;
  }

  .window__bar {
    right: 0;
  }

  .window__mobileBtn {
    display: none!important;
  }

  .window__btn__mobileIcon {
    display: none;
  }

  .window__game {
    height: calc(100vh - (144px));
    width: calc(100% - 56px) !important;
    max-width: 100%;
    flex: 0 0 auto;
    margin: 0;
    position: relative;

    &.demo {
      height: calc(100vh - 244px);
    }

    iframe {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
  }

  .window__fullScreen .window__game {
    width: calc(100% - 56px);
    height: 100%;
    aspect-ratio: unset;
  }

  .window__widthAspectRation {
    width: 100%;
  }

  .window__widthAspectRation .window__game {
    min-height: 0;
    height: auto;
    width: 100%;
    max-width: calc(100% + 200px);
  }

}

@media (max-width: 1800px) {
  .grid__slots .grid__item {
    width: 20% !important;
    flex: 0 0 20% !important;
  }
}

@media (max-width: 1500px) {
  .grid__slots .grid__item {
    width: 25% !important;
    flex: 0 0 25% !important;
  }
}

@media (max-width: 1365px) {
  .grid__slots .grid__item {
    width: 33.3333333333% !important;
    flex: 0 0 33.3333333333% !important;
  }
}

@media(max-width: 1199px) {
  .card__inner {
    //opacity: 1;
    display: none !important;
    height: 100px;
    //display: flex;
    //flex-direction: column;
    //align-items: center;
    //justify-content: space-between;
  }
  .card__play {
    width: 45px;
    height: auto;
    margin-bottom: 5px;
    & svg {
      display: inline-block;
      max-width: 15px;
      //height: auto;
    }
  }
  .card__title {
    font-size: 13px;
    margin: 0 0 5px;
  }
 .grid__item {
   width: 20%;
   flex: 0 0 20%;
 }
  .card__subtitle {
    font-size: 10px;
    margin-bottom: 0!important;
  }
  .card__providerImage {
    max-height: 32px !important;
    margin-bottom: 0!important;
  }
  .card__play_demo {
    font-size: 11px;
    padding: 5px 16px;
  }
  .search_bar__reset {
    background: #9D0000;
  }
  .card:hover{
    .card__favorite{
      opacity: 0;
    }
  }


}
@media(max-width: 1365px) {
  .grid__item {
      width: 20%;
      flex: 0 0 20%;
  }
}

@media(max-width: 1199px) {
  .grid__item {
    width: 25%;
    flex: 0 0 25%;
  }

  //.slots__sort {
  //  max-width: 275px;
  //}
}

@media(max-width: 991px) {
  .casinoCard {
    width: 100px;
  }
  .card__home_slider_item {
    width: 144px;
    max-width: 100%;
  }
  .card__minimize {
    width: 100px;
  }
  .card__regular_right {
    margin-right: 8px;
  }

  .grid__item {
    width: 25%;
    flex: 0 0 25%;
  }
}

@media(max-width: 850px) {
  //.grid__item {
  //  width: 25%;
  //  flex: 0 0 25%;
  //}
}

@media (max-width: 767px) {

  .gamePageHeading {
    width: 100vw;

    .container {
      transform: none;
      margin-top: 5px;
      h1 {
        font-size: 20px;
        line-height: 20px;
      }

      span {
        font-size: 12px;
        line-height: 12px;
      }
    }

  }

  .card {
    //width: 50%;
  }

  .window {
    margin-bottom: 30px;
  }
  .card__rtp {
    max-width: 100px;
    font-size: 16px;
    padding: 6px 9px;
  }
}

@media(max-width: 700px) {
  .grid__item {
    width: 33.33333%;
    flex: 0 0 33.33333%;
  }

}
@media(max-width: 575px) {

  .gamePageHeading {
    width: 100vw;
    .container {
      margin: 5px auto 0;
      width: 320px;

    }
  }

  //.grid__item {
  //  width: 50%;
  //  flex: 0 0 50%;
  //}

  .filterPreloader {
    margin-bottom: 3px;
  }
  .card__title {
    margin-bottom: 5px!important;
  }
  .popup__imageWrapper {
    max-width: 112px;
  }
  .card__home_slider_item {
    //width: 33.33333%;
    //flex: 0 0 33.33333%;
  }
  .popup {
    max-height: 325px;
  }
  .popup__fullWrapper {
    max-height: 90%
  }

  .page_title {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .grid__item {

  }


}

@media(max-width: 360px) {
  .filterPreloader {
    margin-bottom: 0;
  }

  .overlayFilters {
    width: 100vw !important;
  }

  .header {
    h1 {
      font-size: 20px;
    }
  }
}

.isMobile {
  position: fixed;
  z-index: 150;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 !important;
  width: 100%;
  max-width: 100%;

  &.isMobileDemo {
    width: 100vw;
    height: calc(100vh - 50px);
    position: unset;
    scrollbar-width: none;


    &::-webkit-scrollbar {
      display: none;
    }
  }

  .window__game {
    max-width: 100% !important;
    width: 100% !important;
    height: 100% !important;
  }

  .window__game iframe {
    height: 100%;
    position: relative;
  }

  .window__bar__inner {
    background-color: transparent;
    padding: 0;
    height: 100%;
    border-radius: 0;
  }

  .window__btn {
    color: $primary-dark;
  }

  .window__btn__text {
    display: none;
  }

  .window__bar {
    background-color: $background-color;
  }

  .window__screenSizeBtn {
    display: none;
  }

  .window__btn__desktopIcon {
    display: none;
  }

  .window__mobileBtn {
    display: flex !important;
  }

  .window__btn__mobileIcon {
    display: flex !important;
  }

  &.portrait {
    padding-top: 48px;
    justify-content: flex-start;

    .window__go_real_btn {
      display: none;
    }

    .window__bar {
      right: 0;
      left: 0;
      bottom: auto;
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
      height: 48px;

      &__inner {
        flex-direction: row;
        -webkit-flex-direction: row;
        width: 100%;
        height: 100%;
        background-color: #1A1A1A;
        justify-content: flex-start;
      }
    }

    .window__btn {
      height: 30px;
    }

    .window__btn__text {
      margin-bottom: 0;
      max-width: 60px;
    }

    .window__mobileBtn {
      margin-left: auto;
    }
  }

  &.landscape {
    padding-right: 48px;

    .window__go_real_text {
      display: none;
    }

    .window__bar__inner {
      padding: 2px 0 0;
    }

    .window__bar {
      right: 0;
      width: 48px;
    }

    .window__mobileBtn {
      margin-top: auto;
    }
  }
}