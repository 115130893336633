@import "mixins";
@import "variables";

.realityCheck {
  .reality_check_control {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    gap: 12px;

    button {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.returnToLimits {
  .limitsBtnWrapper {
    margin-top: 20px;

    & > button {
      margin: 8px;
    }
  }
}

.reset_pwd_modal {
  form {
    margin-top: 30px;

    h2 {
      font-size: 24px;
      color: #fff;
      margin-bottom: 24px;
    }

    .input {
      margin: 0;
    }

    .errortext {
      color: $error;
      text-align: start;
      padding-left: 25px;
    }

    button[type="submit"] {
      margin-top: 50px;
    }
  }

  .pwd_rules {
    margin-top: 30px;
    margin-left: 0;
    left: unset !important;
    top: unset !important;
    position: unset !important;

  }
}



@media (max-width: 575px) {
  .realityCheck {
    .reality-check-control {
      flex-direction: column;
      -webkit-flex-direction: column;

      button:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}
