@import 'variables';
@import "mixins";

.footer {
  border-top: 1px solid #35303E;
  //margin: 0 315px 135px 315px;
  margin: 0 auto;
  width: 100%;
  padding: 10px 300px 0;

  &.mobileSportFooter {
    margin-bottom: 40px;
    position: unset;
    display: none;
  }

  &.sportFooter {
    position: fixed ;
    z-index: 10 ;
    bottom: 0 ;
    background: $background-color ;
    transform: translateY(100%);
    //border-top: 1px solid $primary-color;
    max-width: $maxWidth ;
    margin-bottom: 0 !important;
    &.open {
      transform: translateY(0);
    }
  }

  .toogleFooter {
    background: $primary-color;
    color: #000;
    font-size: 14px !important;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    text-transform: capitalize;
    padding: 8px 16px;
    position: absolute;
    top: -35px;
    left: 65%;
    border: none;
  }
}


.menu_heading {
  color: var(--white, #FFF);
  /* 18 */
  font-size: 18px;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 20px;
}

.menuContainer {
  display: flex;
  gap: 75px;

  .menu {
    flex-direction: column;
    list-style: none;
    padding: 0;
    gap: 16px;
    font-weight: 500;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: $dark_text3;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      &:hover {
        color: $primary-color;
      }
    }
    
    @media (max-width: 800px) {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  }

}


.contents {
  margin-top: 34px;

  .sportFooter {
    position: fixed;
    bottom: 0;
    background-color: #000;
    z-index: 10;
  }

  &__logo {
    width: 150px;
    aspect-ratio: 260/69;
    display: block;
  }

  &__bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    .socialMedia {
      display: flex;
      flex-direction: row;

      .socialLink {
        background: transparent;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #84789C;
        border-radius: 50px;
        width: 40px;
        height: 40px;

        &:hover {
          border: 1px solid $primary-color;
          svg {
            color: $primary-color;
          }
        }
      }
    }


  }

  &__top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #35303E ;
    padding-bottom: 30px;
  }

  &__sides {

    &.right {
      width: 493px;
      //border: 1px solid #fff;
    }
  }


  &__about {
    p {
      font-weight: 500;
      font-size: 12px !important;
      font-family: Montserrat;
      color: $dark_text3;
      line-height: 20px;
      //padding-right: 15px;
      //width: 80%;
    }
  }

  &__img {
    display: flex;
    gap: 10px;
    position: relative;

    .onjn_url {
      display: block;
      height: 100%;
      width: 70px;
      position: absolute;
      top: 0;
      right: 50%;


    }
  }
}

@media(max-width: 1650px) {
  .footer {
    margin: 20px 0;
    padding: 10px 150px 0;
    max-width: 100%;
  }
}
//
//@media(max-width:1365px) {
//  .footer {
//    margin: 20px 100px;
//    padding: 10px 0 0;
//  }
//}

@media (max-width: 1350px) {
  .contents {
    &__top {
      flex-direction: column;
      gap: 30px;
    }

    &__sides {
      width: 100% !important;
    }
  }
}

@media (max-width: 1200px) {

  .footer {
    margin: 20px 0 0;
    padding: 10px 100px 0;

  }


  .contents {
    flex-direction: column;
    margin-top: 0;
  }


  .menu {
    text-align: left;
    padding-top: 30px;
    padding-left: 25px;


  }
}


@media (max-width: 767px) {
  .menu {
    padding: 20px 16px 0;
  }

  .menuContainer {
    flex-direction: column;
    gap: 0;
  }
}

@media (max-width: 576px){

  .footer {
    padding: 10px 50px 0;

  }

  .menu {
    padding-left: 50px;
    padding-right: 0;

  }
}
@media (max-width: 480px){
  .footer {
    width: 100%;
    margin: 0;
    padding: 20px;
  }

  .menu {
    padding-left: 20px;
  }
}

@media (max-width: 330px) {
  .contents {
    &__logo {
      width: 160px;
      height: 43px;
      aspect-ratio: 260/60 !important;
    }
  }
}