@import 'variables';
@import "mixins";

.swiper {
  position: relative;

  & > div {
    justify-content: center;
  }
}

.slider {
  position: relative;
  z-index: 50;
  margin-bottom: 105px;

  &__inner {
    position: absolute;
    left: 0;
    right: 0;
    top: -65px;
    z-index: 10;
  }

  &__innerRelative {
    position: relative;
    margin-top: 90px;
  }

  &__sliderRelative {
    margin-bottom: 0;
  }

  .swiper-wrapper {
    padding-top: 0 !important;
  }

  &__swiper_item {
    width: auto !important;
    padding: 0 5px;

    img {
      max-width: 30px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    min-height: 56px;
    text-decoration: none;
    color: #fff;
    padding: 12px;
    //background: #27222D;
    border-radius: 10px;

    img {
      width: 30px;
      height: auto;
      display: block;
      margin-right: 10px;
    }

    &__title {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      width: 100px;
    }

    &:hover {
      color: $primary-color;
    }
  }
}

@media (max-width: 1750px) {
  .slider__alignLeft .swiper > div {
    justify-content: flex-start;
  }
}

@media (max-width: 1200px) {
  .swiper {
    & > div {
      justify-content: start;
    }
  }
  .slider {
    &__inner {
      padding-left: 25px;
    }
  }
}
@media (max-width: 757px) {
  .slider__sliderRelative {
    margin-bottom: -25px;
  }
}

@media (max-width: 575px) {
  .slider {
    margin-bottom: 80px;
  }

  .slider__sliderRelative {
    margin-bottom: -25px;
  }

  .slider__item__title {
    width: auto;
  }

  .slider__item {
    max-height: 48px;
    min-height: 48px;
  }
}
