@import 'variables';
@import "mixins";


.main {
  max-width: 1200px;
  margin: 100px auto 70px;

  &__heading {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }

  &__content {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    margin-top: 24px;

    p {
      //border: 1px solid #fff;
      margin: 0;
    }
  }

}

@media (max-width: 1500px) {
  .main {
    width: 100%;
    padding: 0 150px;
    margin: 100px 0 70px;
    max-width: unset;
  }
}

@media (max-width: 1200px) {
  .main {
    width: 100%;
    padding: 0 100px;
    margin: 100px 0 70px;
    max-width: unset;
  }
}

@media (max-width: 990px) {
  .main {
    &__heading {
      font-size: 24px;
      line-height: 30px;
    }

    &__content {
      font-size: 12px;
      margin-top: 10px;

      p {
        //border: 1px solid #fff;
        margin: 0;
      }
    }
  }
}

@media (max-width: 770px) {
  .main {
    &__heading {
      font-size: 18px;
      line-height: 22px;
    }

    &__content {
      font-size: 12px;
      margin-top: 10px;

      p {
        //border: 1px solid #fff;
        margin: 0;
      }
    }
  }
}

@media (max-width: 576px) {
  .main {
    padding: 0 50px;
  }
}

@media (max-width: 480px)  {
  .main {
    padding: 0 20px;
  }
}


