@import 'variables';
@import "mixins";

$sideWidth: 153px;

.pageAvailableOffers {
    //border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pageHeading {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;

    &__switch{
      width: 315px;
      height: 40px;
      background: $background-color;
      border: 1px solid $dark2;
      border-radius: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .sides {
        width: $sideWidth;
        text-align: center;
        font-size: 16px;
        font-family: Montserrat;
        font-weight: 700;
        line-height: 16px;
        text-transform: uppercase;
        color: $dark_text;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        &.active {
          color: #fff;
        }
      }

      .selector {
        border-radius: 100px;
        width: $sideWidth;
        height: 100%;
        border: 1px solid $primary-color;
        position: absolute;
        left: 0;
        -webkit-transition: 0.3s all;
        transition: 0.3s all;

        &.floated {
          -webkit-transition: 0.3s all;
          transition: 0.3s all;
          left: calc(100% - $sideWidth);
        }
      }
    }

    .buttons {
      display: flex;
      gap: 8px;
      
    }
    
    @media (max-width: 520px) {
      .buttons {
        flex-wrap: wrap;

        button {
          font-size: 11px;
          padding: 16px;
          min-height: 40px;
          height: 40px;
        }
      }
    }
  }

  .no_offers {
    color: $dark_text2;
  }

  .terms {
    flex-wrap: wrap;
    gap: 8px;
    color: $dark_text2;
    margin-top: 60px;
    justify-self: end;

    span {
      margin-left: 8px;
    }

    svg {
      transform: translateY(-4px);
    }

    button {
      padding: 0;
      border: 0;
      background: none;
      color: $primary-color;
      margin-left: 8px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .title_platform {
    position: relative;

    img {
      width: 100%;
      height: auto;
    }

    .title {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: end;
      padding: 16px;
    }
  }
}

.accountMenu {
  //margin-top: 60px;
  //border: 1px solid #604180;
  border-radius: 24px;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 50px;
  width: 325px;


  &__profileName {
    padding: 30px;
    background: #1F1B24;
    border-radius: 50px;
    width: 100px;
    height: 100px;
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__profile {
    font-weight: 700;
    text-align: center;
    padding: 0 10px 25px;
    color: #FFFFFF;


    & img {
      border: 1px solid purple;
      border-radius: 50px;
      //padding: 20px 10px;
      background: purple;
    }
  }

  &__name {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #fff;
    margin: 16px 0;
    text-transform: capitalize;
  }

  &__offers {
    padding: 10px 0 9px 48px;


  }

  &__offersText {
    &:hover {
      color: #EEB665;
      transform: translateX(0.2%);
    }
  }
  &__block {
    border-top: 1px solid #1F1B24;
    padding: 9px 0;
  }

  &__myProfile {
    color: white;
    position: relative;
    cursor: pointer;
    margin: auto;

    &:hover {
      //background: #1B1025;
    }
  }

  &__title {
    padding: 12px 0 10px 16px;
    text-transform: capitalize;
    width: 325px;
    margin: auto;

    //&:hover {
    //  color: #EEB665;
    //}
  }

  &__icon {
    color: transparent;
    position: absolute;
    left: 11px;
    margin-right: 15px;
    margin-top: 16px;
  }

  &__info {
    list-style: none;
    padding-left: 16px;
    padding-top: 8px;
    font-size: 14px;
    font-weight: 500;


    & li {
      padding: 3px 0;

      & a{
        display: inline-flex;
        width: 100%;
        padding: 5px 0;
      }
      &:hover {
        color: #EEB665;
      }
    }
  }

  &__text {
    padding-left: 10px;
    margin: auto 0;
  }
}

.passwordRules {
  position: absolute;
  color: white;
  font-size: 14px;
  margin-left: 20px;
  max-width: 100%;
  width: 360px;
  height: auto;
  background: #17141B;
  border-radius: 16px;
  padding: 16px;
  top: 20px;
  left: 100%;

  &__description {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }
}

.green {
  color: #21A67A;
}

.red {
  color: #9D0000;
}

.passwordRules__list {
  list-style: none;
  line-height: 162%;
  padding-left: 0;
  margin-top: 10px;
  margin-bottom: 0;

  &__item {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    letter-spacing: 0.1px;
    font-size: 14px;
    line-height: 24px;
  }
}

.passwordRules__listIcon {
  margin-right: 8px;
  //color:#9D0000;
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
}

.active{
  color: #EEB665;
}

.arrow {
  &:after {
    content: "";
    position: absolute;
    margin-top: -25px;
    right: 20px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #d2cddb;
  }
}

.wrapper {
  padding: 8px 0 5px 44px;
}

.activeTitle {
  background: #1F1B24;
  border-radius: 8px;
  width: 325px;
  color: white;

  //& .link {
  //  color: #EEB665;
  //
  //  color: #EEB665;
  //}

  &.arrow{
    &:after{
      transform: rotate(180deg);
      border-top: 5px solid #84789C;

    }
  }
}

.link {
  display: inherit;
  padding: 7px 0 6px 0px;
 }

//PROFILE
.profile {
  position: relative;
  z-index: 99;


  //margin-bottom: 40px;
  //margin-top: 60px;

  &__title {
    font-size: 32px;
    line-height: 32px;
    //text-transform: capitalize!important;
    //border-bottom: 1px solid #35303e;
    //padding-bottom: 5px;
    color: white;
    font-weight: 700;
    margin-bottom: 8px;
    position: relative;
  }

  &__titleCount {
    position: absolute;
    left: 168px;
    letter-spacing: .24px;
    text-transform: capitalize;
    font-size: 14px;
    color: #eeb665;
    font-weight: 500;
    padding-left: 10px;
  }

  &__wrapper {
    padding: 0px 0px 20px 0px;
  }

  &__block {
    margin: 20px 0 0 0;
  }

  &__details {
    border-bottom: 1px solid #35303e;
    padding: 5px 2px;
  }

  &__name {
    font-size: 10px;
    color: #84789c;
    text-transform: uppercase;
  }

  &__input {
    background: transparent;
    border: none;
    color: #fff!important;
    font-weight: 500;
    width: 100%;

    &[disabled] {
      opacity: 0.7!important;
    }
  }

  &__nickName {
    &:focus {
      outline: none;
    }
  }
}

.savebtn {
  display: inherit;
  //margin: 30px auto 0 ;
}

.agreement {
  padding-left: 30px;
  margin-top: 22px;
  color: #84789c;
  font-size: 11px;
}

//change Password
.changePassword {
  position: relative;
  max-width: 550px;
  margin: 50px auto 0px 265px;
}

.inputWrapper {
  position: relative;
}

.passwordWrapper {
  position: absolute;
  max-width: 360px;
  height: 164px;
  background: #17141B;
  border-radius: 16px;
  padding-top: 10px;
  top: 20px;
  right: -371px;
}

.profile__titleCount {
  left: 192px;
}

.claimable_offer {
  padding: 0 !important;
  overflow: hidden;

  .offer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 !important;

    .title_platform {
      position: relative;

      .countdown {
        top: 0;
        left: 0;
        position: absolute;
        border-radius: 0 0 16px 0;
        @include smallCountdown;
      }

      img {
        width: 100%;
        height: auto;
      }

      .countdown {
        position: absolute;
        top: 0;
      }

      .title {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        padding: 16px;
      }
    }

    .alternatives {
      width: 100%;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .alternative_item {
        display: flex;
        align-items: center;
        gap: 8px;

        img {
          width: 24px;
        }

      }
    }


  }

  .offer_bottom {
    padding: 16px;
    display: flex;
    gap: 8px;
    flex-direction: row;

    .btn_details {
      background: none;
      border: none;
      font-weight: 700;
      text-decoration: underline;
      color: $primary-color;
    }

    button {
      flex: 1;

    }
  }
}

@media (max-width: 1670px)  {
  .passwordRules {
    position: relative;
    width: 100%;
    left: auto;
    margin-left: 0;
  }
  .passwordWrapper {
    position: relative;
    top: -2px;
    right: -3px;
    padding-top: 15px;
    max-width: 100%;
  }
}

@media(max-width: 1200px) {
  .profile__titleCount {
    left: 158px;
  }
}

@media(max-width: 1199px) {
  .accountMenu {
   display: none;
  }
  .profile__title {
    margin-bottom: 0;
    font-size: 28px;
  }
  .activeTitle {
    width: 100%;
    border-radius: 0;
  }
  .accountMenu__block {
    border-top: none;
  }
  .accountMenu__info {
    padding-left: 20px;
  }

  .profile__titleCount {
    left: 158px;
  }
  .accountMenu__title {
    padding-left: 0;
  }

  .profile__block {
    &:first-child {
      margin: 0;
    }

  }
  .profile {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    max-width: 370px;
  }
  .accountMenu__info {
    & li {
      & a {
        padding: 11px 0 ;
      }
    }
   margin-bottom: 0;
  }


  .btnBlock {
    border-bottom: 1px solid #27222D;
    //padding-bottom: 16px;
    margin-bottom: 10px;
    padding: 0 16px 16px;
    display: flex;
    justify-content: space-between;
    & button {
      flex: 1;
    }
  }
}

@media(max-width: 991px){
  .changePassword {
    margin: 15px 0;
    max-width: 100%;
  }
}

@media(max-width: 767px) {
  .profile__title {
    font-size: 20px;
  }
  .profile {
    //margin-bottom: 0px!important;
  }
  .myDocsTitle {
    //margin-bottom: 15px!important;
  }
  .profile__title {
    font-size: 16px;
  }
  .titleGameBlock {
    margin-bottom: 30px!important;
  }
  .profile__title {
    font-size: 16px;
  }

  .profile__forGuest {
    margin-bottom: 0 !important;
  }
  .profile__titleCount {
    left: 95px;

  }
  .profile {
    max-width: 250px;
  }
}

@media(max-width: 575px) {
  .profile__title {
    font-size: 28px;
  }
  .profile__title {
    font-size: 16px;
    line-height: 1;
  }
  .profile__titleCount {
    font-size: 13px;
  }
  //.profile {
  //  margin-bottom: 0!important;
  //}

  .pageAvailableOffers {

    .pageHeading {
      flex-direction: column;
      align-items: start;

      &__switch{
        width: 100%;
        height: 40px;
        background: $background-color;
        border: 1px solid $dark2;
        border-radius: 100px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        position: relative;

        .sides {
          width: $sideWidth;
          text-align: center;
          font-size: 16px;
          font-family: Montserrat;
          font-weight: 700;
          line-height: 16px;
          text-transform: uppercase;
          color: $dark_text;
          cursor: pointer;

          &.active {
            color: #fff;
          }
        }

        .selector {
          border-radius: 100px;
          width: 50%;
          height: 100%;
          border: 1px solid $primary-color;
          position: absolute;
          left: 0;
          -webkit-transition: 0.3s all;
          transition: 0.3s all;

          &.floated {
            -webkit-transition: 0.3s all;
            transition: 0.3s all;
            left: 50%;
          }
        }
      }
    }
  }

  .claimable_offer {
    .offer {
      .alternatives {
        .alternative_item {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
}





















