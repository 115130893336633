@import 'variables';
@import "mixins";


.block {
  //background-color: #17141B;
  margin-bottom: -50px;
}

.border__block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(238, 182, 101, 0.05), rgba(238, 182, 101, 1));
  padding: 2px;
  clip-path: polygon(-1% 0%, 0% 100%, 1% 101%, 1% 1%, 97% 1%, 99% 97%, 0 97%, 0 100%, 108% 100%, 100% 0%);
}


.border__img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../../public/default/images/newBorder.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
}

.header__image {
  width: 100%;
  height: 760px;
  margin-top: -124px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 50px;


  .header__wrapper {
    background: linear-gradient(324deg, rgba(238, 182, 101, .1) 0%, rgba(62, 12, 88, .3) 100%),
    rgba(14, 12, 16, 0.3);
    backdrop-filter: blur(10px);
    overflow: hidden;
    border-radius: 21px;
    //border-radius: 14px;
    padding-bottom: 50px;
  }

  .header__offerBlock {
    width: 540px;
    max-width: 100%;
    position: absolute;
    top: 127px;
    right: 49px;
    transform: translateX(0);
    //overflow: hidden;
    background: linear-gradient(324deg, rgba(238, 182, 101, 0) 0%, rgba(62, 12, 88, 0) 100%),
    rgba(14, 12, 16, 0);

    &:after {
      content: '';
      background-image:  url('../../../public/default/images/newnewBorder.png');
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      pointer-events: none;
      background-size: 100% 100%;
    }



    &__title {
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      display: block;
      background: transparent;
      line-height: 16px;
      border: 1px solid rgba(238, 182, 101, 1);
      border-radius: 30px;
      padding: 12px 30px;
      margin: auto;
      text-transform: uppercase;
      font-weight: 500;
      width: 280px;
      font-family: 'Montserrat', sans-serif;
      margin-top: 50px;
    }

    &__content {
      text-align: center;
      padding: 15px 0 20px 0;
      border-bottom: 1px solid rgba(238, 182, 101, 0.5);
      margin: 0 50px;
    }

    .bonus {
      color: #9D0000;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      text-decoration: line-through;
    }

    &__contentTitle {
      color: #D2CDDB;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 12px;
    }

    &__contentRound {
      font-weight: 700;
      font-size: 56px;
      color: #FFFFFF;
      line-height: 39px;
      //padding: 12px 0;
      margin-bottom: 0;
      text-transform: uppercase;
    }

    &__contentType {
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      color: #EEB665;
      margin-bottom: 0;
      margin-top: 12px;
    }


  }
}

.fullWidthImage {
  width: 100%;
  height: 350px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px 20px 0px 0px;
}

.fullWidthImage__block {
  margin: 25px 20px  0;
  border: 1px solid #3F3749;
  border-radius: 20px;
  background: #0E0C10;
}

.fullWidthImage__content {
  background: #0E0C10;
  padding-top: 18px;

}

.header__offerBlock__button {
  width: 440px;
  max-width: 100%;
  margin: 50px auto 0;
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}

.header__offerBlock__buttonSecond {
  width: 208px;
  max-width: 100%;
  margin: 18px auto 30px auto;
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}


.cards {
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  padding: 0 20px;

  > * {
    padding-right: 10px;
    padding-left: 10px;
  }

  &__wrapper {
    margin-bottom: 25px;
  }

  &__block {
    border-radius: 20px;
    background-color: #0E0C10;
    border: 1px solid #3F3749;
    padding-bottom: 38px;
    cursor: pointer;
    overflow: hidden;
    height: 100%;
    &__imageWrapper {
      height: 223px;
    }

    & img {
      width: 100%;
      height: 100%;

    }

    &__title {
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      color: white;
      margin: 18px 30px 26px;
    }

    &__description {
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: white;
      margin-top: 20px;

      p {
        font-size: 16px;
        line-height: 16px;
        margin: 0 30px;
      }
    }

  }
}
@media(max-width: 1134px){
  .fullWidthImage__block {
    margin-top: 63px;
  }
  .cards__block__title {
    font-size: 18px;
    line-height: 22px;
  }
  .cards__block__description p{
    font-size: 14px;
    line-height: 16px;
  }
  .fullWidth__title {
    font-size: 24px;
    line-height: 30px;
  }
  .fullWidth__description p {
    font-size: 16px;
    line-height: 16px;
  }
  .header__image {


    .header__offerBlock__contentRound {
      font-size: 32px;
      line-height: 32px;
    }
    .header__offerBlock__contentType {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      margin-top: 5px;
    }
    .header__offerBlock__title {
      font-size: 12px;
      line-height: 16px;
      width: 250px;
      max-width: 100%;
      margin: 30px 40px 0;
    }
    .header__offerBlock {
      width: 328px;
    }
    .header__offerBlock__content {
      margin: 0 30px;
      padding: 15px 0 10px 0;
    }
    .header__offerBlock__button {
      width: 270px;
      margin: 30px 30px 0;
    }
    .header__wrapper {
      padding-bottom: 30px;
      border-radius: 16px;
    }

  }


}



@media (min-width: 1650px) {
  .block {
    margin-bottom: -30px;
  }
}

@media (max-width: 767px) {
  .header__image {
    height: 960px;
  }
  .header__image .header__offerBlock {
    left: 50%;
    transform: translateX(-50%);
  }
  .fullWidthImage__block {
    margin-top: 25px;
  }
  .block {
    margin-bottom: 0px;
  }

}

@media (max-width: 576px) {
  .cards__block__title {
    font-size: 24px;
    line-height: 30px;
    margin: 18px 30px 26px;
  }
  .cards__block__description p{
    font-size: 16px;
    line-height: 16px;
  }
  .cards__wrapper {
    margin-bottom: 20px;
  }
  .header__image {
    height: 765px;

    .header__offerBlock {
      width: 382px;
      top: 110px;
    }
    .header__offerBlock__content {
      margin: 0 20px;
      padding: 9px 0 2px 0;

    }
    .header__offerBlock__title {
      width: 208px;
      margin: 30px 85px 0;
      padding: 8px;
    }
    .header__offerBlock__contentRound {
      font-size: 24px;
    }
    .header__offerBlock__contentType {
      margin-top: 0px;
      margin-bottom: 5px;
    }
    .header__offerBlock__button {
      width: 342px;
      margin: 10px 20px 0;
    }
    .header__wrapper {
      padding-bottom: 20px;
    }
  }
}