@import 'variables';
@import "mixins";

.slider {
  margin-bottom: 55px;

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    color: #FFFFFF;
    margin-top: 0;
    margin-bottom: 28px;
    //min-width: 250px;
  }

  &__moreInfo {
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    color: #FFFFFF;
    margin-left: 165px;
    width: 250px;
    position: absolute;
    z-index: 10;
  }

  &__child {
    width: 100%;
    //color: $primary-color;

    .slick-dots {
      display: none;
    }
  }
}
.sliderWrapper {
  //min-height: 500px;
}


.sliderBlock {
  width: 100%;
  //height: 580px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  position: relative;
  //padding-left: 55px;

}
.mobileSlider {
  width: 100%;
  //height: 580px;
  background-repeat: no-repeat;
  background-size: cover;
  //display: flex;
  align-items: center;
  padding-left: 55px;
  display: none;
}

.imagesSliderMobile {
  .slider__moreInfo {
    bottom: 82px;
  }
}

@media (max-width: 1200px) {
  .sliderBlock {
    padding-left: 0;
    background-position: center;
    //height: 360px;
    justify-content: center;
  }
  .slider{
    &__moreInfo {
      //margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 0;
      left: 0;
      margin-bottom: 60px;
      transform: translateY(50%);
    }
    &__title {
      text-align: center;
      margin-bottom: 10px;
      min-width: 250px;
    }
  }
  .slider {
    &__child {
      //.slick-dots {
      //  display: block;
      //  //top: 0;
      //  bottom: 50px;
      //}
    }
  }
  //.slick-dots {
  //  display: block;
  //  //top: 0;
  //  bottom: 50px;
  //}

  .sliderWrapper {
    //min-height: 360px;
  }
  //.slick-dots {
  //  //position: absolute;
  //  bottom: 65px!important;
  //}
}
//@media (max-width: 991px) {
//  .mobileSlider {
//    //display: flex;
//    padding-left: 0;
//    background-position: center;
//    height: 360px;
//
//    display: flex;
//    justify-content: center;
//  }
//  .sliderBlock {
//    display: none;
//  }
//}

//@media (max-width: 1070px) {
//  .slider__moreInfo {
//    width: 455px;
//  }
//}

@media (max-width: 1070px) {
  .slider__moreInfo {
    //margin-left: -700px;
  }
}

@media (min-width: 799px) {
  .imagesSliderMobile {
    display: none;
  }
}

@media (max-width: 800px) {
  .imagesSlider:not(.imagesSliderMobile) {
    display: none;
  }
}





























