@import 'variables';
@import "mixins";

.root__block {
  display: none;
}
.root {
  position: relative;
  margin-bottom: 120px;
  & img {
    //width: 100%;
  }
  &__wrapper {
    display: none;
  }
  &__imageWrapper {
    display: none;
  }
  &__infoMob {
    background-color: $dark1;
  }

  &__info {
    background-color: $dark1;
    min-height: 72px;
    max-width: 100%;
    width: 740px;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    padding: 20px 12px 20px 24px;
    border-radius: 12px;
    position: absolute;
    bottom: -53px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    max-width: 100%;
    min-width: 400px;
    justify-content: space-between;

    &__title {
      color: #fff;
      //margin: 0 72px 0 0;
      font-weight: 700;
      font-size: 24px;
      margin-right: 10px;
      margin-bottom: 0;
    }

    &__game {
      //margin-right: 72px;
      margin-right: auto;
      margin-left: auto;
      display: flex;
      align-items: center;
      -webkit-align-items: center;

      &__provider_thumb {
        height: 48px;
        margin-right: 24px;
      }

      &__provider_name {
        color: $dark_text;
        font-weight: 500;
        font-size: 16px;
        line-height: 1;
        display: block;
      }

      &__name {
        font-weight: 700;
        font-size: 16px;
        line-height: 1;
        color: #fff;
        display: block;
        margin-bottom: 4px;
        max-width: 150px;
      }
    }
  }

  &.run_random_game {
    .root__info {
      width: 550px;
    }
  }
}
.root__infoMob {
  display: none;
}

//.desctopImg {
//  display: block;
//}

.mobileImg {
  display: none;
}

.mobileTitle {
  display: none;
}

@media (max-width: 991px) {
  .root__info {
    width: 100%;
    border-radius: 0;
    justify-content: center;

  }


  //.root__image {
  //  position: relative;
  //}
  //.root__infoMob {
  //  display: flex;
  //  justify-content: space-between;
  //  padding: 20px;
  //}
  //.root__secondBlock {
  //  display: flex;
  //}
  //.root__bgImage {
  //  display: none;
  //}
  //.root__block {
  //  overflow: hidden;
  //  display: block;
  //  margin: 0 20px;
  //}
  //
  //.root__info {
  //  display: none;
  //
  //  &__game__name {
  //    font-size: 14px;
  //  }
  //  &__game__provider_name {
  //    font-size: 12px;
  //  }
  //  &__title {
  //    font-size: 18px;
  //  }
  //  &__game__provider_thum {
  //    margin-right: 10px;
  //  }
  //}
  //.root__info__game {
  //    flex-direction: column;
  //    justify-content: space-around;
  //}
  //.root__wrapper {
  //  display: flex;
  //  height: 400px;
  //  margin-right: 10px;
  //  margin-left: 10px;
  //  overflow: hidden;
  //  position: relative;
  //}
  //
  //.root__scaleBlock {
  //  filter: blur(5px);
  //  top: 50%;
  //  left: 50%;
  //  position: absolute;
  //  background-repeat: no-repeat;
  //  background-size: cover;
  //  height: 100%;
  //  width: 100%;
  //  transform: translate(-50%, -50%) scale(2);
  //  -webkit-transform: translate(-50%, -50%) scale(2);
  //
  //  & img {
  //    width: 100%;
  //  }
  ////scale: 90;
  //}
  //
  //.root__imageWrapper {
  //  display: flex;
  //  width: 165px;
  //  height: 240px;
  //  right: 50%;
  //  position: absolute;
  //  transform: translate(50%, 50%);
  //
  //  & img {
  //    width: 100%;
  //  }
  //}

}
//@media (max-width: 767px) {
//
//  .global-styling{
//    margin-bottom: 25px;
//  }
//  .root__infoMob {
//    display: block;
//  }
//
//  .root__info {
//    padding: 0;
//  }
//
//  .root__info__game__name {
//    font-size: 14px;
//  }
//
//  .root__info__game__provider_thumb {
//    width: auto!important;
//  }
//
//  .root__info__game {
//    margin-bottom: 0;
//  }
//
//  .root__info__game__provider_name {
//    font-weight: 600;
//    font-size: 12px;
//    line-height: 14px;
//  }
//  .global-styling{
//      &__bgImage {
//        display: none;
//      }
//
//      & img {
//        &__info {
//          display: none;
//        }
//      }
//
//      &__info {
//        display: none;
//      }
//
//      &__infoMob {
//        background-color: #27222D;
//        min-height: 72px;
//        max-width: 100%;
//        width: 100%;
//        display: flex;
//        align-items: center;
//        -webkit-align-items: center;
//        justify-content: space-between;
//        //padding: 0 12px 0 24px;
//        border-radius: 12px;
//        //position: absolute;
//        bottom: -85px;
//        left: 50%;
//        padding: 20px;
//      }
//
//    }
//  }

@media (max-width: 767px) {
  .root__info {
    padding: 0;
  }
}

@media (max-width: 575px) {
  .root__info__game__provider_thumb {
    margin-right: 10px;
  }
  .desctopImg {
    display: none;
  }
  .mobileImg {
    display: block;
  }

  .mobileTitle {
    display: block;
    margin-bottom: 20px;
    font-size: 16px!important;
    line-height: 16px;
    padding-left: 10px;
  }

  .root__info__game {
    margin-right: 8px;
  }
  .root__btn {
    padding: 10px 15px!important;
  }
  .root__blockImage {
    //border: 1px solid red;
    margin: 0 15px
  }

  .root__liveImageBlock {
    width: 100%;
    height: 250px;
    margin: auto;
    background-position: center;
    background-size: cover;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  .root{
    margin-bottom: 25px;
  }

  .root__wrapImage {
    display: flex;
    align-items: center;
    width: 48px;
    height: 48px;
    background: black;
    border-radius: 50%;
    padding: 0 2px;

     & img {
       height: auto;
       width: auto;
     }
  }

  .root__image {
    position: relative;
  }
  .root__infoMob {
    display: flex;
    justify-content: space-between;
    align-items: center;
    -webkit-align-items: center;
    padding: 8px;
  }
  .root__secondBlock {
    display: flex;
    justify-content: space-around;
    //width: 100%;

  }
  .root__bgImage {
    display: none;
  }
  .root__block {
    overflow: hidden;
    display: block;
    margin: 0 16px;
  }

  .root__info {
    display: none;

    &__game__name {
      font-size: 14px;
      max-width: 100%;
    }
    &__game__provider_name {
      font-size: 12px;
    }
    &__title {
      font-size: 18px;
    }
    &__game__provider_thum {
      margin-right: 10px;
    }
  }
  .root__info__game {
    flex-direction: column;
    justify-content: space-around;
    margin-right: 50px;
  }
  .root__wrapper {
    display: flex;
    height: 270px;
    overflow: hidden;
    position: relative;
  }

  .root__scaleBlock {
    filter: blur(5px);
    top: 50%;
    left: 50%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    transform: translate(-50%, -50%) scale(2);
    -webkit-transform: translate(-50%, -50%) scale(2);

    & img {
      width: 100%;
    }
    //scale: 90;
  }

  .root__gameLive {
    margin: 0 15px;
    text-align: center;
    width: 100%;
  }
  .root__backColor {
    background: transparent;
    margin-top: -70px;
    position: relative;
    z-index: 10;
  }

  .root__imageWrapper {
    display: flex;
    width: 165px;
    height: 240px;
    right: 50%;
    position: absolute;
    transform: translate(50%, 6%);

    & img {
      width: 100%;
    }
  }

}
@media(max-width: 400px) {
  .root__info__game {
    margin-right: 10px;
    margin-left: 20px;
  }
}