@import 'variables';
@import "mixins";


.skeletonItem {
  background: none!important;
  height: 172px;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.item {
  //background: #292929;
  //box-shadow: inset 0 0 0 2000px #292929;
  background-position: center;
  background-size: cover;
  display: flex;
  width: 342px;
  min-height: 100px;
  padding: 8px;
  border-radius: 16px;
  overflow: hidden;

  &__play {
    border: 0;
    background-color: $secondary-color;
    outline: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    color: #fff;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    opacity: 0;

    svg {
      width: 20px;
      height: auto;
    }

    &:hover {
      background-color: $primary-color;
      color: #000;
    }
  }

  &__img {
    max-width: 110px;
    min-width: 110px;
    margin-right: 16px;
    position: relative;

    img {
      height: auto;
      transition: all .3s ease;
      -webkit-transition: all .3s ease;
      transform: scale(1);
      -webkit-transform: scale(1);
      filter: brightness(1);
    }

    &:hover {
      img {
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
        filter: brightness(0.5);
      }

      .item__play {
        opacity: 1;
      }
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    padding-top: 8px;
    padding-bottom: 4px;

    width: 200px;
  }

  &__details__top {
    flex: 1;

  }

  &__details__bottom {
  display: flex;
  width: 100%;
  }


  &__title {
    max-width: 100%!important;

    text-overflow: ellipsis;
  }

  &__date {
    font-size: 16px;
    line-height: 1;
    color: #868686;
    font-weight: 500;
    margin-bottom: 8px;
  }

  &__title {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    margin: 0 0 16px;

    display:block;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
  }

  &__imgPrize {
    min-width: 53px;
    min-height: 53px;
    margin-right: 8px;
    box-shadow: inset 0 0 0 2000px #555555;
    border-radius: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
      width: 50px;
      height: auto;
    }
  }

  &__userName {
    font-size: 16px;
    color: $dark_text;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    -webkit-align-items: center;

    .nickname{
      color: #fff;
      text-overflow: ellipsis;
      width: 150px;
      overflow: hidden;
    }

    svg {
      margin-right: 8px;
    }
  }

  &__prize {
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    color: $primary-color;
  }
}

@media (max-width: 1199px) {
  .item {
    background: #292929;

    &__img {


      &:hover {

        img {
          transform: scale(1) ;
          -webkit-transform: scale(1) ;
          filter: brightness(1) ;

        }

        .item__play {
          opacity: 0;
          display: none;
        }
      }
    }

    &__imgPrize {

      max-width: 40px;
      width: 100%;
      margin-right: 8px;
    }
    &__title {
      font-size: 15px;
    }
    &__prize {
      font-size: 14px;
    }
    &__userIcon {
      display: none;
    }
    &__userName {
      color: #F8F8F8;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 0;
    }


  }

}

@media (max-width: 575px) {

  .skeletonItem {
    background: none!important;
    height: 95.5px;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 222px;
  }

  .item {
    width: 222px;
    min-height: 95px;
    &__details__bottom {
      //border: 1px solid #fff;

    }
    &__imgPrize {
      min-width: 40px;
      margin-right: 8px;
      min-height: unset;
    }

     &__prize {
       font-size: 11.5px !important;
       //border: 1px solid #fff;
     }
  }

  .item__date, .item__title, .item__userName {
    font-size: 12px;
    line-height: 14px;

  }

  .item__userName {
    .nickname {

      width: 80px;

    }
  }

  .item__userName svg {
    display: none;
  }
  .item__userName {
    margin-bottom: 0;
  }

  .item__prize {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    margin-top: 7px;
  }

  .item__img {
    max-width: 55px;
    min-width: 55px;
  }

  .item__date {
    margin-bottom: 0;
  }

  .item__details {
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
  }

  .item__title {
    font-size: 11px;
    margin-bottom: 0;
  }

}