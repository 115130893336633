@import "mixins";
@import "variables";

$first_place_color: #EEB665;
$second_place_color: #DADADA;
$third_place_color: #DF8E4C;


.main {
  font-family: Poppins, "sans-serif";
  display: flex;
  flex-direction: column;
  gap: 60px;



  .hero {
    position: relative;
    height: fit-content ;

    .bg_image, .bg_image_mobile {
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    .bg_image_mobile {
      display: none;
    }

    h2 {
      color: #FFF;
      font-family: Poppins, "sans-serif";
      font-size: 60px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      margin: 0;
      text-align: center;
    }

    .surface {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      padding: var(--padding-margin);
      display: flex;
      align-items: center;

      .quest_logo {
        object-fit: contain;
        width: 100%;
        height: 130px;
      }

      .content {
        z-index: 1;
        display: flex;
        flex-direction: column;
        //align-items: center;
        gap: 16px;

        .same_width_blocks {

          .time_and_stats {
            display: flex;
            flex-direction: column;
            gap: 8px;
            height: auto;

            .time_limit_text {
              color: var(--White, #FFF);
              //text-align: center;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 14px; /* 116.667% */
            }


            .lv_stats {
              width: 100%;
              margin-top: 8px;

              .info {
                color: #fff;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                display: flex;
                justify-content: space-between;

                .left {
                  display: flex;
                  gap: 8px;
                  justify-content: space-between;
                  width: 100%;
                }

                .yellow {
                  color: $primary-color;
                }

                .gray {
                  opacity: 0.7;
                }
              }

              .lv_bar {
                height: 26px;
                width: 100%;
                border-radius: 24px;
                background: rgba(255, 255, 255, 0.2);
                backdrop-filter: blur(10px);
                margin-top: 8px;
                position: relative;

                .points_amn {
                  height: 100%;
                  border-radius: 24px;
                  background: $primary-color;
                  display: flex;
                  align-items: end;
                  position: relative;

                  .pin_image {
                    position: absolute;
                    max-width: 40px;
                    height: auto;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%) translateX(50%);
                  }
                }

                .leader_pin_image {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%) translateX(-50%);
                  max-width: 50px;
                  height: auto;
                }
                
                @media (max-width: 850px) {
                  height: 10px;
                  margin-bottom: 6px;
                  margin-top: 10px;

                  .points_amn {
                    .pin_image {
                      max-width: 30px;
                    }
                  }

                  .leader_pin_image {
                    max-width: 30px;
                  }
                }
              }
            }
          }

          .controllers {
            width: 100%;
            display: flex;
            gap: 8px;
            margin-top: 16px;

            button {
              flex: 1;
              white-space: nowrap;
            }
          }

        }
      }
    }
  }

  .quest_prizes {
    padding: 0 var(--padding-margin);

    .container {
      margin: 0 auto;
      width: 767px;
      display: flex;
      gap: 22px;
      align-items: end;
      max-width: 100%;

      .card {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;

        .nickname {
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
        }

        .prizes_card {
          position: relative;
          border-radius: 12px;
          width: 100%;

          &:before {
            content: "";
            position: absolute;
            top: 1px;
            bottom: 1px;
            left: 1px;
            right: 1px;
            border-radius: 12px;
          }

          &.place_2 {
            height: 110px;
            background: linear-gradient(180deg, #363538 60%, #bbbaba  100%);
            &:before {
              background: linear-gradient(180deg, #363538 0%, #0e0c10  100%);
            }
          }

          &.place_1 {
            height: 145px;
            background: linear-gradient(180deg, #3a2e21  60%,  #bc8f53  100%);
            &:before {
              background: linear-gradient(180deg, #3a2e21 0%, #0e0c10  100%);
            }
          }

          &.place_3 {
            height: 80px;
            background: linear-gradient(180deg, #37261c 60%, #a87040  100%);
            &:before {
              background: linear-gradient(180deg, #37261c 0%, #0e0c10 100%);
            }
          }

          .card_content {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 4px;
            height: 100%;
            width: 100%;
            padding-bottom: 16px;

            span {
              line-height: 100%;
              color: #fff;
              font-family: Montserrat;
              font-weight: 700;
              text-align: center;
            }

            .main_text {
              font-size: 28px;
            }

            .secondary {
              font-size: 18px;
            }

            .user_level {
              color: #0e0c10;
              display: flex;
              width: 102.133px;
              padding: 6px 12px;
              justify-content: center;
              align-items: center;
              border-radius: 43px;
              text-transform: uppercase;

              font-family: Montserrat;
              font-size: 10px;
              font-style: normal;
              font-weight: 700;
              line-height: 100%;

              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translate(-50%, 50%);
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  .swiper_container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .swiper {
      padding-top: 30px;
      max-width: 100%;

      &:hover {
        .dir_button {
          display: block;
        }
      }

      .sw_item {
        width: auto;

        .lv_item {
          width: 140px;
          display: flex;
          flex-direction: column;
          gap: 2px;
          font-family: Montserrat, sans-serif;

          &.passed {
            .top {
              background: $dark_cards_hover;

              .header span, .content {
                opacity: 0.3;
              }

              .header {
                box-shadow: none;
                background: $dark_cards_hover;
                color: $dark_text3;
                border: 2px solid $background-color;
              }

              .content {
                .prize_text {
                  .main {
                    color: $dark_text3;
                  }
                }
              }
            }
          }

          .top {
            border-radius: 12px 12px 0 0;
            position: relative;
            background: rgb(233,181,105);
            background: linear-gradient(0deg, rgba(233,181,105,0) 0%, rgba(233,181,105) 100%);

            &:before {
              content: '';
              display: block;
              position: absolute;
              z-index: 0;
              top: 1px;
              left: 1px;
              right: 1px;
              bottom: 1px;
              background: $dark_cards_hover;
              border-radius: 12px 12px 0 0;
            }

            .header {
              position: absolute;
              padding: 6px 12px;
              border-radius: 100px;
              font-size: 10px;
              font-style: normal;
              font-weight: 700;
              color: $background-color;
              background: $primary-color;
              box-shadow: 0px 0px 30px 0px rgba(238, 185, 108, 0.10), 0px 0px 20px 0px rgba(238, 185, 108, 0.10), 0px 0px 10px 0px rgba(238, 185, 108, 0.10), 0px 0px 5px 0px rgba(238, 185, 108, 0.10);
              left: 50%;
              transform: translate(-50%, -50%);
              white-space: nowrap;
            }

            .content {
              position: relative;
              margin-top: 24px;

              .prize_text {
                height: 64px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 8px;
                text-align: center;

                .main_val {
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 700;
                  color: #fff;
                  line-height: 100%;
                }

                .secondary {
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 700;
                  color: $dark_text3;
                  line-height: 100%;
                }
              }

              .image  {
                min-height: 110px;
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                  width: 100px;
                  height: auto;
                  object-fit: cover;
                }
              }
            }
          }

          .bot {
            background: $dark_cards_hover;
            border-radius: 0 0 12px 12px;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            gap: 8px;

            .tick {
              min-width: 20px;
              min-height: 20px;
              max-height: 20px;
              background: $secondary-color;
              border-radius: 100px;
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                color: $background-color;
                width: 17px;
                height: 17px;
              }
            }
          }
        }
      }

      @media (max-width: calc($maxWidth + 100px)) {
        //margin: 0 calc(var(--padding-margin) * -1);

        .sw_item:first-child {
          margin-left: var(--padding-margin);
        }

        .sw_item:last-child {
          margin-right: var(--padding-margin);
        }
      }
    }

    .subtitle_box {
      margin: 0 auto;
      max-width: 770px;
      font-family: Montserrat, sans-serif;
      display: flex;
      flex-direction: column;
      align-items: center;

      .subtitle {
        max-width: 100%;
        color: $dark_text3;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.1px;
        text-align: center;
        white-space: unset;
      }
    }

    .deb_btn {
      width: fit-content;
      margin: 0 auto;
    }
  }

  .terms {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    max-width: 774px;
    margin: 0 auto 50px;

    .open_terms {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      color: #fff;
      cursor: pointer;

      &.open {
        svg {
          transform: rotate(90deg);
        }
      }

      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;

      }

      svg {
        width: 24px;
        transform: rotate(-90deg);
        -webkit-transition: 0.2s all;
        transition: 0.2s all;
      }
    }

    .subtitle {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: $dark_text3;
    }

    .terms_list {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .term_item {
        background: $dark_cards;
        display: flex;
        max-width: 770px;
        padding: 16px;
        align-items: flex-start;
        gap: 30px;
        border-radius: 12px;

        .order {
          padding: 10px;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          color: #FFF;
        }

        .content {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }
  }
  
  @media (max-width: $maxWidth) {
    .hero {
      margin: 0 calc(var(--padding-margin) * -1);
      .surface {
        padding: calc(var(--padding-margin) * 2);

      }
    }
  }

  @media (max-width: 1000px) {
    .hero {
      //.bg_image {
      //  display: none;
      //}
      //
      //.bg_image_mobile {
      //  display: block;
      //}

      .surface {
        align-items: end;

        .quest_logo {
          height: 100px;
        }

        .countdown {
          div {
            //opacity: 0;
            font-size: 24px;
            //min-width: 60px;
            //height: 40px;
            //padding: 4px;
          }
        }

        .controllers {
          //button {
          //  padding: 0;
          //  font-size: 12px;
          //  min-height: 32px;
          //}
        }
      }
    }
  }

  @media (max-width: 850px) {
    .hero {
      .surface {
        padding-bottom: 8px;

        .quest_logo {
          height: 80px;
        }

        .countdown {
          div {
            min-width: 60px;
            height: 30px;
            padding: 0;
            font-size: 16px;
          }
        }

        .controllers {
          margin-top: 8px !important;

          button {
            padding: 0;
            font-size: 11px;
            min-height: 40px;
          }
        }
      }
    }
  }
  
  @media (max-width: 800px) {
    .hero {
      h2 {
        font-size: 32px;
      }
    }
  }
  
  @media (max-width: 767px) {
    .swiper_container {
      .swiper {
        .sw_item {
          .lv_item {
            width: 120px;

            .top {
              .header {
                white-space: nowrap;
              }

              .content {
                .prize_text {
                  .main {
                    font-size: 18px;

                  }

                  .secondary {
                    font-size: 12px;

                  }
                }

                .image {
                  min-height: 57px;

                  img {
                    width: 57px;
                  }
                }
              }
            }

            .bot {
              span {
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 750px) {
    margin-top: -80px;
    gap: 40px;

    .hero {
      margin: unset;

      .bg_image {
        display: none;
      }

      .bg_image_mobile {
        display: block;
      }

      .surface {
        padding-top: 8px;
        flex-direction: column;
        justify-content: end;
        align-items: center;

        .quest_logo {
          display: none;
        }

      }
    }

    .quest_prizes {
      .container {
        gap: 10px;

        .card {
          .nickname {
            font-size: 14px;
            white-space: nowrap;
          }

          .prizes_card {
            .card_content {
              padding: 0 8px;

              .main_text {
                font-size: 18px;
              }

              .secondary {
                font-size: 12px;
              }

              .user_level {
                width: fit-content;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 470px) {

    .hero {
      h2 {
        font-size: 32px;
      }

      .same_width_blocks {
        .controllers {
          flex-direction: column;
        }
      }

      .surface {
        position: unset;

      }
    }

    .terms {
      .term_item {
        flex-direction: column;
        gap: 0 !important;
      }
    }
  }
}

.modalQuest {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 15px 20px 30px;
  width: 600px;
  max-width: calc(100vw - 30px);


  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;


    .heading {
      margin: 0 !important;
      text-align: center;
      flex: 1;
      text-transform: capitalize;
      padding-left: 20px;
    }

    //.mobileDisapear {
    //  &.hidden {
    //    display: none;
    //  }
    //}
  }

  &__closeBtn {
    width: 40px;
    height: 40px;
    font-size: 14px;
    border-radius: 40px;
    border: none;
    background: none;
    color: #fff;

    img {
      transform: translateX(10px);
    }

  }

  &__content {
    max-width: 100%;
    margin: 0 auto;
    //padding: 0 32px;
    //border: 1px solid #fff;
    overflow: scroll;

    &.hidden {
      width: 450px;
      padding: 0;
      max-width: 100%;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    ol, ul, span, p {
      text-align: start !important;
    }

    span {
      display: block;
    }

    .textContainer {
      a {
        color: $primary-color ;

      }

      &.congrats {
        color: #fff;
      }

      &.nextAdv{
        color: $primary-color;
        font-size: 40px;
        line-height: 58px;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 24px;
        -webkit-transition: 0.7s all;
        transition: 0.7s all;

        &:hover {
          -webkit-transition: 0.7s all;
          transition: 0.7s all;
          text-shadow: rgba(238, 182, 101, 0.6) 0px 0 50px;
        }
      }

      &.sorryText {
        color: $primary-color;
        font-size: 35px;
        font-weight: 700;
        text-transform: uppercase;
      }

      &.nicknameSm {
        color: $primary-color;
        font-size: 18px;
        font-weight: 700;
        text-transform: capitalize;
      }

      &.soon {
        color: #fff;
        font-size: 16px;
        margin-top: 5px;

        p {
          text-align: center !important;
        }
      }
    }
  }
  
  @media (max-width: 500px) {
    &__header {

      .heading {
        font-size: 18px;
        text-align: start;
        padding: 0;
      }

      .mobileDisapear {
        &.hidden {
          display: none;
        }
      }
    }
  }
}