@import 'variables';
@import "mixins";

.root {
  min-height: 48px;
  padding: 0 32px;
  cursor: pointer;
  border: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  -webkit-align-items: center;
  transition: all .3s ease;
  outline: none;
  -webkit-transition: all .3s ease;
  border-radius: 100px;
  font-family: inherit;
  text-align: center;
  justify-content: center;


  &.primary {
    background: $secondary-color;
    color: white;

    &:hover {
      color: white;
      background: #08a50d;
    }
  }

  &.outlined {
    background: transparent;
    color: white;
    border: 2px solid $primary-color;

    &:hover {
      color: $primary-color;
    }
  }

  &.large {
    min-height: 56px;
    //font-size: 16px;
  }

  &:disabled {
    background: #1F1B24 !important;
    color: #3F3749 !important;
    cursor: initial !important;
  }

  &.outlinedDark {
    background: transparent;
    border: 2px solid #27222D;
    color: white;
  }

}


@media(max-width: 365px) {
  .root {
    font-size: 10px;
  }
}