@import "variables";

.header {
  padding-top: 10px;
  display: flex;
  gap: 20px;
  align-items: center;

  .switch {
    margin-bottom: 10px;
  }
}

//@media (max-width: 1199px) {
//  .header {
//    .switch {
//      margin-bottom: 20px;
//    }
//  }
//}

@media (max-width: 767px) {
  .header {
    .switch {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 575px) {
  .header {
    flex-direction: column;
    align-items: start;
  }
}