@import 'variables';

.root {
  background-color: $background-color;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
}