.info {
  padding-bottom: 100px;

}

.border-block {
  border: 1px solid green;
}

.infoTitle {
  margin: 14px 0;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
}

.infoBlock {
  font-size: 14px;
  //padding: 20px 0;
  padding: 0 30px 34px 30px;


  table {
    font-family: monospace;
    width: 100%;

    & p {
          color: #84789c;
        }

    margin-bottom: 30px;

    td {
      p {
        font-size: 14px;
      }
    }

    tr {
      border-bottom: 1px solid #604180;
      //font-weight: 400;
      padding: 15px 4px;
      display: flex;
      justify-content: space-around;
      white-space: nowrap;


      &:last-child{
        border-bottom: none;
      }


      th {
        color: #84789c;
        width: 100px;
        max-width: 100px;

        p {
          font-weight: 400;
          padding: 15px 4px;
          color: #84789c;
          font-size: 14px;
          white-space: wrap;
        }
      }

      td {
        width: 100px;
        max-width: 100px;
      }
    }
  }

  &.border_block {
    border: 1px solid #604180;
    padding: 20px 35px;
    overflow-x: auto;

    table ~ p{
      strong{
        color: #f1cf00;
      }
    }
  }
}

@media (max-width: 991px) {
  .infoBlock.border_block > p {
    font-size: 13px;
  }
  .infoBlock.border_block {
    padding: 20px;
  }
}
@media(max-width: 575px) {


  .info {
    padding-bottom: 30px;
  }
}

