.root {
  display: flex;
  align-items: center;
  -webkit-align-items: center;

  &__selector {
    width: auto;
    padding-right: 30px;
  }

  &__input {
    width: 100%;
    margin: 0 !important;
  }
}