@import 'variables';

@mixin localContainer {
  position: absolute;
  padding: 15px;
  background: #1B1025;
  border-radius: 12px;
  width: 340px;
  display: none;
  z-index: 999999;
  
}

@keyframes borderBalanceValidation {
  0% {border: 1px solid transparent;
    box-shadow: #f13737 2px 2px 25px 0;}
  50% {border: 1px solid #a80404;
    box-shadow: none}
  100%{
    border: 1px solid transparent;
    box-shadow: #f13737 2px 2px 25px 0;
  }
}

@keyframes borderBalance {
  0% {border: 1px solid transparent;
    box-shadow: #664f2c 2px 2px 25px 0;}
  50% {border: 1px solid #EEB665;
    box-shadow: none}
  100%{
    border: 1px solid transparent;
    box-shadow: #664f2c 2px 2px 25px 0;
  }
}

@keyframes spinsStyle {
  0% {background: rgba(238, 182, 101, 0.1)}
  50% {background: transparent}
  100% {background: rgba(238, 182, 101, 0.1) ;
  }
}

.bonusStyle {
  animation-name: spinsStyle;
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-delay: 2s;
}

.dropDownBalanceWrapper {


  .deposit_btn {
    margin: 16px 0;
    width: 100%;
  }

  &.dropDownBalance_active {
    .dropDownBalance {
      display: block;
    }
  }

  .btn_balance {
    position: relative;
    background: #1B1025;
    border: 1px solid transparent;
    border-radius: 30px;
    color: #EEB665;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    padding: 0;
    margin-right: 17px;
    min-height: 40px;

    .useBalanceWrapper {
      color: #fff;
      padding: 6px 0;
      display: inline-block;
      min-width: 100px;

      .useBalance {
        margin-right: 5px;
        padding-left: 15px;
      }

      .balanceCurrency {
        color: #84789C;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-right: 10px;
      }
    }

    .btn_balance_add {

      aspect-ratio: auto 48 / 48;
    }

    &:hover {
      border: 1px solid $dark_text;

    }
  }

  .btnBalance_NotValidation {
    animation-name: borderBalanceValidation;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-timing-function: linear;
  }

  .btnBalance_activeSpins {
    animation-name: borderBalance;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-timing-function: linear;
  }

  .main_item_component {
    &.open {

      .dropDownBalance__items {
        .left {
          svg {
            transform: rotate(90deg);
          }
        }
      }

      .bonus_container {
        max-height: 1000px;
        overflow: unset;
      }
    }
  }

  .dropDownBalance {
    right: 48px;
    top: 70px;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    overflow-x: hidden;
    @include localContainer;

    &__wrappers {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__cashAm {
      border-bottom: 1px solid #27222D;
    }

    &__items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #27222D;
      position: relative;
      cursor: pointer;
      padding: 2px 0;

      .left {
        display: flex;
        align-items: center;
        padding: 0 8px;
        gap: 8px;

        svg {
          color: $dark_text2;
          transform: rotate(-90deg);
          width: 14px;
          height: 14px;
          transition: 0.2s all;
          -webkit-transition: 0.2s all;
        }
      }

      &.clickable {
        cursor: pointer;
        -webkit-transition: 0.2s all;
        transition: 0.2s all;
        &:hover {
          background: rgba(63, 55, 73, 0.20);
          -webkit-transition: 0.2s all;
          transition: 0.2s all;
        }
      }
    }

    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__title {
      font-weight: 400;
      font-size: 12px;
      margin: 8px 0 8px 0;
      text-transform: uppercase;
      color: white;
      //padding-left: 10px;
    }

    &__data {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: #EEB665;
      display: flex;
      gap: 4px;

      //padding-right: 10px;
    }

    &__dataStyle {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #EEB665;
      color: #1B1025;
      padding: 0 5px;
      border-radius: 100px;
      width: fit-content;
      height: 27px;
      min-width: 27px;
      //text-align: center;
      margin-right: 10px;
      box-shadow: #664f2c 2px 2px 25px 0;
      //padding-right: 0;

    }

    &__currency {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      color: #84789C;
    }

    &__progressBlock {
      border-bottom: 1px solid #27222D;
    }

    &__progress {
      width: 100%;
      height: 24px;
      background-color: #27222d;
      border-radius: 30px;
      overflow: hidden;
      margin-bottom: 10px;
      position: relative;

      .progressData {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        color: white;
        text-align: center;
        width: 100%;
      }
    }

    &__withdraw {
      display: flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: space-between;
      line-height: 1.2;
    }

    &__btn {
      white-space: nowrap;
      border: 1px solid $primary-color;
      background-color: transparent;
      color: #fff;
      text-transform: uppercase;
      font-size: 12px;
      border-radius: 30px;
      padding: 4px 10px;
      outline: none;
      transition: all .3s ease;
      -webkit-transition: all .3s ease;

      &:hover {
        background-color: $primary-color;
        color: #000;
      }
    }

    .betStyle {
      animation-name: spinsStyle;
      animation-iteration-count: infinite;
      animation-duration: 1.5s;
      animation-timing-function: linear;
    }

    .firstStyle {
      animation-name: spinsStyle;
      animation-iteration-count: infinite;
      animation-duration: 1.5s;
      animation-timing-function: linear;
      animation-delay: 0s;
    }

    .spinsStyle {
      animation-name: spinsStyle;
      animation-iteration-count: infinite;
      animation-duration: 1.5s;
      animation-timing-function: linear;
      animation-delay: 1s;
    }

    .bonus_text {
      font-weight: 600;
      display: block;
      margin-top: 4px;
      font-size: 13px;
      text-transform: uppercase;
      color: $dark_text2;
    }

    .bonus_list {
      //max-height: 350px;
      //overflow-y: auto;
      //overflow-x: hidden;
      margin-top: 8px;

    }

    .bonus_container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-height: 0;
      overflow: hidden;
      transition: 0.2s all;
      -webkit-transition: 0.2s all;

      .bonus_type_bonus {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 8px 0;

        .bonuses {
          display: flex;
          gap: 12px;
          width: 100%;
          justify-content: space-between;
          align-items: end;

          span {
            font-size: 14px;
            font-weight: 600;
          }

          .yellow {
            color: $primary-color;
          }

          .range{
            display: flex;
            flex-direction: column;
            flex: 1;
            width: 100%;
            gap: 4px;
          }

          .amn {
            font-size: 12px;
            line-height: 100%;
          }
        }

        .inf {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 12px;
          margin-top: 12px;
          font-weight: 500;

          svg {
            color: $secondary-color;
            width: 18px;
            height: 18px;
          }
        }
      }

      .bonus_type_free_bets {
        display: flex;
        flex-wrap: wrap;
        padding: 16px 0;
        gap: 8px;

        .bet {
          display: flex;
          background: $dark3;
          border-radius: 8px;
          position: relative;
          cursor: pointer;

          .ticket_start {
            padding: 6px;
            border-right: 1px dashed $dark_text2;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 18px;
            }

            .cuts {
              position: absolute;
              right: 0;
              width: 10px;
              min-width: 10px;
              height: 10px;
              min-height: 10px;
              border-radius: 100px;
              background: #1B1025;

              &.top {
                top: 0;
                transform: translate(50%, -50%);
              }

              &.bottom {
                bottom: 0;
                transform: translate(50%, 50%);
              }
            }
          }

          .value {
            padding: 12px;
            font-weight: 700;
            font-size: 13px;
          }


        }
      }

      .bonus_type_free_spins {
        padding: 16px 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 16px;
        grid-row-gap: 16px;

        .game {
          cursor: pointer;
          position: relative;
        }
      }

      .badge {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(50%, 50%);
        min-width: 16px;
        height: 16px;
        width: fit-content;
        border-radius: 100px;
        background: $primary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #1B1025;
        font-size: 12px;
        font-weight: 700;
        padding: 0 3px;

        &.g {
          top: 0;
          bottom: unset;
          transform: none;
          min-width: 20px;
          height: 20px;
        }
      }
    }

    .questBalance {

      &.clickable {
        -webkit-transition: 0.2s all;
        transition: 0.2s all;
        &:hover {
          background: rgba(63, 55, 73, 0.20);
          -webkit-transition: 0.2s all;
          transition: 0.2s all;
        }
      }

      &__section {
        min-height: 32px;
        padding: 4px 8px;
        -webkit-transition: 0.3s all;
        transition: 0.3s all;


        &.section1 {
          padding: 8px 0 !important;
          border-bottom: 1px solid $dark1;
          cursor: pointer;

          h4 {
            margin-left: 8px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            /* identical to box height, or 133% */
            text-transform: uppercase;
            color: #FFFFFF;
          }

          .score {
            margin-right: 8px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;

            .yellow {
              color: $primary-color;
            }

          }
        }

        &.section2 {
          border-top: 1px solid $dark1;
          border-bottom: 1px solid $dark1;
        }

        &.section3{
          border-bottom: 1px solid $dark1;
        }
      }

      &__headings {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .infoLine {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          text-transform: uppercase;
          color: $primary-color;
        }

        .spinsQt {
          width: 24px;
          height: 24px;
          border-radius: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $background-color;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          background-color: $primary-color;
          box-shadow: 0px 0px 15px rgba(238, 182, 101, 0.3);
        }
      }
    }
  }

  @media (max-width: 767px) {
    .btn_balance {
      .btn_balance_add {
        //height: 36px;
        //width: 36px;
      }
    }
  }

  @media (max-width: 450px) {
    .dropDownBalance {
      position: fixed;
      top: 60px !important;
      left: 50%;
      transform: translateX(-50%);
      right: unset;
      width: calc(100vw - 32px);
    }
  }
}

.bonusCenter {
  position: relative;
  margin-right: 16px;

  &.active {
    .bonus_container {
      display: block;
    }
  }

  button {
    border: none;
    background: none;
  }

  .main_btn {
    position: relative;
    border-radius: 100px;
    height: 50px;
    width: 50px;
    background: #1b1025;
    padding: 0;

    &.blink {
      animation: blinkEffect 2s infinite ;
    }

    @keyframes blinkEffect {
      0% {
        -webkit-box-shadow: 0px 0px 15px 2px rgba(238, 182, 101, 0.4);
        box-shadow: 0px 0px 15px 2px rgba(238, 182, 101, 0.4);
      }
      50% {
        -webkit-box-shadow: 0px 0px 15px 2px rgba(238, 182, 101, 0.8);
        box-shadow: 0px 0px 15px 2px rgba(238, 182, 101, 0.8);
      }
      100% {
        -webkit-box-shadow: 0px 0px 15px 2px rgba(238, 182, 101, 0.4);
        box-shadow: 0px 0px 15px 2px rgba(238, 182, 101, 0.4);
      }
    }

    img {
      position: relative;
    }

    .badge {
      position: absolute;
      right: -3px;
      top: -3px;
      display: flex;
      width: 18px;
      height: 18px;
      background: $secondary-color;
      border-radius: 20px;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      color: #FFF;
    }

  }

  .bonus_container {
    @include localContainer;
    left: 50%;
    top: 60px;
    transform: translateX(-50%);


    &__items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #27222D;
      position: relative;
      width: 100%;

      &:hover {
        .right {
          background: $primary-color;
          color: $dark1;
        }
      }

      .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        .icon_container {
          width: 22px;
        }
      }

      .right {
        background: $dark1;
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        transition: 0.2s all;
        -webkit-transition: 0.2s all;
        color: #fff;

        img, svg {
          max-height: 16px;
          transform: rotate(180deg);
        }
      }

      &.clickable {
        cursor: pointer;
        -webkit-transition: 0.2s all;
        transition: 0.2s all;

        &:hover {
          background: rgba(63, 55, 73, 0.20);
          -webkit-transition: 0.2s all;
          transition: 0.2s all;
        }
      }
    }

    &__wrappers {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__title {
      font-weight: 400;
      font-size: 12px;
      margin: 8px 0 8px 0;
      text-transform: uppercase;
      color: white;
      //padding-left: 10px;
    }

    &__data {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: #EEB665;
      display: flex;
      gap: 4px;
      //padding-right: 10px;
    }

    &__dataStyle {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #EEB665;
      color: #1B1025;
      //padding: 5px;
      border-radius: 50%;
      width: 27px;
      height: 27px;
      //text-align: center;
      margin-right: 10px;
      box-shadow: #664f2c 2px 2px 25px 0;
      padding-right: 0;

    }
  }

  @media (max-width: 767px) {
    .main_btn {
      width: 40px;
      height: 40px;

      img {
        width: 24px;
        height: auto;
        transform: translateY(-1px);
      }
    }
  }

  @media (max-width: 400px) {
    .bonus_container {
      transform: translateX(-40%);
    }
  }
}

@media(max-width: 767px) {
  .btn-balance__add {
    width: 32px;
    height: 32px;
  }

  .useBalanceWrapper {
    font-size: 12px;
    padding-left: 10px;
  }
 .dropDownBalanceWrapper .btn_balance {
    margin-right: 10px;
  }
}

@media (max-width: 575px) {
  .dropDownBalance {
    right: 10px !important;
    top: 35px !important;
  }
}
