@import "variables";
@import "mixins";

@mixin thinContainer($gap){
  max-width: 920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: $gap;
}

@mixin headingStyle {
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;

  @media (max-width: 980px) {
    text-align: center;
  }
}

@mixin contentStyle {
  ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-left: 20px !important;

    li {
      p {
        font-size: 14px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: $dark_text3;
      }
    }
  }
}

.main {
  display: flex;
  flex-direction: column;
  gap: 72px;
  font-family: Montserrat, sans-serif;

  .hero {
    position: relative;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 calc(var(--padding-margin) * -1);

    &__desktop_bg, &__mobile_bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__desktop_bg {

    }

    &__mobile_bg {
      display: none;
    }

    &__inner {
      min-height: 435px;
      display: flex;
      flex-direction: column;
      justify-content: end;
      gap: 56px;
      z-index: 1;
      padding: 8px 0;

      .main_content {
        display: flex;
        flex-direction: column;
        gap: 24px;

        h3 {
          color: #FFF;
          font-size: 60px;
          font-style: normal;
          font-weight: 700;
          margin: 0;
          text-align: center;
        }

        .user_tickets_info {
          display: flex;
          gap: 8px;
          padding: 8px;
          background: rgba(255, 255, 255, 0.10);
          backdrop-filter: blur(10px);
          border-radius: 8px;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          color: $primary-color;
          font-weight: 500;
          min-height: 48px;

          .initials {
            min-width: 32px;
            min-height: 32px;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            text-transform: uppercase;
            background: $dark1;
            padding: 0;
            gap: 2px;
            color: #fff;
          }

          .username {
            max-width: 170px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .tickets {
            display: flex;
            gap: 8px;
            align-items: center;
            justify-content: center;
          }

          .not_logged {
            color: #fff;
            font-size: 14px;
            text-align: center;

            button {
              font-weight: 700;
              //color: inherit;
              font-size: inherit;
              border: none;
              background: none;
              cursor: pointer;
              text-decoration: underline;
              color: $primary-color;
            }
          }
        }

        .countdown_container {
          width: fit-content;

          .next_result {
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            color: #fff;

          }
        }

        .controllers {
          display: flex;
          justify-content: center;
          gap: 8px;

          button:disabled {
            pointer-events: unset;
            min-height: 48px;
            padding: 0 32px;
            cursor: pointer;
            border: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 1;
            text-transform: uppercase;
            display: inline-flex;
            align-items: center;
            -webkit-align-items: center;
            transition: all 0.3s ease;
            outline: none;
            -webkit-transition: all 0.3s ease;
            border-radius: 100px;
            font-family: inherit;
            text-align: center;
            justify-content: center;
            background: #3b3b3b;
            color: #8f8f8f;
            cursor: default;
          }
        }
      }

      .page_navs {


        button {
          min-width: 130px;
          padding: 10px;
          background: none;
          border: none;
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          text-transform: uppercase;
        }
      }

    }

  }

  .prizes {
    color: #fff;

    .container {
      @include thinContainer(32px);

      h4 {
        @include headingStyle;
      }

      .grid_container {
        .grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
          gap: 8px;

          .grid_item {
            background: $dark_cards;
            border-radius: 12px;
            padding: 24px 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
          }
        }
      }
    }
  }

  .rules {

    .container {
      @include thinContainer(24px);

      .headings {
        display: flex;
        flex-direction: column;
        gap: 16px;
        color: #FFF;

        h4 {
          @include headingStyle;
        }

        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.1px;
        }

      }

      .swiper {
        width: 100% ;

        .sw_item {
          width: auto;

          .ticket_item {
            min-width: 180px;
            //height: 300px;

            .content {
              background: $dark_cards_hover;

              .top .part, .bot {
                .label {
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  color: $dark_text3;
                }

                .value {
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                  color: #FFF;
                }
              }

              .top {
                padding: 16px;
                display: flex;
                flex-direction: column;
                gap: 16px;

                .part {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  gap: 4px;



                }

              }

              .divider {
                display: flex;
                justify-content: space-between;

                .divider_ends {
                  width: 9px;
                  height: 6px;
                  border-radius: 0 10px 10px 0;
                  background: #000;

                  &.end {
                    border-radius: 10px 0 0 10px;
                  }
                }

                .divider_item {
                  width: 6px;
                  height: 6px;
                  background: #000;
                  border-radius: 10px;
                }
              }

              .bot {
                padding: 16px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 8px;
              }

            }

            svg {
              width: 100%;
              height: auto;
            }
          }
        }
      }

      .content_list {
        @include contentStyle;
      }

      .get_tickets_btn {
        width: fit-content;
        margin: 0 auto;
      }
    }
  }

  .results {
    color: #fff;

    .container {
      @include thinContainer(16px);

      h4 {
        @include headingStyle;
      }

      .date_picker_container {
        display: flex;
        flex-direction: row;
        max-width: 100%;

        .dates_row {
          display: flex;
          flex-direction: row;
          justify-content: start;
          flex: 1;


          .array {
            flex: 1;

            &.for_desktop {
              display: flex;
              justify-content: space-between;
            }

            &.for_mobile {
              display: none;
              max-width: calc(100vw - 160px);
              overflow: hidden;

              .all_days {
                display: flex;
                flex-direction: row;
                transition: 0.2s all;
              }
            }

            @media (max-width: 980px) {
              &.for_desktop {
                display: none;
              }

              &.for_mobile {
                display: flex;

              }
            }

            .date_item {
              height: 48px;
              padding: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 14px;
              border: 1px solid transparent;
              white-space: nowrap;
              cursor: pointer;

              &:hover {
                border-bottom: 1px solid #fff;
              }

              &.selected {
                color: $primary-color !important;
                border-bottom: 1px solid $primary-color;

              }
            }


            .slider {
              flex: 1;
              width: 400px;

              .slide {
                width: auto;
              }
            }
          }

          .dir_button {
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            border: none;
            padding: 0;
            border-radius: 200px;
            transition: 0.2s all;
            -webkit-transition: 0.2s all;

            &.chevron_left {
              transform: rotate(-180deg);
            }

            &:disabled {
              opacity: 0.6;
              color: #3F3749;

              &:hover {
                background: rgba(255, 255, 255, 0);
              }
            }

            &:hover {
              background: rgba(255, 255, 255, 0.1);
            }
          }
        }

        .date_picker {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $dark_text3;
          gap: 8px;
          cursor: pointer;


          span {
            white-space: nowrap;
          }
        }
      }

      .winners_table {

        .table_header {
          color: #8F84A4;
        }

        .table_item, .table_header {
          display: flex;
          padding: 16px;
          border-radius: 12px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;

          .nickname, .tb_col_nickname {
            width: 60%;
          }

          &:nth-child(2n) {
            background: $dark_cards;
          }
        }
      }
    }
  }

  .terms {
    position: relative;
    //padding-bottom: 20px;

    .divider {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background: $dark_cards_hover;
      height: 1px;
      width: 100vw;
    }

    .button_terms {
      margin: 20px auto ;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      border: none;
      background: none;
      color: #FFF;
      display: flex;
      padding: 0;
      align-items: center;
      justify-content: center;
      gap: 14px;


      &.showed {
        svg {
          transform: rotate(-90deg);

        }
      }

      svg {
        width: 10px;
        transform: rotate(90deg);
        transition: 0.2s all;
        -webkit-transition: 0.2s all;
      }
    }

    .terms_content {
      margin: 0 auto;
      display: none;
      max-width: 920px;

      @include contentStyle;

      &.showed {
        display: block;

      }
    }
  }

  @media (max-width: 1199px) {

    .hero {
      padding: 0;
      background-color: $dark_cards_hover;


      &__mobile_bg {
        display: block;
        opacity: 0.2;
      }

      &__desktop_bg {
        display: none;
      }
    }
  }

  @media (max-width: 980px) {
    .rules {
      .container {
        max-width: 920px;

        .headings {
          p {
            text-align: center;
          }
        }

        .swiper {
          width: calc( 100% + var(--padding-margin) * 2);
          margin: 0 calc(var(--padding-margin) * -1);

          .sw_item {
            .ticket_item {
              min-width: 150px;
              max-width: 150px;
              .content {
                .top .part .value, .bot .value {
                  font-size: 16px;
                }
              }
            }
          }

          .sw_item:first-child  {

            .ticket_item{
              margin-left: var(--padding-margin);
            }
          }

          .sw_item:last-child  {
            margin-right: var(--padding-margin);
            .ticket_item{

            }
          }
        }

        .content_list {
          ul {

            li {
              p {
                font-size: 12px !important;
                line-height: 18px;
              }
            }
          }
        }
      }
    }

    .results {
      .container {
        .date_picker_container {
          .date_picker {
            span {
              display: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: 800px) {

    .hero {
      padding: 0;
      background-color: $dark_cards_hover;
      margin: 0 calc(var(--padding-margin) * -1);

      &__mobile_bg {
        display: block;
        opacity: 0.2;
      }

      &__desktop_bg {
        display: none;
      }

      &__inner {
        .main_content {
          h3 {
            font-size: 32px;
          }
        }
      }
    }

    .prizes {
      .container {
        gap: 24px;

      }
    }
  }

  @media (max-width: 630px) {
    .prizes {
      .container {

        .grid_container {
          margin: 0 calc(var(--padding-margin) * -1);
          overflow-x: scroll;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none;
          }

          .grid {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: 1fr;
            padding: 0 8px;
            width: fit-content;

            .grid_item {
              min-width: 200px;
              padding: 10px 16px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 470px) {
    .hero {

      &__inner {
        width: fit-content;
        align-items: center;

        .main_content {
          gap: 16px;
          padding: 8px;
          max-width: 340px;

          .user_tickets_info {
            font-size: 14px;
            .username {
              max-width: 120px;
            }
          }

          .controllers {
            flex-direction: column;

            button {
              flex: 1;
            }
          }
        }

        .page_navs {
          button {
            min-width: 110px;
            flex: 1;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }

    .results {
      .container {
        .winners_table {

          .table_header {
            display: none !important;
          }

          .table_item, .table_header {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .nickname, .tb_col_nickname {
              width: 100%;
            }
          }
        }
      }
    }
  }
}


