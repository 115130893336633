@import 'variables';
@import "mixins";


.modal {
  width: fit-content !important;

  .modalContent {
    width: 417px;
    display: flex;
    flex-direction: column;
    min-height: 300px;
    justify-content: space-between;
    gap: 16px;
    max-width: 100%;

    .top {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .img_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90px;
        height: 90px;
        margin: 0 auto;

        svg {
          width: 100%;
          height: auto;
        }
      }

      strong {
        color: $primary-color;
        font-size: 32px;
      }
    }

    form {
      gap: 8px;
      display: flex;

      .input {
        flex: 1;
        margin-bottom: 0;
      }

      button {
        width: fit-content;
      }
    }

    @media (max-width: 600px) {

      .top {
        .img_container {
          width: 70px;
          height: 70px;
        }

        strong {
          font-size: 24px;
        }
      }

      form {
        flex-direction: column;

        button {
          margin: 0 auto;
        }
      }
    }
  }
}

@media (max-width: 430px) {
  .title {
    margin-top: 10px;
  }
}