@import "variables";

@mixin infoBarSports {
  margin-bottom: 8px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .money {
    color: $spins-yellow-color !important;
    text-transform: uppercase;
  }
}

@mixin mainRightMargin {
  margin-right: 50px;

  @media (max-width: 1050px) {
    margin-right: 30px;
  }

}

@mixin paginationHeight {
  height: 72px;

  @media(max-width: 730px) {
    &.more_height {
      height: 160px;
    }

    &.height_limit {
      max-height: 72px !important;
      height: 72px !important;
    }
  }
}

@mixin fullWidthElementsPadding {

  padding-right: 50px !important;
  padding-left: 50px !important;

  @media(max-width: 1050px) {
    padding-right: 30px !important;
    padding-left: 30px !important;
  }

  @media(max-width: 600px) {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }

  @media(max-width: 400px) {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }

}

@mixin sliderPaddingLeft {
  padding-left: 50px!important;

  &.noPadding {
    padding-left: 0 !important;
    margin-right: 0 !important;
  }

  @media(max-width: 1050px) {
    padding-left: 30px!important;
  }

  //@media(max-width: 425px) {
  //  padding-left: 20px!important;
  //}

  @media(max-width: 1000px) {

    &.noPadding {
      margin-right: -30px !important;
    }
  }

  @media(max-width: 500px) {
    &.noPadding {
      margin-right: -20px !important;
    }
  }

  @media(max-width: 600px) {
    padding-left: 25px !important;
  }

  @media (max-width: 450px) {
    &.noPadding {
      margin-right: -10px !important;
    }
  }

  @media(max-width: 400px) {
    padding-left: 16px!important;

    &.noPadding {
      margin-right: -5px !important;
    }
  }
}

@mixin titleBlockWrapper {
  padding-left: 64px!important;
  margin-right: 20px;

  @media(max-width: 1620px) {
    margin-right: 40px;
  }

  @media(max-width: 1300px) {
    padding-left: 32px!important;
    margin-right: 7px;
  }

  @media(max-width: 1000px) {
    padding-left: 20px!important;
  }

  @media(max-width: 600px) {
    padding-left: 25px;
  }

  @media(max-width: 575px) {
    margin-top: 40px;
  }

  @media(max-width: 400px) {
    padding-left: 16px !important;
    margin-right: 0;
    padding-right: 12px!important;
  }
}

@mixin sliderNoMarginRight {
  &.noMarginRight {
    @media (max-width: 1990px) {
      margin-right: -$mainPaddingMargin1;
    }

    @media (max-width: 1050px) {
      margin-right: -$mainPaddingMargin2;
    }

    @media (max-width: 500px) {
      margin-right: -$mainPaddingMargin3;
    }

    @media (max-width: 450px) {
      margin-right: -$mainPaddingMargin4;
    }

    @media (max-width: 400px) {
      margin-right: -$mainPaddingMargin5;
    }
  }
}

@mixin iconsHovered {

  * {
    transition: 0.2s all;
    -webkit-transition: 0.2s all;
  }

  &.selected {
    color: var(--theme-white-black-text);

    svg, span {
      color: var(--theme-white-black-text);
    }
  }

  &.hoverable {}
  &:hover {

    //color: var(--theme-white-black-text) ;
  }
}

@mixin regularTransition {
  transition: 0.2s all;
  -webkit-transition: 0.2s all;
}

//BOXES START BOXES START BOXES START
@mixin pageContainerSidesPadding {
  padding: 0 $mainPaddingMargin1;

  @media (max-width: 1050px) {
    padding: 0 $mainPaddingMargin2;
  }

  @media (max-width: 500px) {
    padding: 0 $mainPaddingMargin3;
  }

  @media (max-width: 450px) {
    padding: 0 $mainPaddingMargin4;
  }

  @media (max-width: 400px) {
    padding: 0 $mainPaddingMargin5;
  }
}

@mixin darkBoxBordered {
  box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.15) inset, 0px -2px 1px 0px rgba(255, 255, 255, 0.10) inset;
  border: 1px solid $spins_border_gray_color;
}

@mixin boxYellow {
  background: $spins-primary-color;
  box-shadow: 0px 4px 8px 0px rgba(255, 255, 255, 0.60) inset;
}

@mixin gameThemeBox {
  .cardLink {
    display: block;
    max-width: 260px;
    transition: .2s all ease;
    -webkit-transition: .2s all ease;
    padding: 4px 8px;
    border-radius: 12px;

    &.selected {
      outline: 1px solid $spins-primary-color;
      background: var(--theme-bg1);

      &:hover {
        outline: 1px solid $spins-primary-color !important;
      }
    }

    &:hover {
      outline: 1px solid var(--theme-border1);
      background: var(--theme-bg1);
      border-radius: 12px;
    }
  }

  .cardLinkSkeleton {
    display: block;
    width: 260px;
    height: 150px;
  }
}

@mixin simpleBoxBordered {
  background: var(--theme-bg4);
  border: 1px solid var(--theme-border4);
}

@mixin shadedBoxLighter {
  border-radius: 16px;
  //border: 1px solid var(--theme-border4);
  background: var(--theme-border4);
  box-shadow: 0px 4px 8px 0px rgba(255, 255, 255, 0.06) inset, 0px 4px 6px 0px rgba(21, 25, 30, 0.30);
}

//BOXES END BOXES END BOXES END BOXES END

@mixin pauseAccSelfExclussion {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  .form {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  &__action_btn {
    text-align: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 30px;

  }

  &__item {

    .checkmark_label {
      display: flex;
      position: relative;
      //margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      border-radius: 100px;
      width: 100%;
      gap: 8px;
      align-items: center;

      input {
        position: absolute;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkmark2 {
        height: 25px;
        width: 25px;
        border-radius: 50px;
        background-color: transparent;
        border: 2px solid $spins-dark_text;
        position: absolute;
        left: 16px;
      }

      .checkBoxBlock2 {
        color: $spins-dark_text;
        font-size: 11px;
        border-radius: 100px;
        border: 1px solid transparent;
        padding: 16px;
        width: 100%;
        padding-left: 50px;

        .chekboxText {
          line-height: 1.5;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .checkmark_label input:checked ~ .checkmark2{
      background-color:  transparent !important;
      border: 2px solid $spins-primary-color;

      .checkBoxBlock {
        color: white;
      }

      .checkBoxBlock2 {
        color: white;
      }

    }

    .checkmark_label input:checked ~ .checkBoxBlock {
      border: 1px solid #EEB665;
      & .chekboxBreak {
        color: white!important;
      }
    }

    .checkmark_label input:checked ~ .checkBoxBlock2 {
      border: 1px solid #EEB665;

      .chekboxBreak {
        color: var(--theme-color)
      }
    }

    .checkmark_label .checkmark:after {
      left: 9px;
      top: 4px;
      width: 5px;
      height: 10px;
      border: solid #1E1E1E;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      display: none;
    }

    .checkmark_label .checkmark2:after {
      left: 4px;
      top: 4px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: $spins-primary-color;
      border: solid $spins-primary-color;
      display: none;
    }

    .checkmark_label input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark_label input:checked ~ .checkmark2:after {
      display: block;
    }

    .checkmark_label input:checked {
      .pauseWrapper {
        border: none;
        background: grey;
      }
    }

    .checkmark:after {
      content: "";
      position: absolute;
    }

    .checkmark2:after {
      content: "";
      position: absolute;
    }
  }
}

//MODALS START MODALS START MODALS START
@mixin contentModalWidth {
  width: calc(100% - 48px);

  @media (max-width: 575px) {
    width: calc(100% - 32px);
  }
}
//MODALS END MODALS END MODALS END

//OTHERS START
@mixin paginationBottomMargin {
  margin-bottom: -72px !important;

  @media(max-width: 1000px)  {
    margin-bottom: 0 !important;
  }
}

@mixin closeBtnShadowed {
  margin-bottom: 0.5rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background: var(--theme-bg2);
  transition: 0.2s all;
  -webkit-transition: 0.2s all;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--theme-shadow1);
  color: var(--theme-white-black-text) !important;

  svg {
    width: 24px !important;
    height: 24px !important;
  }

  &:hover {
    background: var(--theme-bg2-hover);
    box-shadow: var(--theme-shadow-hover1);
  }
}

@mixin closeBtnUnshadowed {
  margin-bottom: 0.5rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background: var(--theme-bg2);
  transition: 0.2s all;
  -webkit-transition: 0.2s all;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: var(--theme-bg2-hover);
  }
}

@mixin smallCountdown {

  background: $danger;
  padding: 0 16px;
  gap: 8px;

  div {
    border: none !important;
    font-size: 12px !important;
    width: fit-content;
    height: fit-content;
    min-width: unset;
    min-height: unset;
    padding: 0;
    gap: 0;

    span {
      display: inline;
    }
  }

  span {
    display: none;
  }
}

@mixin jpBadge {
  height: 100%;
  border-radius: 40px;
  padding: 10px;
  color: #FFF;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  &.cool_start {
    background: #097B9E;
    box-shadow: 0px 4px 5px 0px rgba(106, 2, 160, 0.40) inset, 0px -4px 5px 0px rgba(238, 182, 101, 0.40) inset;
  }

  &.warm_up {
    background: #ED9E15;
    box-shadow: 0px 4px 5px 0px rgba(106, 2, 160, 0.40) inset, 0px -4px 5px 0px rgba(238, 182, 101, 0.40) inset;
  }

  &.blazing_hot {
    background: #E9550C;
    box-shadow: 0px 4px 5px 0px rgba(106, 2, 160, 0.40) inset, 0px -4px 5px 0px rgba(238, 182, 101, 0.40) inset;
  }

  &.on_fire {
    background: #9D0000;
    box-shadow: 0px 4px 5px 0px rgba(106, 2, 160, 0.40) inset, 0px -4px 5px 0px rgba(238, 182, 101, 0.40) inset;
  }
}

@mixin spinningValue {
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 100%;
  text-align: start;

  .value {
    overflow: hidden;

    //height: 25px;
    .slide {
      display: flex;
      flex-direction: column;

      &.sliding {
        animation-name: slideAnim;
        animation-duration: $animationDuration;
        animation-timing-function: ease-out;
        animation-iteration-count: infinite;
      }

      &.rolling {
        animation-name: rollAnim;
        animation-duration: $animationDuration;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }


      @keyframes rollAnim {
        from {
          transform: translateY(0);

        }

        to {
          transform: translateY(-50%);

        }
      }

      @keyframes slideAnim {
        0% {
          transform: translateY(0);
        }

        80% {
          transform: translateY(-28px);
        }

        to {
          transform: translateY(-30px);
        }
      }
    }
  }
}

//OTHERS END
@mixin regularGridResponsive($width, $gap) {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($width, 1fr));
  gap: $gap;
}





