@import 'variables';
@import "mixins";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //align-items: flex-start;
  //-webkit-align-items: flex-start;
  //border: 1px solid #35303e;
  min-height: 56px;
  padding: 0 16px;
  border-radius: 50px;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
  cursor: pointer;
  user-select: none;
  position: relative;
  width: 100%;
  margin-right: 10px;
  background: #1F1B24;
  align-items: center;
  -webkit-align-items: center;

  &:last-child {
    margin-right: 0;
    //padding: 8px 20px 8px;
  }

  &.hasIcon {
    padding-left: 8px;
  }

  &__inner {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    width: 100%;
    justify-content: flex-start;
    text-align: left;
    overflow: hidden;

    &__icon {
      margin-right: 8px;
    }
  }

  &__labelWrapper {
    display: flex;
    align-items: flex-end;
    -webkit-align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    //position: relative;
  }

  &__selected {
    text-transform: capitalize;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    margin-right: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    gap: 8px;
    align-items: center;

    img {
      max-height: 14px;
    }
  }

  &__icon {
    margin-right: 12px;
    color: $primary-color;
  }

  &__label {
    text-transform: uppercase;
    font-size: 12px;
    //margin: 7px 0 0px 0;
    //line-height: 1.6;
    align-self: flex-start;
    color: #84789c;
    font-weight: 400;
    letter-spacing: 0;


    //margin-right: 8px;
    //color: $primary-color;
    //text-transform: uppercase;
  }

  &__caret {
    position: absolute;
    top: 50%;
    right: 12px;
    width: 35px;
    height: 35px;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #84789C;
    transform: translateY(-50%);

    & svg {
      width: 14px;
      height: 12px;

    }

    //opacity: .5;
    //transform: rotate(0);
    //-webkit-transform: rotate(0);
    //transition: all .3s ease;
    //-webkit-transition: all .3s ease;
    //position: absolute;
    //right: 0;
    //top: -8px;

  }

  &__options {
    position: fixed;
    z-index: 1100;
    min-width: 250px;
    //width: 101%;
    //right: -1px;
    //top: -1px;
    background-color: #19131d;
    border-radius: 24px 8px 8px 24px;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
    padding: 8px;
    padding-left: 0;
    //transform: translateY(10px);
    //-webkit-transform: translateY(10px);
    //opacity: 0;
    //pointer-events: none;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    max-height: 300px;
    overflow: auto;

    &__item {
      cursor: pointer;
      height: 100%;
      color: #84789c;
      transition: all .3s;
      overflow: hidden;
      font-size: 1em;
      font-family: inherit;
      line-height: 1.5;
      text-overflow: ellipsis;
      box-sizing: content-box;
      padding: 12px 16px;
      display: flex;
      align-items: center;

      &__icon {
        margin-right: 8px;
      }

      .specified {
        display: flex;
        flex-direction: column;
        gap: 8px;

        div:first-child {
          display: flex;
          align-items: center;

          &>img {
            margin-left: 8px;
            max-height: 18px;
          }
        }

        div:last-child {
          display: flex;
          gap: 8px;
        }
      }

      //padding: 8px;
      //color: #84789c;
      //font-weight: 500;
      //font-size: 14px;
      //letter-spacing: 0.1px;
      //background-color: transparent;
      //cursor: pointer;
      ////border-radius: 8px;

      &.selected, &:hover {
        //background-color: #0E0C10;
        border-left: 5px solid #604180;
        background: rgba(0,0,0,.12);
        color: #d2cddb;
      }

      &.selected {
        color: $primary-color;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.active {
    .root__caret {
      opacity: 1;
      transform: translateY(-50%) rotate(180deg);
      -webkit-transform: translateY(-50%) rotate(180deg);
    }

    .root__options {
      opacity: 1;
      transform: translateY(0);
      -webkit-transform: translateY(0);
      pointer-events: initial;
      z-index: 99999999;
    }
  }

  &:hover, &.active {
    border-color: #35303e;
  }
}
.closeModal {
  display: none;
}
@media (max-width: 767px)  {
  .root {
    //margin-bottom: 10px;
    min-height: 50px;

  }
}