@import 'variables';
@import "mixins";

//.search_icon {
//  width: 40px;
//  height: 40px;
//  padding: 0;
//  background-color: transparent;
//  border: 0;
//  outline: none;
//  cursor: pointer;
//  margin-right: 10px;
//}
//
//.overlay {
//  position: fixed;
//  top: 100px;
//  display: flex;
//  left: 0;
//  right: 0;
//  bottom: 0;
//  z-index: 499;
//  //background-color: rgba(0,255,255,.5);
//  overflow: hidden;
//  pointer-events: none;
//  padding-top: 30px;
//  padding-bottom: 30px;
//  background-color: #000;
//  transform: translateY(50px);
//  -webkit-transform: translateY(50px);
//  //transition: opacity .2s linear, transform .2s linear;
//  //-webkit-transition: opacity .2s linear, transform .2s linear;
//  opacity: 0;
//  transition-delay: 0s;
//  -webkit-transition-delay: 0s;
//
//  &__empty {
//    font-weight: 500;
//    position: absolute;
//    text-align: center;
//    top: 50%;
//    left: 50%;
//    transform: translate(-50%, -50%);
//    -webkit-transform: translate(-50%, -50%);
//  }
//
//  &__results {
//    overflow-y: auto;
//    height: 100%;
//
//    & > div {
//      display: flex;
//      //height: 100%;
//      height: auto;
//    }
//  }
//
//  & > * {
//    width: 100%;
//  }
//
//  &.active {
//    pointer-events: initial;
//    transform: translateY(0);
//    -webkit-transform: translateY(0);
//    opacity: 1;
//    transition-delay: .4s;
//    -webkit-transition-delay: .4s;
//
//    &::before {
//      transform: translate(-50%, -50%) scale(999);
//      -webkit-transform: translate(-50%, -50%) scale(999);
//    }
//  }
//}
//
//.search_bar {
//  height: 0;
//  //transition: all .2s linear;
//  //-webkit-transition: all .2s linear;
//  //transition-delay: 0.4s;
//  //-webkit-transition-delay: 0.4s;
//  //overflow: hidden;
//
//  &__inner {
//    padding: 8px 0 8px 12px;
//    width: 100%;
//    display: flex;
//    align-items: center;
//    -webkit-align-items: center;
//    transition: all .2s linear;
//    -webkit-transition: all .2s linear;
//    transition-delay: 0s;
//    -webkit-transition-delay: 0s;
//    opacity: 0;
//    pointer-events: none;
//    z-index: -1;
//    //display: none;
//
//    .outlinedButton {
//      height: 48px;
//      padding: 0 32px;
//      cursor: pointer;
//      border: none;
//      font-weight: 500;
//      font-size: 14px;
//      line-height: 1;
//      text-transform: uppercase;
//      display: inline-flex;
//      align-items: center;
//      -webkit-align-items: center;
//      transition: all .3s ease;
//      outline: none;
//      -webkit-transition: all .3s ease;
//      border-radius: 100px;
//      font-family: inherit;
//      text-align: center;
//      justify-content: center;
//      background: none;
//      color: #f8f8f8;
//      margin-right: 10px;
//      border: 2px solid rgba(139, 129, 156, 0);
//
//      &:hover {
//        border: 2px solid #8B819C;
//        -webkit-transition: all .3s ease;
//        transition: all .3s ease;
//
//      }
//    }
//  }
//
//  &__back {
//    height: 100%;
//    padding: 0;
//    width: 35px;
//    border: 0;
//    outline: none;
//    background-color: transparent;
//    color: $primary-color;
//
//    //&:hover {
//    //  color: $secondary-color;
//    //}
//  }
//
//  &__input {
//    border: 0;
//    outline: none;
//    font-size: 18px;
//    flex: 1;
//    height: 100%;
//    padding: 0;
//    background-color: transparent;
//    color: #fff;
//
//    &::-webkit-input-placeholder {
//      font-size: 16px;
//      font-weight: 500;
//      color: $dark_text;
//    }
//    &::-moz-placeholder {
//      font-size: 16px;
//      font-weight: 500;
//      color: $dark_text;
//    }
//    &:-ms-input-placeholder {
//      font-size: 16px;
//      font-weight: 500;
//      color: $dark_text;
//    }
//    &:-moz-placeholder {
//      font-size: 16px;
//      font-weight: 500;
//      color: $dark_text;
//    }
//  }
//
//  &__providers {
//    justify-content: center;
//    padding: 0px 8px;
//    padding-right: 0px;
//
//    &__item {
//      background-color: $dark1;
//      border: 1px solid $dark1;
//      max-width: 110px;
//      width: 100%;
//      margin-bottom: 10px;
//      height: 48px;
//      display: inline-flex;
//      justify-content: center;
//      align-items: center;
//      -webkit-align-items: center;
//      cursor: pointer;
//      transition: all .3s ease;
//      -webkit-transition: all .3s ease;
//      margin-right: 8px;
//      border-radius: 8px;
//
//      img {
//        display: block;
//        max-width: 100%;
//        max-height: 100%;
//        width: auto;
//        height: auto;
//        transition: all .3s ease;
//        -webkit-transition: all .3s ease;
//      }
//
//      &:hover {
//        border-color: $dark_text;
//      }
//
//      &.active {
//        border-color: $primary-color;
//      }
//    }
//  }
//  &__selectedBlock {
//    text-align: end;
//    padding:8px 50px 8px 0;
//    background: #19131D;
//    border-top: 1px solid #27222D;
//    margin: 5px -50px 0 -50px;
//
//  }
//
//  &__reset {
//    height: 40px;
//    padding: 10px 12px
//  }
//  &__selectedGames {
//    font-weight: 500;
//    font-size: 16px;
//    line-height: 16px;
//    color: #84789C;
//    margin-right: 24px;
//
//  }
//
//  &__title {
//    color: #fff;
//    margin: 0 0 16px;
//    font-weight: 700;
//    font-size: 24px;
//    line-height: 29px;
//  }
//
//
//  &.active {
//    height: 72px;
//    transition-delay: 0s;
//    -webkit-transition-delay: 0s;
//    //z-index: 999999;
//
//    .search_bar__inner {
//      opacity: 1;
//      //display: block;
//      transition-delay: 0.4s;
//      -webkit-transition-delay: 0.4s;
//      pointer-events: initial;
//    }
//  }
//}
//.search__sideBar {
//  display: none;
//}
//.search_bar__filterIcon {
//  display: none;
//}
//
//@media (max-width: 1200px) {
//  .search_icon {
//    position: absolute;
//    right: -41px;
//    z-index: 7;
//  }
//
//  //.search_bar {
//  //  &__inner {
//  //    .outlinedButton {
//  //
//  //    }
//  //  }
//  //}
//
//}
//
//@media (max-width: 1199px) {
//  .search_bar__furnizorBlock {
//    display: none;
//  }
//  .searchOverlay{
//    position: fixed;
//    width: 100%;
//    height: 100%;
//    top: 0;
//    left:0;
//    right: 0;
//    bottom: 0;
//    background-color: rgba(0,0,0,0.5);
//    z-index: 1000;
//    opacity: 0;
//    pointer-events: none;
//    transition: all .3s ease;
//    -webkit-transition: all .3s ease;
//
//
//    &.active{
//      opacity: 1;
//      transition: all .3s ease;
//      -webkit-transition: all .3s ease;
//      pointer-events: initial;
//    }
//  }
//  .header-scroll.active-search:not(.fixed-scroll):after {
//    height: 0;
//  }
//
//  //.header-scroll.fixed-scroll:after {
//  //  opacity: 0;
//  //}
//
//  .header-scroll:after {
//    display: none;
//  }
//  .search_bar__selectedBlock {
//    display: none;
//  }
//
//  .search_bar__selectedProvider {
//    background: red;
//    color: white;
//    //padding: 1px 3px;
//    border-radius: 50%;
//    width: 15px;
//    height: 15px;
//    padding: 0 3px;
//    font-size: 10px;
//    position: absolute;
//    left: 10px;
//  }
//  .search_bar__inner {
//    padding: 8px 8px 8px 12px;
//    margin-top: 17px;
//    position: absolute;
//    z-index: -1;
//    top: 0;
//    left: 0;
//    right: 0;
//    bottom: 0;
//  }
//
//  .search_bar__filterWrapper__btn {
//    display: flex;
//    align-items: center;
//    justify-content: space-between;
//    margin: 30px 0;
//
//  }
//  .search_bar {
//    &.active {
//      position: absolute;
//      z-index: 10;
//      top: 0;
//      left: 0;
//      right: 0;
//      background: linear-gradient(270deg, #0E0C10 0%, #20102B 33.85%, #20102B 66.15%, #0E0C10 100%);
//
//
//      height: 100px;
//      //mob height: 75px
//
//    }
//  }
//
//  .search__sideBar {
//    display: block;
//    background: #0E0C10;
//    height: 100%;
//    max-width: 395px;
//    width: 100%;
//    position: fixed;
//    top: 0px;
//    right: -110%;
//    z-index: 12;
//    transition: all 0.3s ease;
//    -webkit-transition: all 0.3s ease;
//    //height: 100%;
//    padding: 10px 16px;
//
//    &.active {
//      transition: all .3s ease;
//      -webkit-transition: all .3s ease;
//      right: 0;
//      overflow: auto;
//      &::-webkit-scrollbar {
//        display: none;
//      }
//    }
//
//  &__filterText {
//    display: flex;
//    justify-content: space-between;
//    align-items: center;
//  }
//  &__text {
//    font-weight: 700;
//    font-size: 24px;
//    line-height: 29px;
//    margin-bottom: 0;
//    color: #FFFFFF;
//  }
//    &__textIcon {
//      width: 14px;
//      height: 14px;
//      & img {
//        width: 100%;
//      }
//    }
//}
//.search_bar__filterIcon {
//  display: block;
//  position: relative;
//  cursor: pointer;
//  margin-right: 15px;
//}
//
//.sideBarSort {
//display: block;
//}
//}
//@media(max-width: 767px) {
//  .overlay.active {
//    top: 95px!important;
//  }
//}
//
//@media(max-width: 757px) {
//  .search_active {
//    height: 60px;
//  }
//  .overlay.active {
//    top: 70px!important;
//  }
//
//  .search_bar {
//    &.active {
//      height: 60px;
//
//    }
//
//    &__inner {
//
//    }
//  }
//  .search_bar__inner {
//    margin-top: 8px;
//  }
//  .search_bar__filterIcon {
//    margin-right: 7px;
//  }
//
//}
//@media(max-width: 550px) {
//  .overlay.active {
//    top: 56px!important;
//  }
//
//  .search_bar {
//
//    &.active {
//      height: 60px;
//
//    }
//
//    &__inner {
//
//      .outlinedButton {
//        font-size: 10px;
//        height: 30px;
//        min-width: fit-content;
//        padding: 0 !important;
//
//        &:focus {
//          border: none;
//        }
//
//        &:hover {
//          border: none;
//        }
//      }
//    }
//
//  }
//}
//
//@media(max-width: 370px) {
//  .search_bar {
//
//    &.active {
//      height: 60px;
//    }
//    &__inner {
//
//      .outlinedButton {
//        font-size: 10px;
//        height: 30px;
//        min-width: fit-content;
//        padding: 0 !important;
//
//
//      }
//    }
//
//    &__input {
//      width: 190px;
//
//    }
//  }
//}



.search_icon {
  width: 40px;
  height: 40px;
  padding: 0;
  background-color: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  margin-right: 10px;
}

.overlay {
  position: fixed;
  top: 100px;
  display: flex;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 499;
  //background-color: rgba(0,255,255,.5);
  overflow: hidden;
  pointer-events: none;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #000;
  transform: translateY(50px);
  -webkit-transform: translateY(50px);
  //transition: opacity .2s linear, transform .2s linear;
  //-webkit-transition: opacity .2s linear, transform .2s linear;
  opacity: 0;
  transition-delay: 0s;
  -webkit-transition-delay: 0s;

  &__empty {
    font-weight: 500;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }

  &__results {
    overflow-y: auto;
    height: 100%;

    & > div {
      display: flex;
      //height: 100%;
      height: auto;
    }
  }

  & > * {
    width: 100%;
  }

  &.active {
    pointer-events: initial;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    opacity: 1;
    transition-delay: .4s;
    -webkit-transition-delay: .4s;

    &::before {
      transform: translate(-50%, -50%) scale(999);
      -webkit-transform: translate(-50%, -50%) scale(999);
    }
  }
}

.search_bar {
  height: 0;
  //transition: all .2s linear;
  //-webkit-transition: all .2s linear;
  //transition-delay: 0.4s;
  //-webkit-transition-delay: 0.4s;
  //overflow: hidden;

  &__inner {
    padding: 8px 0 8px 12px;
    width: 100%;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    transition: all .2s linear;
    -webkit-transition: all .2s linear;
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
    opacity: 0;
    pointer-events: none;
    z-index: -1;
    //display: none;

    .sides {
      align-items: center;
      display: flex;

      &.left {
        flex: 1;
      }
      
      @media (max-width: 500px) {

        &.left {
          input {
            max-width: 150px;
            font-size: 12px !important;
          }

          input::placeholder {
            font-size: 12px !important;
          }
        }

      }
    }

    .outlinedButton {
      height: 48px;
      padding: 0 32px;
      cursor: pointer;
      border: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 1;
      text-transform: uppercase;
      display: inline-flex;
      align-items: center;
      -webkit-align-items: center;
      transition: all .3s ease;
      outline: none;
      -webkit-transition: all .3s ease;
      border-radius: 100px;
      font-family: inherit;
      text-align: center;
      justify-content: center;
      background: none;
      color: #f8f8f8;
      margin-right: 10px;
      border: 2px solid rgba(139, 129, 156, 0);

      &:hover {
        border: 2px solid #8B819C;
        -webkit-transition: all .3s ease;
        transition: all .3s ease;

      }
    }
  }

  &__back {
    height: 100%;
    padding: 0;
    width: 35px;
    border: 0;
    outline: none;
    background-color: transparent;
    color: $primary-color;

    //&:hover {
    //  color: $secondary-color;
    //}
  }

  &__input {
    border: 0;
    outline: none;
    font-size: 18px;
    flex: 1;
    height: 100%;
    padding: 0;
    background-color: transparent;
    color: #fff;

    &::-webkit-input-placeholder {
      font-size: 16px;
      font-weight: 500;
      color: $dark_text;
    }
    &::-moz-placeholder {
      font-size: 16px;
      font-weight: 500;
      color: $dark_text;
    }
    &:-ms-input-placeholder {
      font-size: 16px;
      font-weight: 500;
      color: $dark_text;
    }
    &:-moz-placeholder {
      font-size: 16px;
      font-weight: 500;
      color: $dark_text;
    }
  }

  &__providers {
    justify-content: center;
    padding: 0px 8px;
    padding-right: 0px;

    &__item {
      background-color: $dark1;
      border: 1px solid $dark1;
      max-width: 110px;
      width: 100%;
      margin-bottom: 10px;
      height: 48px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      -webkit-align-items: center;
      cursor: pointer;
      transition: all .3s ease;
      -webkit-transition: all .3s ease;
      margin-right: 8px;
      border-radius: 8px;

      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        transition: all .3s ease;
        -webkit-transition: all .3s ease;
      }

      &:hover {
        border-color: $dark_text;
      }

      &.active {
        border-color: $primary-color;
      }
    }
  }
  &__selectedBlock {
    text-align: end;
    padding:8px 50px 8px 0;
    background: #19131D;
    border-top: 1px solid #27222D;
    margin: 5px -50px 0 -50px;

  }

  &__reset {
    height: 40px;
    padding: 10px 12px
  }
  &__selectedGames {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #84789C;
    margin-right: 24px;

  }

  &__title {
    color: #fff;
    margin: 0 0 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }


  &.active {
    height: 72px;
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
    //z-index: 999999;

    .search_bar__inner {
      opacity: 1;
      //display: block;
      transition-delay: 0.4s;
      -webkit-transition-delay: 0.4s;
      pointer-events: initial;
    }
  }
}
.search__sideBar {
  display: none;
}

.search_bar__filterIcon {
  display: none;
}

@media (max-width: 1200px) {
  .search_icon {
    position: absolute;
    right: -41px;
    z-index: 7;
  }
}

@media (max-width: 1199px) {
  .search_bar__furnizorBlock {
    display: none;
  }

  .search_filter_desktop {
    display: none !important;
  }

  .search_filter_desktop_az {
    display: none !important;
  }

  .searchOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left:0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;


    &.active{
      opacity: 1;
      transition: all .3s ease;
      -webkit-transition: all .3s ease;
      pointer-events: initial;
    }
  }
  .header-scroll.active-search:not(.fixed-scroll):after {
    height: 0;
  }

  //.header-scroll.fixed-scroll:after {
  //  opacity: 0;
  //}

  .header-scroll:after {
    display: none;
  }
  .search_bar__selectedBlock {
    display: none;
  }

  .search_bar__selectedProvider {
    text-align: center;
    background: red;
    color: white;
    //padding: 1px 3px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    padding: 0 3px;
    font-size: 10px;
    position: absolute;
    left: 10px;
  }
  .search_bar__inner {
    padding: 8px 8px 8px 12px;
    margin-top: 17px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .search_bar__filterWrapper__btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0;

  }
  .search_bar {
    &.active {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      right: 0;
      background: linear-gradient(270deg, #0E0C10 0%, #20102B 33.85%, #20102B 66.15%, #0E0C10 100%);


      height: 100px;
      //mob height: 75px

    }
  }

  .search__sideBar {
    display: block;
    background: #0E0C10;
    height: 100%;
    max-width: 395px;
    width: 100%;
    position: fixed;
    top: 0px;
    right: -110%;
    z-index: 12;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    padding: 10px 16px;

    &.active {
      transition: all .3s ease;
      -webkit-transition: all .3s ease;
      right: 0;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__filterText {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__text {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 0;
      color: #FFFFFF;
    }
    &__textIcon {
      width: 14px;
      height: 14px;
      & img {
        width: 100%;
      }
    }
  }
  .search_bar__filterIcon {
    display: block;
    position: relative;
    cursor: pointer;
    margin-right: 15px;
    color: $primary-color;
  }

  .sideBarSort {
    display: block;
  }
}
@media(max-width: 767px) {
  .overlay.active {
    top: 95px!important;
  }
}

@media(max-width: 757px) {
  .search_active {
    height: 60px;
  }
  .overlay.active {
    top: 70px!important;
  }

  .search_bar {
    &.active {
      height: 60px;

    }

    &__inner {

    }
  }
  .search_bar__inner {
    margin-top: 8px;
  }
  .search_bar__filterIcon {
    margin-right: 7px;
  }

}
@media(max-width: 550px) {
  .overlay.active {
    top: 56px!important;
  }

  .search_bar {

    &.active {
      height: 60px;

    }

    &__inner {

      .outlinedButton {
        font-size: 10px;
        height: 30px;
        min-width: fit-content;
        padding: 0 !important;

        &:focus {
          border: none;
        }

        &:hover {
          border: none;
        }
      }
    }

  }
}

@media(max-width: 370px) {
  .search_bar {

    &.active {
      height: 60px;
    }
    &__inner {

      .outlinedButton {
        font-size: 10px;
        height: 30px;
        min-width: fit-content;
        padding: 0 !important;


      }
    }

    &__input {
      width: 190px;

    }
  }
}