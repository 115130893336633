@import 'variables';
@import "mixins";

.modal {
  min-width: 800px;
  min-height: 800px;
  background: none !important;

  .container {
    min-height: 100%;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .progress {
      position: absolute;
    }

    .iframe {
      width: 100%;
      height: 100%;
      background: none;
    }
  }

  @media (max-width: 840px) {
    min-width: calc(100vw - 32px);
    min-height: 800px;
  }
}