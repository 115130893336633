@import "mixins";
@import "variables";

.countdown {
  display: flex;
  gap: 4px;
  align-items: center;
  color: rgba(255, 255, 255, 0.30);
  color: #fff;
  font-family: Roboto, "sans-serif";



  &__value {
    padding: 8px;
    display: flex;
    gap: 8px;
    align-items: baseline;
    justify-content: center;
    height: 48px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    min-width: 90px;

    .time_unit {
      font-size: 12px;
      font-style: normal;
      font-weight: 600
    }
  }

  &__value:last-child {
    color: $primary-color;
  }

}

.countdown_dark {
  display: flex;
  gap: 40px;

  .value {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;

    color: $dark_text2;
    text-align: center;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;

    span {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      text-transform: uppercase;
    }
  }
}

.pin_fields {
  padding: var(--local-gap-padding);
  gap: var(--local-gap-padding);
  background: var(--smoothe-gray-bg);
  border-radius: 24px;
  color: var(--dark-border-color);
  border: 1px solid var(--blinded-border-color);

  .fields {
    gap: 8px;
    display: flex;

    .inputs {
      text-align: center;
      color: #fff;
      min-width: 30px;
      //max-width: auto;
      -moz-appearance: textfield;
      display: block;
      flex: 1;
      width: auto;
      border: 1px solid $primary-color;
      background: $background-color;
      outline: none;
      min-height: 40px;
      border-radius: 8px;
    }

  }

  .error_span {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: var(--danger-color);
    margin-top: 6px;
  }
}

.app_links_container {

  padding: 32px 16px;

  .app_links {
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: flex;

    button {
      height: 52px;
      border: 1px solid $dark_text;
      background: $background-color;
      border-radius: 8px;
      align-items: center;
      display: flex;
      color: #Fff;
      gap: 8px;
      padding: 0 8px;
      transition: 0.2s all;
      -webkit-transition: 0.2s all;

      &:hover {
        box-shadow: 0px 0px 10px 0px rgba(238, 182, 101, 0.5);
      }

      img {
        height: 70%;
        width: 40px;
        object-fit: contain;
      }

      .inf {
        display: flex;
        flex-direction: column;
        text-align: start;
        gap: 4px;

        span, strong {
          line-height: normal;
        }

        span {
          font-size: 10px;
        }
      }
    }
  }


  @media (max-width: 950px) {
    flex-wrap: wrap;
    gap: 32px;

    .socialMedia {
      order: 1;
    }

    .marks {
      order: 2;
    }

    .app_links {
      min-width: 100%;
      flex: 1;
      order: 3;
    }
  }

  @media (max-width: 680px) {
    .app_links {
      flex-direction: column;
      width: 100%;

      button {
        width: 100%;
        gap: 32px;

        .inf {
          flex-direction: row;
          gap: 8px;

          span {
            font-weight: 400;
          }

          span, strong {
            font-size: 18px;

          }
        }
      }
    }
  }

  @media (max-width: 440px) {
    .app_links {
      button {
        padding: 0 16px 0 8px;
        justify-content: space-between;

        .inf {
          span, strong {
            font-size: 15px;
          }
        }
      }
    }
  }
}

.maintenance_page {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 26px;
  justify-content: space-between;

  .message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 60px;
    max-width: 330px;

    strong {
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      color: #fff;
      text-align: center;
    }
  }

  .cd_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
}

.get_app_popup {
  background-color: $background-color;
  width: 100vw;
  border-bottom: 1px solid #1f1c26;

  .inner {
    max-width: 428px;
    margin: 0 auto;
    display: flex;
    padding: 16px;
    align-items: center;
    height: 60px;
    flex-direction: row;
    gap: 8px;
    max-height: 60px;

    .slogan {
      display: flex;
      flex-direction: column;
      align-items: start;
      //gap: 8px;
      flex: 1;

      strong {
        font-size: 11px;
      }

      span {
        font-size: 9px;
        text-transform: uppercase;
        color: $primary-color;
      }

    }

    .dwn_app_btn {
      //height: 52px;
      border: 1px solid $dark_text;
      background: $background-color;
      border-radius: 8px;
      align-items: center;
      display: flex;
      color: #Fff;
      gap: 8px;
      padding: 16px 8px;
      transition: 0.2s all;
      -webkit-transition: 0.2s all;
      max-height: 34px;

      //&:hover {
      //  box-shadow: 0px 0px 10px 0px rgba(238, 182, 101, 0.5);
      //}

      img {
        width: 16px;
        height: auto;
        object-fit: contain;
      }

      .inf {
        display: flex;
        flex-direction: column;
        text-align: start;
        gap: 4px;

        strong {
          line-height: normal;
          font-size: 9px;
        }

      }
    }

    .close_btn {
      cursor: pointer;
      width: fit-content;
      height: fit-content;
      background: transparent;
      border: none;

      svg {
        width: 12px;
        height: 12px;
        color: $dark_text;
      }
    }
  }

}

.masked {
  width: 200px;
  height: 100px;
  -webkit-mask-image: url('../../../public/default/images/leveling_mask.webp');
  mask-image: url('../../../public/default/images/leveling_mask.webp');
  mask-repeat: no-repeat;
  mask-size: contain;
  background-color: #000;

  position: relative;


  .half {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 200px 200px 0 0;
    background-color: #f00;
    width: 200px;
    height: 100px;
    transform: rotate(-180deg);
    transition: 0.2s transform;
    -webkit-transition: 0.2s transform;
    transform-origin: center bottom;
    -webkit-box-shadow: inset 0px 5px 15px -4px rgba(0,0,0,1);
    box-shadow: inset 0px 5px 15px -4px rgba(0,0,0,1);
  }

}


@keyframes btn-glow {
  0% {
    background-position: 0 100%;
  }
  100% {
    background-position: 0 300%;
  }
}
.burning .glow_bg:after {
  will-change: transform, opacity;
}

.burning {
  position: relative;
  display: inline-block;
  border-radius: 100px;
  border: none;
  padding: 0;
  overflow: visible;

  .glow_bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    overflow: visible;
    filter: blur(10px);


    &:after {
      position: absolute;
      inset: 1px;
      display: block;
      content: "";
      border-radius: 100px;
      animation: btn-glow 2s infinite linear;
      background: linear-gradient(
                      -20deg,
                      #f86700,
                      rgba(0, 248, 241, 0) 16.5%,
                      #f8d700 33%,
                      rgba(0, 248, 241, 0) 49.5%,
                      #f86700 66%,
                      rgba(0, 248, 241, 0) 85.5%,
                      #f8be00 100%
      )
      0 100% / 100% 200%;
      background-clip: unset;
      opacity: 1;
      transition: opacity 0s linear 0s;
    }
  }

  &.non_ios {
    .glow_bg {
      filter: blur(5px);
      &:after {
        transform: scale(1.05);
      }
    }
  }

  .bg_dark {
    position: relative;
    z-index: 2;
    background: #1d222c;
    border-radius: 100px;
    padding: 0 20px;
    color: #fff;
    overflow: visible;
    border: 2px solid #EEB665;
  }
}



@media (max-width: 470px) {
  .countdown {

    &__value {
      font-size: 24px;
      min-width: 72px;
    }

  }

}