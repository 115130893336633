@import "variables";

.root {
  margin-bottom: 70px;
  position: relative;
}

.root__grid {
  padding-left: 50px;
}

.item {
  //background-color: $dark1;
  border: 1px solid rgba(39, 34, 45, 0);
  border-radius: 8px;
  width: 126px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  overflow: hidden;
  padding: 0 5px;
  margin: 0 8px 8px 0;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;

  &:hover {
    border-color: $primary-color;
  }
}
@media(max-width: 991px) {
  .item {
    width: 84px;
    height: 48px
  }
}

@media(max-width: 767px) {
  .root {
    margin-bottom: 30px;
  }
}