@import "variables";

.reset_pwd_form {
  display: flex;
  flex-direction: column;
  gap: 8px;

  strong {
    font-size: 20px;
    color: $primary-color;
    margin-top: 20px;
    line-height: normal;
  }

  .info {
    font-size: 14px;
    color: $dark_text;
    line-height: normal;

  }

  .controllers {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-top: 20px;

    button {
      width: 100%;
    }

    .back_btn {
      width: fit-content;
    }
  }
}

.write_pin_form {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 50px;

  button {
    width: fit-content;
    margin: 0 auto;
  }

  .other_controllers {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 24px;

    button {
      background: transparent;
      border: none;
      color: $primary-color;
      text-decoration: underline;

      &:disabled {
        color: $dark_text;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 0;
      line-height: normal;

      p {
        margin: 0;
      }
    }

    .resend {
      display: flex;
      justify-content: center;
      gap: 8px;

      button {
        margin: unset;
      }
    }
  }

  .pin_fields {
    margin: 24px 0;
  }

}