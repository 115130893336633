
$animationDuration: 0.22s;


@mixin defaultInfoBarSports {
  border-radius: 30px;
  border: 1px solid #000;
  background: #0E0C10;
  margin-bottom: 8px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  span {
    color: #8F84A4;
    font-size: 14px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .money {
    color: #EEB665 !important;
    text-transform: uppercase;
  }
}

@mixin infoBarSports {
  border-radius: 30px;
  border: 1px solid #000;
  background: #0E0C10;
  margin-bottom: 8px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  span {
    color: #8F84A4;
    font-size: 14px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .money {
    color: #EEB665 !important;
    text-transform: uppercase;
  }

}

@mixin regularGridResponsive($width, $gap) {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($width, 1fr));
  gap: $gap;
}

@mixin smallCountdown {
  background: $danger;
  padding: 0 16px;
  gap: 8px;

  div {
    border: none !important;
    font-size: 12px !important;
    width: fit-content;
    height: fit-content;
    min-width: unset;
    min-height: unset;
    padding: 0;
    gap: 0;

    span {
      display: inline;
    }
  }

  span {
    display: none;
  }
}

@mixin spinningValue {
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 100%;
  text-align: start;

  .value {
    overflow: hidden;

    //height: 25px;
    .slide {
      display: flex;
      flex-direction: column;

      &.sliding {
        animation-name: slideAnim;
        animation-duration: $animationDuration;
        animation-timing-function: ease-out;
        animation-iteration-count: infinite;
      }

      &.rolling {
        animation-name: rollAnim;
        animation-duration: $animationDuration;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }


      @keyframes rollAnim {
        from {
          transform: translateY(0);

        }

        to {
          transform: translateY(-50%);

        }
      }

      @keyframes slideAnim {
        0% {
          transform: translateY(0);
        }

        80% {
          transform: translateY(-28px);
        }

        to {
          transform: translateY(-30px);
        }
      }
    }
  }
}

@mixin jpBadge {
  border-radius: 40px;
  padding: 10px;
  color: #FFF;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;

  &.cool_start {
    background: #097B9E;
    box-shadow: 0px -4px 5px 0px rgba(106, 2, 160, 0.40) inset, 0px 4px 5px 0px rgba(238, 182, 101, 0.40) inset;
  }

  &.warm_up {
    background: #ED9E15;
    box-shadow: 0px -4px 5px 0px rgba(106, 2, 160, 0.40) inset, 0px 4px 5px 0px rgba(238, 182, 101, 0.40) inset;
  }

  &.blazing_hot {
    background: #E9550C;
    box-shadow: 0px -4px 5px 0px rgba(106, 2, 160, 0.40) inset, 0px 4px 5px 0px rgba(238, 182, 101, 0.40) inset;
  }

  &.on_fire {
    background: #9D0000;
    box-shadow: 0px -4px 5px 0px rgba(106, 2, 160, 0.40) inset, 0px 4px 5px 0px rgba(238, 182, 101, 0.40) inset;
  }
}
